// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RuleProperty = Record<string, any>

export function getDefaultValue(property: RuleProperty) {
  if (Object.hasOwn(property, 'defaultValue')) return property.defaultValue
  switch (property.code) {
    case 'text':
    case 'select':
      return ''
    case 'multi-select':
      return []
    case 'rule-schema':
      return {}
    case 'integer':
    case 'decimal':
      return 0
    case 'slider':
      return 0
    case 'checkbox':
      return false
    default:
      return ''
  }
}
