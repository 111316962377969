import { Badge, Box, ButtonBase, Stack, useTheme } from '@mui/material'
import { Document } from '@/types/documents'
import ImageCard from './ImageCard'
import useSplitDocuments from './useSplitDocuments'

type SplitDocumentPagesProps = {
  document: Document
  splitDocumentsData: ReturnType<typeof useSplitDocuments>
  useFullImage?: boolean
}

export const THUMBNAIL_TILE_WIDTH = 240
export const FULL_TILE_WIDTH = 480

function SplitUnderline({
  splitPoint,
  distanceToNextSplitPoint,
  pointIsSelected,
  useFullImage,
}: {
  splitPoint: number
  distanceToNextSplitPoint: number
  pointIsSelected: boolean
  useFullImage?: boolean
}) {
  const tileWidth = useFullImage ? FULL_TILE_WIDTH : THUMBNAIL_TILE_WIDTH
  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: 8,
        left: splitPoint * tileWidth + splitPoint * 20,
        width:
          distanceToNextSplitPoint * tileWidth +
          distanceToNextSplitPoint * 20 -
          20,
        height: 8,
        transition: 'all 0.1s ease-in-out',
        border: pointIsSelected ? '1px solid' : 0,
        borderTop: 'none',
      }}
    />
  )
}

export default function SplitDocumentPages({
  document,
  splitDocumentsData,
  useFullImage,
}: SplitDocumentPagesProps) {
  const theme = useTheme()

  const { pagesToDelete, splitPoints, setPagesToDelete, setSplitPoints } =
    splitDocumentsData

  const pages = document.document_pages

  return (
    <Stack
      direction="row"
      sx={{
        position: 'relative',
        overflowX: 'auto',
        overflowY: 'visible',
        pb: 3.5,
      }}
    >
      {pages.map((page, index) => {
        const splitPoint = index + 1
        const splitPointIndex = splitPoints.findIndex(
          (point) => point === splitPoint,
        )

        const pointIsSelected = splitPointIndex !== -1

        const distanceToNextSplitPoint = pointIsSelected
          ? (splitPoints[splitPointIndex + 1] || pages.length) -
            splitPoints[splitPointIndex]
          : 0

        // Get width based on larger page dimension
        return (
          <Stack key={page.id} direction="row">
            <Badge
              key={page.id}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              badgeContent={page.page_number}
              color="primary"
              component="div"
              max={10000} // Just in case we ever have a document that has more than 99 (default) pages
              slotProps={{
                badge: () => ({
                  sx: { transform: 'scale(1) translate(-20%, -20%)' }, // Override default badge transform position
                }),
              }}
            >
              <ImageCard
                page={page}
                pagesToDelete={pagesToDelete}
                setPagesToDelete={setPagesToDelete}
                useFullImage={useFullImage}
              />
            </Badge>
            {index < pages.length - 1 && (
              <ButtonBase
                sx={{
                  width: 20,
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'divider',
                  },
                }}
                onClick={() => {
                  if (!pointIsSelected) {
                    setSplitPoints(
                      [...splitPoints, splitPoint].toSorted((a, b) => a - b),
                    )
                  } else {
                    setSplitPoints(splitPoints.filter((p) => p !== splitPoint))
                  }
                }}
              >
                <Box
                  sx={{
                    width: 4,
                    height: '100%',
                    borderRadius: 4,
                    transition: 'all 0.1s ease-in-out',
                    background: pointIsSelected
                      ? theme.palette.secondary.main
                      : undefined,
                  }}
                />
              </ButtonBase>
            )}
            {pointIsSelected && (
              <SplitUnderline
                splitPoint={splitPoint}
                distanceToNextSplitPoint={distanceToNextSplitPoint}
                pointIsSelected={pointIsSelected}
                useFullImage={useFullImage}
              />
            )}
          </Stack>
        )
      })}
      {splitPoints.length > 0 && (
        <SplitUnderline
          splitPoint={0}
          distanceToNextSplitPoint={splitPoints[0]}
          pointIsSelected
          useFullImage={useFullImage}
        />
      )}
    </Stack>
  )
}
