import { CustomVariableCategory } from '@/types/custom-variables'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'

const service = buildService<CustomVariableCategory>({
  pathCategory: 'custom_variable_categories',
})

export function useGetCustomVariableCategory({
  id,
  ...options
}: UseGetDetailOptions<CustomVariableCategory>) {
  const query = useGetDetail<CustomVariableCategory>({
    id,
    queryKey: queryKeys.customVariableCategories.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    customVariableCategory: query.data,
    ...query,
  }
}

export function useCreateCustomVariableCategory(
  options: UseCreateDetailOptions<CustomVariableCategory> = {},
) {
  const mutation = useCreateDetail<CustomVariableCategory>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createCustomVariableCategory: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateCustomVariableCategory(
  options: UseUpdateDetailOptions<CustomVariableCategory> = {},
) {
  const mutation = useUpdateDetail<CustomVariableCategory>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateCustomVariableCategory: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteCustomVariableCategory(
  options: UseDeleteDetailOptions = {},
) {
  const mutation = useDeleteDetail<CustomVariableCategory>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteCustomVariableCategory: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetCustomVariableCategories({
  filters,
  ...options
}: UseGetListOptions<CustomVariableCategory> = {}) {
  const query = useGetList<CustomVariableCategory>({
    filters,
    queryKey: queryKeys.customVariableCategories.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    customVariableCategories: query.allData,
    ...query,
  }
}

export function useUpdateCustomVariableCategories(
  options: UseUpdateListOptions<CustomVariableCategory> = {},
) {
  const mutation = useUpdateList<CustomVariableCategory>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateCustomVariableCategories: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteCustomVariableCategories({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<CustomVariableCategory>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteCustomVariableCategories: mutation.mutateAsync,
    ...mutation,
  }
}
