import { QueryKey } from '@tanstack/react-query'
import {
  CustomVariable,
  CustomVariableCategory,
} from '@/types/custom-variables'
import {
  useDeleteCustomVariableCategory,
  useUpdateCustomVariableCategory,
} from '@/service-library/hooks/custom-variable-categories'
import queryKeys from '@/service-library/query-keys'
import { showErrorSnackbar, showSuccessSnackbar } from '@/utils/snackbars'
import EditableNameWithActions from '@/components/editable-name/EditableNameWithActions'
import { Stack } from '@mui/material'

type CategoryNameWithActionsProps = {
  category: CustomVariableCategory & { variables: CustomVariable[] }
  listQueryKey: QueryKey
  deleteDisabledMessage?: string
}

export default function CategoryNameWithActions({
  category,
  listQueryKey,
  deleteDisabledMessage,
}: CategoryNameWithActionsProps) {
  const { name } = category

  const { updateCustomVariableCategory } = useUpdateCustomVariableCategory({
    listQueryKey,
    // needed since we use the root org to get the custom variable categories for the other tab
    sideEffectQueryKeys: [queryKeys.organizations.details()],
    onError: () => {
      showErrorSnackbar('Unable to update category. Please try again later.')
    },
  })

  const { deleteCustomVariableCategory } = useDeleteCustomVariableCategory({
    listQueryKey,
    // needed since we use the root org to get the custom variable categories for the other tab
    sideEffectQueryKeys: [queryKeys.organizations.details()],
    onError: () => {
      showErrorSnackbar('Unable to delete category. Please try again later.')
    },
    onSuccess: () => {
      showSuccessSnackbar('Category Deleted')
    },
  })

  function handleUpdate(internalName: string) {
    if (internalName && internalName !== name) {
      const updatedCategory = { ...category, name: internalName }
      updateCustomVariableCategory(updatedCategory)
    }
  }

  function handleDelete() {
    deleteCustomVariableCategory(category.id)
  }

  return (
    <Stack direction="row" alignItems="center" sx={{ height: 48 }}>
      <EditableNameWithActions
        canBeEdited
        groupName="category"
        name={name}
        onDelete={handleDelete}
        onUpdate={handleUpdate}
        deleteDisabledMessage={deleteDisabledMessage}
        typographyProps={{ component: 'h2', variant: 'h5' }}
      />
    </Stack>
  )
}
