import { ReactNode, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Tabs, Tab, Stack, Divider } from '@mui/material'
import ProjectBreadcrumb from '@/components/project-dashboard/ProjectBreadcrumb'
import ImporterEmail from './ImporterEmail'

type TabPanelProps = {
  children: ReactNode
  index: number
  value: number
}

export const sourceTypeMap: Record<string, number> = {
  // NONE: 'none',
  email: 0,
}

function TabPanel({ children, index, value }: TabPanelProps) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`source-tabpanel-${index}`}
      aria-labelledby={`source-tab-${index}`}
      style={{ height: '100%' }}
    >
      {children}
    </div>
  )
}

export default function Source() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [sourceType, setSourceType] = useState(
    sourceTypeMap[searchParams.get('tab') || ''] ?? 0,
  )

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number,
  ) => {
    setSourceType(newValue)
    setSearchParams(
      (prev) => {
        prev.set('tab', Object.keys(sourceTypeMap)[newValue])
        return prev
      },
      { replace: true },
    )
  }

  useEffect(() => {
    if (!searchParams.get('tab')) {
      const defaultTab = Object.keys(sourceTypeMap)[0]
      setSearchParams(
        (prev) => {
          prev.append('tab', defaultTab)
          return prev
        },
        { replace: true },
      )
      setSourceType(0)
    }
  }, [searchParams, setSearchParams])

  return (
    <Stack direction="column" sx={{ width: '100%', height: '100%' }}>
      <ProjectBreadcrumb label="Sources" url="../sources" />
      <Tabs value={sourceType} onChange={handleChange} sx={{ ml: 2, mt: -2 }}>
        <Tab label="Email" id={`source-tab-${sourceType}`} />
      </Tabs>
      <Divider />
      <TabPanel value={sourceType} index={sourceTypeMap.email}>
        <ImporterEmail />
      </TabPanel>
    </Stack>
  )
}
