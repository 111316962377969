import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Stack } from '@mui/material'
import { QueryKey } from '@tanstack/react-query'
import {
  CustomVariable,
  CustomVariableCategory,
} from '@/types/custom-variables'
import { OverlayState } from '@/hooks/useOverlay'
import {
  useCreateCustomVariable,
  useUpdateCustomVariable,
} from '@/service-library/hooks/custom-variables'
import queryKeys from '@/service-library/query-keys'
import generateUuid from '@/utils/generate-uuid'
import { showErrorSnackbar } from '@/utils/snackbars'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import {
  FormAutocomplete,
  FormTextField,
  PixyDocsForm,
} from '@/components/forms'

type AddEditCustomVariableDialogProps = {
  overlay: OverlayState
  categories: CustomVariableCategory[]
  listQueryKey: QueryKey
  orgId: string
  selectedCategory: CustomVariableCategory
  variable?: CustomVariable
}

type FormValues = {
  name: string
  description: string
  category: CustomVariableCategory
}

export default function AddEditCustomVariableDialog({
  overlay,
  categories,
  listQueryKey,
  selectedCategory,
  orgId,
  variable,
}: AddEditCustomVariableDialogProps) {
  const { createCustomVariable } = useCreateCustomVariable({
    listQueryKey,
    // needed since we use the root org to get the custom variables for the other tab
    sideEffectQueryKeys: [queryKeys.organizations.details()],
    onError: () => {
      showErrorSnackbar('Unable to create variable. Please try again later.')
    },
  })

  const { updateCustomVariable } = useUpdateCustomVariable({
    listQueryKey,
    // needed since we use the root org to get the custom variables for the other tab
    sideEffectQueryKeys: [queryKeys.organizations.details()],
    onError: () => {
      showErrorSnackbar('Unable to update team. Please try again later.')
    },
  })

  const defaultValues = useMemo(
    () => ({
      name: variable?.name || '',
      description: variable?.description || '',
      category: selectedCategory,
    }),
    [variable?.description, variable?.name, selectedCategory],
  )

  const methods = useForm<FormValues>({
    defaultValues,
  })

  const {
    formState: { isDirty, isValid },
    register,
    reset,
  } = methods

  const handleSubmit = (values: FormValues) => {
    values.name = values.name.trim()
    values.description = values.description.trim()

    if (variable) {
      updateCustomVariable({
        ...variable,
        ...values,
        category_id: values.category.id,
      })
    } else {
      createCustomVariable({
        ...values,
        id: generateUuid(),
        org_id: orgId,
        category_id: values.category.id,
      })
    }
    overlay.close()
  }

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, overlay.isOpen, reset])

  return (
    <Dialog
      title={`${variable ? 'Edit' : 'New'} Variable`}
      {...overlay}
      maxWidth="sm"
    >
      <PixyDocsForm methods={methods} onSubmit={handleSubmit}>
        <DialogContent>
          <Stack spacing={2}>
            <FormTextField
              autoFocus
              fullWidth
              label="Name"
              required
              {...register('name', {
                validate: (value) => value.trim().length > 0,
              })}
            />
            <FormTextField
              name="description"
              fullWidth
              label="Description"
              multiline
            />
            <FormAutocomplete
              required
              name="category"
              label="Category"
              options={categories}
              isOptionEqualToValue={(option, value) => option?.id === value.id}
              disableClearable
              getOptionLabel={(option) => option?.name || ''}
            />
          </Stack>
        </DialogContent>
        <DialogFooter>
          <Button variant="text" onClick={overlay.close}>
            Cancel
          </Button>
          <Button type="submit" disabled={!isDirty || !isValid}>
            Save
          </Button>
        </DialogFooter>
      </PixyDocsForm>
    </Dialog>
  )
}
