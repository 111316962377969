import { Card, Typography } from '@mui/material'
import { Handle, Position } from '@xyflow/react'
import { memo } from 'react'

function InfoTypeNode({
  data,
}: {
  data: { label: string; showSourceHandle: boolean; showTargetHandle: boolean }
}) {
  return (
    <Card sx={{ p: 1, borderRadius: 2 }}>
      <Typography variant="body2" textAlign="left">
        {data.label}
      </Typography>
      <Handle type="source" position={Position.Right} />
      <Handle type="target" position={Position.Left} />
    </Card>
  )
}

export default memo(InfoTypeNode)
