import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Box, Divider, Tab, Tabs } from '@mui/material'
import usePermission from '@/hooks/usePermission'
import TabPanel from '@/components/TabPanel'
import { useRootOrganization } from '@/components/organizations/RootOrganizationProvider'
import CustomVariables from './CustomVariables'
import CustomVariableValues from './CustomVariableValues'

const tabsMap: Record<string, number> = {
  variables: 0,
  values: 1,
}

export default function CustomVariablesDetails() {
  const { '*': id } = useParams()
  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedTab, setSelectedTab] = useState(
    tabsMap[searchParams.get('tab') || ''] ?? 1,
  )
  const { rootOrganization } = useRootOrganization()
  const { hasEditingPermission } = usePermission()

  const canEdit = hasEditingPermission('edit_orgs', rootOrganization.id)

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number,
  ) => {
    setSelectedTab(newValue)
    setSearchParams(
      (prev) => {
        prev.set('tab', Object.keys(tabsMap)[newValue])
        return prev
      },
      { replace: true },
    )
  }

  useEffect(() => {
    const defaultTab = Object.keys(tabsMap)[1]
    const currentTab = searchParams.get('tab')
    if (!id) {
      navigate(`${rootOrganization.id}?tab=${currentTab ?? defaultTab}`, {
        replace: true,
      })
    } else if (!currentTab) {
      setSearchParams(
        (prev) => {
          prev.append('tab', defaultTab)
          return prev
        },
        { replace: true },
      )
      setSelectedTab(1)
    }
  }, [id, navigate, rootOrganization.id, searchParams, setSearchParams])

  return (
    <Box>
      <Tabs value={selectedTab} onChange={handleChange}>
        {canEdit && <Tab label="Variables" />}
        <Tab label="Values" />
      </Tabs>
      <Divider />
      {canEdit && (
        <TabPanel value={selectedTab} index={tabsMap.variables}>
          <CustomVariables />
        </TabPanel>
      )}
      <TabPanel value={selectedTab} index={tabsMap.values}>
        <CustomVariableValues />
      </TabPanel>
    </Box>
  )
}
