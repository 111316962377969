import { Document } from '@/types/documents'
import { DocumentFlag } from '@/types/flags'

function getDocumentIdsFromDescription(flag: DocumentFlag) {
  const duplicateDocIds: string[] = []
  const regex =
    /document_id=[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g
  const matches = flag.long_description.match(regex)
  if (matches) {
    matches.forEach((match) => {
      const docId = match.split('=')[1]
      duplicateDocIds.push(docId)
    })
  }
  return duplicateDocIds
}

export function getDuplicateSuggestions(document: Document) {
  const dupeFlags =
    document.document_flags?.reduce<DocumentFlag[]>((acc, flag) => {
      if (flag.flag_type?.code === 'z-duplicate_document') {
        acc.push(flag)
      } else if (flag.flag_type?.code === 'z-duplicate_document_data') {
        acc.push(flag)
      }
      return acc
    }, []) || []

  const duplicateDocsIds = dupeFlags.map(getDocumentIdsFromDescription)

  const suggestions = duplicateDocsIds.flat().map((docId) => ({
    docId,
    label: 'Original Document',
  }))
  const uniqueDocIds = new Set(
    suggestions.map((suggestion) => suggestion.docId),
  )
  return Array.from(uniqueDocIds).map((docId) =>
    suggestions.find((suggestion) => suggestion.docId === docId),
  ) as {
    docId: string
    label: string
  }[]
}
