import { CustomVariableValue } from '@/types/custom-variables'
import { Tooltip, Typography } from '@mui/material'

type VariableValueDisplayProps = {
  variableValue?: CustomVariableValue
  resolvedValue: string | null
  isInheriting?: boolean
}

export default function VariableValueDisplay({
  variableValue,
  resolvedValue,
  isInheriting,
}: VariableValueDisplayProps) {
  const displayedValue = variableValue?.value || resolvedValue
  return (
    <Typography color={!variableValue ? 'text.secondary' : undefined}>
      {isInheriting ? (
        <Tooltip title="Value inherited from a parent organization">
          <i>{displayedValue}</i>
        </Tooltip>
      ) : (
        displayedValue
      )}
    </Typography>
  )
}
