import { Alert, Stack } from '@mui/material'
import {
  CustomVariable,
  CustomVariableCategory,
} from '@/types/custom-variables'
import { Organization } from '@/types/organizations'
import {
  useGetCustomVariableValues,
  useGetResolvedCustomVariableValues,
} from '@/service-library/hooks/custom-variable-values'
import CustomVariableValuesTable from './CustomVariableValuesTable'

type CustomVariablesPerOrgProps = {
  categories: (CustomVariableCategory & { variables: CustomVariable[] })[]
  organization?: Organization
}
export default function CustomVariablesPerOrg({
  categories,
  organization,
}: CustomVariablesPerOrgProps) {
  const { customVariableValues, queryKey: customVariableValuesQueryKey } =
    useGetCustomVariableValues({
      filters: {
        owner_org_id: organization?.id || '',
        limit: '1000',
      },
      enabled: !!organization?.id,
    })

  const {
    resolvedCustomVariableValues,
    queryKey: resolvedCustomVariableValuesQueryKey,
  } = useGetResolvedCustomVariableValues({
    orgId: organization?.id || '',
  })

  if (!resolvedCustomVariableValues) return null

  return (
    <Stack spacing={2} sx={{ pt: 1, flexGrow: 1, overflow: 'auto' }}>
      {organization ? (
        <Stack spacing={6}>
          {categories.map((category) => (
            <CustomVariableValuesTable
              key={category.id}
              category={category}
              customVariableValuesQueryKey={customVariableValuesQueryKey}
              resolvedCustomVariableValuesQueryKey={
                resolvedCustomVariableValuesQueryKey
              }
              resolvedCustomVariableValues={resolvedCustomVariableValues}
              customVariableValues={customVariableValues}
              organization={organization}
            />
          ))}
        </Stack>
      ) : (
        <Alert severity="error">
          Unable to load variables for this organization. You may not have
          access or this organization does not exist.
        </Alert>
      )}
    </Stack>
  )
}
