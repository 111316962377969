import {
  Background,
  ReactFlow,
  useEdgesState,
  useNodesState,
} from '@xyflow/react'
import { useMemo } from 'react'
import InfoTypeNode from './InfoTypeNode'
import { Box, useTheme } from '@mui/material'
import PredictionNode from './PredictionNode'
import { ProjectLinkedModel } from '@/types/project-linked-models'
import { ProjectModelVersionInfoType } from '@/types/project-models'

type ModelOutputsDisplayProps = {
  projectLinkedModel: ProjectLinkedModel
  projectModelVersionInfoTypes: ProjectModelVersionInfoType[]
}

const nodeTypes = {
  output: InfoTypeNode,
  prediction: PredictionNode,
}

const INPUTS_X = 0
const PREDICTON_X = 400
const OUTPUT_X = 800
const NODE_Y_GAP = 50

export default function ModelOutputsDisplay({
  // projectLinkedModel, // will use this to get fields maybe?
  projectModelVersionInfoTypes,
}: ModelOutputsDisplayProps) {
  const theme = useTheme()

  const initialNodes = useMemo(() => {
    const infoTypeNodes = projectModelVersionInfoTypes.map(
      (projectModelVersionInfoType, index) => {
        return {
          id: projectModelVersionInfoType.id,
          type: 'output',
          data: {
            label: projectModelVersionInfoType.info_type.name,
            showTargetHandle: true,
          },
          draggable: false,
          position: { x: OUTPUT_X, y: 100 + index * NODE_Y_GAP },
          style: {
            background: 'transparent',
            color: theme.palette.text.primary,
            border: 'solid 0px transparent',
            padding: 0,
          },
        }
      },
    )
    return [
      ...infoTypeNodes,
      {
        id: 'prediction',
        type: 'prediction',
        data: { label: 'Prediction' },
        draggable: false,
        selectable: false,
        position: {
          x: PREDICTON_X,
          y: (projectModelVersionInfoTypes.length * NODE_Y_GAP + 100) / 2 + 50,
        },
        style: {
          background: 'transparent',
          color: theme.palette.text.primary,
          border: 'solid 0px transparent',
        },
      },
    ]
  }, [projectModelVersionInfoTypes, theme.palette.text.primary])

  const initialEdges = useMemo(() => {
    const infoTypeToPredictionEdges = projectModelVersionInfoTypes.map(
      (projectModelVersionInfoType) => {
        return {
          id: `prediction-${projectModelVersionInfoType.id}`,
          source: 'prediction',
          target: projectModelVersionInfoType.id,
          type: 'default',
          animated: true,
        }
      },
    )
    return infoTypeToPredictionEdges
  }, [projectModelVersionInfoTypes])

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes)
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges)

  return (
    <Box
      sx={{
        height: '100%',
        '& svg': {
          overflow: 'visible !important', // Something in MUI is messing with this, so this is a hack to fix it.
          position: 'absolute !important',
        },
      }}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodeTypes={nodeTypes}
        style={{
          position: 'absolute',
        }}
      >
        <Background />
      </ReactFlow>
    </Box>
  )
}
