import { useMemo } from 'react'
import { Document, DocumentForRegister } from '@/types/documents'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'
import { deleteCall, updateDetail, updateList } from '../request-wrappers'

const service = buildService<Document>({
  pathCategory: 'documents',
})

export function useGetDocument({
  id,
  ...options
}: UseGetDetailOptions<Document>) {
  const query = useGetDetail<Document>({
    id,
    queryKey: queryKeys.documents.detail({ id, filters: options.filters }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    document: query.data,
    ...query,
  }
}

export function useCreateDocument(
  options: UseCreateDetailOptions<Document> = {},
) {
  const mutation = useCreateDetail<Document>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createDocument: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateDocument(
  options: UseUpdateDetailOptions<Document> = {},
) {
  const mutation = useUpdateDetail<Document>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateDocument: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteDocument(options: UseDeleteDetailOptions = {}) {
  const mutation = useDeleteDetail<Document>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteDocument: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetDocuments({
  filters,
  ...options
}: UseGetListOptions<Document> = {}) {
  const updatedFilters = useMemo(
    () => ({
      'api-consistency': 'weak',
      ...filters, // Allow api-consistency to be overridden
    }),
    [filters],
  )
  const query = useGetList<Document>({
    filters: updatedFilters,
    queryKey: queryKeys.documents.list({ filters: updatedFilters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    documents: query.allData,
    ...query,
  }
}

export function useUpdateDocuments(
  options: UseUpdateListOptions<Document> = {},
) {
  const mutation = useUpdateList<Document>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateDocuments: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteDocuments({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<Document>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteDocuments: mutation.mutateAsync,
    ...mutation,
  }
}

/**
 * MARK: Action Hook: Register Documents
 * Used to create or update a list of documents.
 */
export function useRegisterDocuments(
  options: UseUpdateListOptions<DocumentForRegister, Document> = {},
) {
  const mutation = useUpdateList<DocumentForRegister, Document>({
    serviceFn: (serviceFnOptions) => {
      return updateList<DocumentForRegister, Document>({
        url: '/v2/pd-workflow/documents/register',
        ...serviceFnOptions,
      })
    },
    ...options,
  })

  return {
    registerDocuments: mutation.mutateAsync,
    ...mutation,
  }
}

/**
 * MARK: Action Hook: Run Rules on a Document
 * Used to run rules on a document.
 */
export function useRunRules({
  workflowId,
  ...options
}: UseUpdateDetailOptions<{ id: string }> & { workflowId: string }) {
  const mutation = useUpdateDetail<{ id: string }>({
    serviceFn: ({ item, ...serviceFnOptions }) => {
      return updateDetail<{ id: string }>({
        url: `/v2/pd/documents/${item.id}/run_rules`,
        item: {} as { id: string }, // No body needed for this endpoint
        ...serviceFnOptions,
        filters: {
          ...serviceFnOptions.filters,
          workflow_id: workflowId,
        },
      })
    },
    ...options,
  })

  return {
    runRules: mutation.mutateAsync,
    ...mutation,
  }
}

/**
 * MARK: Action Hook: Clear Document Chips
 * Used to clear chips on a document.
 */
export function useClearDocumentChips(
  options: UseUpdateDetailOptions<{ id: string }> = {},
) {
  const mutation = useUpdateDetail<{ id: string }>({
    serviceFn: ({ item, ...serviceFnOptions }) => {
      return updateDetail<{ id: string }>({
        url: `/v2/pd/documents/${item.id}/clear_chips`,
        item: {} as { id: string }, // No body needed for this endpoint
        ...serviceFnOptions,
      })
    },
    ...options,
  })

  return {
    clearDocumentChips: mutation.mutateAsync,
    ...mutation,
  }
}

/**
 * MARK: Action Hook: Archive Documents
 * Used to archive a list of documents.
 */
export function useArchiveDocuments(options: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<Document>({
    serviceFn: ({ ids, filters, axiosOptions }) => {
      return deleteCall({
        url: '/v2/pd/documents/archive',
        filters: {
          id__in: ids.join(','),
          ...filters,
        },
        axiosOptions,
      })
    },
    ...options,
  })

  return {
    archiveDocuments: mutation.mutateAsync,
    ...mutation,
  }
}
