import { useGetWorkflowStateEvent } from '@/service-library/hooks/workflow-state-events'
import { WorkflowStateAction } from '@/types/workflow-state-actions'
import { Box, Typography } from '@mui/material'
import PrimaryTextWrapper from './PrimaryTextWrapper'
import { useGetCustomVariable } from '@/service-library/hooks/custom-variables'
import ProjectTagChip from '@/components/project-tags/ProjectTagChip'
import { useGetProjectTag } from '@/service-library/hooks/project-tags'

type TriggerEventDisplayProps = {
  action: WorkflowStateAction
}

export default function TriggerEventDisplay({
  action,
}: TriggerEventDisplayProps) {
  const { workflowStateEvent } = useGetWorkflowStateEvent({
    id: action.params.event_id,
    filters: {
      fields__only: 'id,name',
    },
  })

  const { customVariable } = useGetCustomVariable({
    id: action.params.org_custom_variable_id || '',
    enabled: !!action.params.org_custom_variable_id,
  })

  const { projectTag } = useGetProjectTag({
    id: action.params.project_tag_id || '',
    enabled: !!action.params.project_tag_id,
  })

  if (!workflowStateEvent) return null

  const condition = action.params.condition

  const messageMap: Record<string, string> = {
    none: '.',
    has_flags: ' if the document has any flags.',
    has_no_flags: ' if the document has no flags.',
    has_flag_type: ' if the document has the following flag type:',
    does_not_have_flag_type:
      ' if the document does not have the following flag type:',
    org_custom_variable_is: ` if the document's organization's custom variable matches the configured value:`,
    has_tag: ' if the document has the following tag:',
  }

  return (
    <>
      <Typography variant="body2" color="text.secondary">
        Trigger the{' '}
        <PrimaryTextWrapper>{workflowStateEvent.name}</PrimaryTextWrapper> event
        {messageMap[condition]}
      </Typography>

      {/* // TODO: When we eventually switch this action to use actual flag types instead of relying on flag class,
    // make this show the actual flag. */}
      {(condition === 'has_flag_type' ||
        condition === 'does_not_have_flag_type') && (
        <Typography variant="body2">
          <b>Flag Type: </b>
          {action.params.flag_type_code}
        </Typography>
      )}

      {condition === 'org_custom_variable_is' && (
        <>
          <Typography variant="body2">
            <b>Custom Variable: </b>
            {customVariable?.name}
          </Typography>
          <Typography variant="body2">
            <b>Value: </b>
            {action.params.org_custom_variable_value}
          </Typography>
        </>
      )}

      {condition === 'has_tag' && projectTag && (
        <Box sx={{ width: 'fit-content' }}>
          <ProjectTagChip projectTag={projectTag} />
        </Box>
      )}
    </>
  )
}
