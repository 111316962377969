import useOverlay, { OverlayState } from '@/hooks/useOverlay'
import { useGetProjectModelTypes } from '@/service-library/hooks/project-model-types'
import { Add } from '@mui/icons-material'
import { ListItemIcon, Menu, MenuItem } from '@mui/material'
import AddCustomModelDialog from './AddCustomModelDialog'
import { useState } from 'react'
import { ProjectModelType } from '@/types/project-models'

type AddModelMenuProps = {
  overlay: OverlayState
  anchorEl: Element | null
}

export default function AddModelMenu({ overlay, anchorEl }: AddModelMenuProps) {
  const addCustomModelOverlay = useOverlay()
  const [modelType, setModelType] = useState<ProjectModelType | null>(null)

  const { projectModelTypes } = useGetProjectModelTypes()
  const displayedModelTypes = projectModelTypes.filter(
    ({ code }) => code !== 'OCR',
  )

  return (
    <>
      <Menu open={overlay.isOpen} onClose={overlay.close} anchorEl={anchorEl}>
        {/* TODO: Fetch and show PubModels */}

        {displayedModelTypes?.map((modelType) => (
          <MenuItem
            onClick={() => {
              addCustomModelOverlay.open()
              setModelType(modelType)
              overlay.close()
            }}
            key={modelType.id}
          >
            <ListItemIcon>
              <Add />
            </ListItemIcon>
            Custom {modelType.code} Model
          </MenuItem>
        ))}
      </Menu>

      <AddCustomModelDialog
        overlay={addCustomModelOverlay}
        modelType={modelType}
      />
    </>
  )
}
