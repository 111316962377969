import { Alert, Box, Button, Container, Stack, Typography } from '@mui/material'
import ProjectBreadcrumb from '../project-dashboard/ProjectBreadcrumb'
import { Link, useNavigate, useParams } from 'react-router-dom'
import LargeHeading from '../large-heading/LargeHeading'
import CopyIDButton from '../copy-id-button/CopyIDButton'
import { ArrowBack, Delete } from '@mui/icons-material'
import useIsSuperUser from '@/services/hooks/useIsSuperUser'
import { useDemoModeContext } from '../demo-mode-provider/DemoModeProvider'
import {
  useDeleteProjectLinkedModel,
  useGetProjectLinkedModel,
  useUpdateProjectLinkedModel,
} from '@/service-library/hooks/project-linked-models'
import ModelVersionSelect from '../model-version-select/ModelVersionSelect'
import { showSuccessSnackbar } from '@/utils/snackbars'
import { ProjectLinkedModel } from '@/types/project-linked-models'
import ModelOutputsDisplay from '../model-inputs-outputs/ModelInputsOutputs'
import queryKeys from '@/service-library/query-keys'

const labelMap: Record<string, string> = {
  OCR: 'OCR Model',
  NER: 'Labeling Model',
  Category: 'Category Model',
}

// This is where users can manage a ProjectLinkedModel.
export default function ModelPage() {
  const { id } = useParams()
  const navigate = useNavigate()

  const isSuperUser = useIsSuperUser()
  const [demoMode] = useDemoModeContext()

  const { projectLinkedModel, queryKey, isLoading } = useGetProjectLinkedModel({
    id: id as string,
    filters: {
      fields__include: 'project_model,current_model_version',
      project_model__fields__include:
        'project_model_type,project_model_versions',
      project_model__project_model_versions__fields__include:
        'project_model_version_info_types',
      current_model_version__fields__include:
        'project_model_version_info_types',
      current_model_version__project_model_version_info_types__fields__include:
        'info_type',
    },
  })

  const { updateProjectLinkedModel } = useUpdateProjectLinkedModel({
    detailQueryKey: queryKey,
    onSuccess: () => {
      showSuccessSnackbar('Model version updated')
    },
  })

  const { deleteProjectLinkedModel } = useDeleteProjectLinkedModel({
    sideEffectQueryKeys: [queryKeys.projectLinkedModels.all],
    onSuccess: () => {
      showSuccessSnackbar('Model removed from project')
    },
  })

  if (!projectLinkedModel && !isLoading)
    return (
      <Container maxWidth="xl">
        <Alert severity="error">
          Model not found. Please contact Pixydocs Support if this is
          unexpected.
        </Alert>
      </Container>
    )

  if (!projectLinkedModel) return null // Only have this so TS is happy

  const currentModelVersion = projectLinkedModel.current_model_version

  return (
    <Container maxWidth="xl">
      <ProjectBreadcrumb label="Model Library" url="../model-library" />
      <ProjectBreadcrumb label={projectLinkedModel.project_model?.name || ''} />

      <Button
        sx={{ ml: '-4px', mb: 2 }}
        startIcon={<ArrowBack />}
        component={Link}
        to="../model-library"
        variant="text"
      >
        Back to Model Library
      </Button>

      <LargeHeading
        heading={projectLinkedModel.project_model?.name}
        subHeading={
          labelMap[
            projectLinkedModel.project_model?.project_model_type?.code || 'ner'
          ]
        }
        actions={
          <>
            <Button
              variant="text"
              color="error"
              startIcon={<Delete />}
              onClick={() => {
                deleteProjectLinkedModel(projectLinkedModel.id)
                navigate('../model-library')
              }}
            >
              Remove from Library
            </Button>

            {isSuperUser && !demoMode && (
              <CopyIDButton stringToCopy={projectLinkedModel.id} isSuperUser />
            )}
          </>
        }
      />

      <Stack spacing={4} sx={{ mt: 4 }}>
        <Stack spacing={1}>
          <Box>
            <Typography variant="h6">Model Version</Typography>
            <Typography variant="body2" color="text.secondary">
              Select the model version to use during prediction.
            </Typography>
          </Box>
          <Box>
            <ModelVersionSelect
              selectedVersionId={
                projectLinkedModel?.current_model_version_id || ''
              }
              setSelectedVersionId={(newVersionId) => {
                updateProjectLinkedModel({
                  ...projectLinkedModel,
                  current_model_version_id: newVersionId,
                } as ProjectLinkedModel)
              }}
              modelVersions={
                projectLinkedModel?.project_model?.project_model_versions || []
              }
            />
          </Box>
        </Stack>

        {currentModelVersion && (
          <Stack spacing={1}>
            <Box>
              <Typography variant="h6">Inputs and Outputs</Typography>
              <Typography variant="body2" color="text.secondary">
                These are the expected inputs and outputs for model version v
                {currentModelVersion.version}.
              </Typography>
            </Box>
            <Box
              sx={{
                height: 1000,
                border: (theme) => `solid 2px ${theme.palette.divider}`,
                borderRadius: 1,
              }}
            >
              <ModelOutputsDisplay
                projectLinkedModel={projectLinkedModel}
                projectModelVersionInfoTypes={
                  currentModelVersion?.project_model_version_info_types
                }
              />
            </Box>
          </Stack>
        )}
      </Stack>
    </Container>
  )
}
