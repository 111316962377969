import { ProjectGridField } from '@/types/fields'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'
import { createDetail, deleteCall, updateDetail } from '../request-wrappers'

const service = buildService<ProjectGridField>({
  pathCategory: 'project_grid_fields',
})

export function useGetProjectGridField({
  id,
  ...options
}: UseGetDetailOptions<ProjectGridField>) {
  const query = useGetDetail<ProjectGridField>({
    id,
    queryKey: queryKeys.projectGridFields.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    projectGridField: query.data,
    ...query,
  }
}

export function useCreateProjectGridField(
  options: UseCreateDetailOptions<ProjectGridField> = {},
) {
  const mutation = useCreateDetail<ProjectGridField>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createProjectGridField: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateProjectGridField(
  options: UseUpdateDetailOptions<ProjectGridField> = {},
) {
  const mutation = useUpdateDetail<ProjectGridField>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateProjectGridField: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteProjectGridField(
  options: UseDeleteDetailOptions = {},
) {
  const mutation = useDeleteDetail<ProjectGridField>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteProjectGridField: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetProjectGridFields({
  filters,
  ...options
}: UseGetListOptions<ProjectGridField> = {}) {
  const query = useGetList<ProjectGridField>({
    filters,
    queryKey: queryKeys.projectGridFields.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    projectGridFields: query.allData,
    ...query,
  }
}

export function useUpdateProjectGridFields(
  options: UseUpdateListOptions<ProjectGridField> = {},
) {
  const mutation = useUpdateList<ProjectGridField>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateProjectGridFields: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteProjectGridFields({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<ProjectGridField>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteProjectGridFields: mutation.mutateAsync,
    ...mutation,
  }
}

/**
 * MARK: Action Hook: Create ProjectGridField with Defaults
 * Creates a new ProjectGridField and runs any appropriate side effects, such as creating a sub project grid.
 */
export function useCreateProjectGridFieldWithDefaults(
  options: UseCreateDetailOptions<ProjectGridField> = {},
) {
  const mutation = useCreateDetail<ProjectGridField>({
    serviceFn: (serviceFnOptions) => {
      return createDetail({
        url: '/v2/pd/project_grid_fields/create-with-defaults',
        ...serviceFnOptions,
      })
    },
    ...options,
  })

  return {
    createProjectGridField: mutation.mutateAsync,
    ...mutation,
  }
}

/**
 * MARK: Action Hook: Update ProjectGridField with Defaults
 * Used to update a ProjectGridField and runs any appropriate side effects, such as updating info types.
 */
export function useUpdateProjectGridFieldWithDefaults(
  options: UseUpdateDetailOptions<ProjectGridField> = {},
) {
  const mutation = useUpdateDetail<ProjectGridField>({
    serviceFn: ({ item, ...serviceFnOptions }) => {
      return updateDetail<ProjectGridField>({
        url: `/v2/pd/project_grid_fields/${item.id}/update-with-defaults`,
        item,
        ...serviceFnOptions,
      })
    },
    ...options,
  })

  return {
    updateProjectGridField: mutation.mutateAsync,
    ...mutation,
  }
}

/**
 * MARK: Action Hook: Delete ProjectGridFields with Defaults
 * Used to a list of ProjectGridFields and runs any appropriate side effects, such as deleting info types.
 */
export function useDeleteProjectGridFieldsWithDefaults(
  options: UseDeleteListOptions = {},
) {
  const mutation = useDeleteList<ProjectGridField>({
    serviceFn: ({ ids, filters, axiosOptions }) => {
      return deleteCall({
        url: '/v2/pd/project_grid_fields/delete-with-defaults',
        filters: {
          id__in: ids.join(','),
          ...filters,
        },
        axiosOptions,
      })
    },
    ...options,
  })

  return {
    deleteProjectGridFields: mutation.mutateAsync,
    ...mutation,
  }
}
