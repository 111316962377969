import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { IconButton, Menu, MenuItem } from '@mui/material'
import useOverlay from '@/hooks/useOverlay'
import useIsSuperUser from '@/services/hooks/useIsSuperUser'
import { SUPER_USER_ONLY_COLOR } from '@/theme/usePixydocsTheme'
import { showErrorSnackbar, showInfoSnackbar } from '@/utils/snackbars'
import { useDemoModeContext } from '@/components/demo-mode-provider/DemoModeProvider'
import copyToClipboard from '@/components/document-actions-menu/copy-to-clipboard'
import { useDeleteDataListEntry } from '@/service-library/hooks/data-list-entries'
import { DataListEntry } from '@/types/data-lists'
import { QueryKey } from '@tanstack/react-query'

type EntryMenuProps = {
  isSuperUserOnly: boolean
  dataListEntry: DataListEntry
  listQueryKey: QueryKey
}

export default function EntryMenu({
  isSuperUserOnly,
  dataListEntry,
  listQueryKey,
}: EntryMenuProps) {
  const [demoMode] = useDemoModeContext()
  const isSuperUser = useIsSuperUser()
  const overlay = useOverlay()

  const { deleteDataListEntry } = useDeleteDataListEntry({
    listQueryKey,
    onError: () => {
      showErrorSnackbar('Failed to delete row.')
    },
  })

  return (
    <>
      <IconButton onClick={overlay.toggle}>
        <MoreHorizIcon
          sx={{ color: isSuperUserOnly ? SUPER_USER_ONLY_COLOR : undefined }}
        />
      </IconButton>
      <Menu
        anchorEl={overlay.anchorEl as Element}
        open={overlay.isOpen}
        onClose={overlay.close}
      >
        <MenuItem
          onClick={() => {
            deleteDataListEntry(dataListEntry.id)
            overlay.close()
          }}
        >
          Delete Row
        </MenuItem>
        {isSuperUser && !demoMode && (
          <MenuItem
            onClick={() => {
              copyToClipboard(dataListEntry.id)
              showInfoSnackbar('Copied to Clipboard')
              overlay.close()
            }}
            sx={{ color: SUPER_USER_ONLY_COLOR }}
          >
            Copy ID
          </MenuItem>
        )}
      </Menu>
    </>
  )
}
