import { useState } from 'react'
import { Add, Delete, Edit } from '@mui/icons-material'
import {
  Box,
  IconButton,
  lighten,
  darken,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { CustomVariableValue as TSCustomVariableValue } from '@/types/custom-variables'
import EditableNameField from '@/components/editable-name/EditableNameField'
import PermissionTooltip from '@/components/tooltip/PermissionTooltip'
import { Organization } from '@/types/organizations'
import VariableValueDisplay from './VariableValueDisplay'

type CustomVariableValueProps = {
  canEdit: boolean
  isRootOrg: boolean
  handleOnBlur: (value: string) => void
  onDelete: (variableValueId: string) => void
  variableValue?: TSCustomVariableValue
  resolvedValue: string | null
  organization: Organization
}

export default function CustomVariableValue({
  canEdit,
  isRootOrg,
  handleOnBlur,
  onDelete,
  variableValue,
  resolvedValue,
  organization,
}: CustomVariableValueProps) {
  const [isEditing, setIsEditing] = useState(false)
  const value = variableValue?.value || ''
  const isInheriting = !variableValue && !isRootOrg && resolvedValue !== null

  const addEditButton = (
    <IconButton
      disabled={!canEdit}
      onClick={(e) => {
        e.preventDefault()
        setIsEditing(true)
      }}
      size="small"
    >
      {variableValue ? (
        <Edit color={canEdit ? 'primary' : 'disabled'} fontSize="inherit" />
      ) : (
        <Add color={canEdit ? 'primary' : 'disabled'} fontSize="inherit" />
      )}
    </IconButton>
  )

  return (
    <Box
      sx={{
        background: (theme) =>
          variableValue
            ? theme.palette.mode === 'dark'
              ? lighten(theme.palette.background.paper, 0.05)
              : darken(theme.palette.background.paper, 0.025)
            : 'transparent',
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={0.5}
        justifyContent="space-between"
      >
        {!isEditing && (
          <Stack direction="row" alignItems="center" spacing={0.5}>
            {canEdit ? (
              <Tooltip title={`Set variable for ${organization.name}`}>
                {addEditButton}
              </Tooltip>
            ) : (
              <PermissionTooltip canEdit={canEdit} shortVersion>
                {addEditButton}
              </PermissionTooltip>
            )}

            {variableValue && (
              <PermissionTooltip canEdit={canEdit} shortVersion>
                <IconButton
                  disabled={!canEdit}
                  onClick={(e) => {
                    e.preventDefault()
                    onDelete(variableValue.id)
                  }}
                  size="small"
                >
                  <Delete
                    color={canEdit ? 'error' : 'disabled'}
                    fontSize="inherit"
                  />
                </IconButton>
              </PermissionTooltip>
            )}
          </Stack>
        )}

        {isEditing ? (
          <EditableNameField
            name={value}
            handleOnBlur={(newValue) => {
              handleOnBlur(newValue)
              setIsEditing(false)
            }}
          />
        ) : (
          <Stack direction="row" spacing={1} alignItems="center">
            <Box>
              {!variableValue && !isInheriting ? (
                <Typography variant="caption" color="text.secondary">
                  No Value Set
                </Typography>
              ) : (
                <VariableValueDisplay
                  variableValue={variableValue}
                  resolvedValue={resolvedValue}
                  isInheriting={isInheriting}
                />
              )}
            </Box>
          </Stack>
        )}
      </Stack>
    </Box>
  )
}
