import { useState, useRef, useMemo, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useHotkeys } from 'react-hotkeys-hook'
import { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch'
import 'split-pane-react/esm/themes/default.css'
import cloneDeep from 'lodash.clonedeep'
import { Card, Stack } from '@mui/material'
import { DocumentRow } from '@/types/documents'
import { ProjectGridField as TSField } from '@/types/fields'
import { MetricLog } from '@/types/metrics'
import { ProjectGrid } from '@/types/projects'
import useLocalStorage from '@/hooks/useLocalStorage'
import useTrackActiveTime from '@/hooks/useTrackActiveTime'
import useContentCategories from '@/services/hooks/useContentCategories'
import { useGetDocument } from '@/service-library/hooks/documents'
import { useGetProjectGrids } from '@/service-library/hooks/project-grids'
import { useCreateMetricLog } from '@/service-library/hooks/metric-logs'
import generateUuid from '@/utils/generate-uuid'
import {
  appendContentCategoriesToGrids,
  appendSubGridFieldsToBaseGridAndCleanFields,
  getBaseGrid,
  sortBySortOrder,
} from '@/utils/field-utils'
import { useAuthentication } from '@/components/auth/AuthProvider'
import DrawerPortal from '@/components/drawer/DrawerPortal'
import ImageZoomPan from '@/components/image-zoom-pan/ImageZoomPan'
import ImageZoomPanControls from '@/components/image-zoom-pan/ImageZoomPanControls'
import DocumentImagePageProvider from '@/components/image-zoom-pan/providers/DocumentImagePageProvider'
import ImageZoomPanCenterProvider from '@/components/image-zoom-pan/providers/ImageZoomPanCenterProvider'
import NavigationPanel from '@/components/image-zoom-pan/NavigationPanel'
import ValidationOptionsProvider from '@/components/image-zoom-pan/providers/ValidationOptionsProvider'
import { useProjectContext } from '@/components/project-dashboard/ProjectProvider'
import SizeProvider from '@/components/size-provider/SizeProvider'
import { useSelectedWorkflowContext } from '@/components/workflows/SelectedWorkflowProvider'
import DocumentRowProvider from './providers/DocumentRowProvider'
import { useDocumentRowValuesContext } from './providers/DocumentRowValuesProvider'
import { useSelectedFieldContext } from './providers/SelectedFieldProvider'
import FieldGrid from './FieldGrid'
import ValidationFieldsPanel from './ValidationFieldsPanel'
import useTrackUserActiveTime from './useTrackUserActiveTime'

type ValidationProps = {
  documentQuery: ReturnType<typeof useGetDocument>
}

export default function Validation({ documentQuery }: ValidationProps) {
  const { projectId } = useParams()

  const { user } = useAuthentication()
  const { document } = documentQuery
  const { fieldsWithFlags } = useDocumentRowValuesContext()
  const [selectedOptionKey] = useLocalStorage(
    'validation-visible-fields-option',
    'relevant',
  )

  const { project } = useProjectContext()
  const { setSelectedField } = useSelectedFieldContext()
  const { selectedWorkflow } = useSelectedWorkflowContext()

  const [fieldsContainerEl, setFieldsContainerEl] =
    useState<HTMLDivElement | null>(null)

  const transformerRef = useRef<ReactZoomPanPinchRef>(null)

  const [drawBoxes, setDrawBoxes] = useState(true)
  const [showConfidence, setShowConfidence] = useState(false)

  const [rotationDegree, setRotationDegree] = useState(0)
  const [validationLayout, setValidationLayout] = useLocalStorage<
    'right' | 'left' | 'top' | 'bottom'
  >('validation-page-orientation', 'right')

  const { projectGrids = [] } = useGetProjectGrids({
    filters: {
      limit: '1000',
      project_id: projectId,
      fields__include: 'project_grid_fields',
      project_grid_fields__fields__include:
        'project_grid_field_type,project_grid_field_rules',
      project_grid_fields__project_grid_field_rules__fields__include: 'rule',
    },
    initialListData: project?.project_grids,
  })

  const { categoriesContent = [], isLoading: categoriesIsLoading } =
    useContentCategories({
      projectId,
      refetchOnWindowFocus: true,
    })

  const { createMetricLog } = useCreateMetricLog()

  const workflowStates = selectedWorkflow?.workflow_states || []

  const workflowState = workflowStates.find((state) =>
    document?.workflow_states_ids?.some((id) => id === state.id),
  )

  const mainProjectGrid = getBaseGrid(projectGrids)

  const fields: TSField[] = useMemo(() => {
    if (projectGrids.length > 0 && !categoriesIsLoading) {
      const newGrids =
        categoriesContent.length > 0
          ? appendContentCategoriesToGrids(
              cloneDeep(projectGrids),
              categoriesContent,
            )
          : cloneDeep(projectGrids)
      const baseGrid = getBaseGrid(newGrids) as ProjectGrid
      const fullBaseGrid = appendSubGridFieldsToBaseGridAndCleanFields({
        baseGrid,
        grids: newGrids,
      })
      //TODO: Remove sorting for when the backend returns data sorted
      return sortBySortOrder(fullBaseGrid.project_grid_fields)
    }
    return []
  }, [categoriesContent, categoriesIsLoading, projectGrids])

  const groupedFields = useMemo(() => {
    return fields.reduce<TSField[][]>((acc, field) => {
      let isVisible = false
      switch (selectedOptionKey) {
        case 'relevant':
          // We don't check for children fields here because if all of them are excluded,
          // the parent field id will be excluded as well
          isVisible =
            !workflowState?.excluded_project_grid_fields_ids?.includes(field.id)
          break
        case 'all':
          isVisible = true
          break
        case 'flagged':
          if (field.fields) {
            isVisible = field.fields.some(({ id }) =>
              fieldsWithFlags.includes(id),
            )
          } else {
            isVisible = fieldsWithFlags.includes(field.id)
          }
          break
      }

      if (isVisible) {
        if (field.fields) {
          const tableFields =
            // :flagged: if the parent field is visible when this option is selected, it means
            // at least one of its children is visible, so we show all of them
            selectedOptionKey === 'all' || selectedOptionKey === 'flagged'
              ? field.fields
              : field.fields.filter(
                  (tableField) =>
                    !workflowState?.excluded_project_grid_fields_ids?.includes(
                      tableField.id,
                    ),
                )
          acc.push([{ ...field, fields: tableFields }])
          acc.push([])
        } else {
          if (acc.length === 0) {
            acc.push([])
          }
          acc[acc.length - 1].push(field)
        }
      }

      return acc
    }, [])
  }, [
    fields,
    fieldsWithFlags,
    selectedOptionKey,
    workflowState?.excluded_project_grid_fields_ids,
  ])

  const mainDocumentRow = document?.document_rows?.find(
    ({ project_grid_id }) => project_grid_id === mainProjectGrid?.id,
  ) as DocumentRow

  const rotateImage = () => {
    setRotationDegree((prev) => (prev + 90 === 360 ? 0 : prev + 90))
  }

  const onReport = useCallback(
    (startTime: Date, endTime: Date) => {
      if (document?.id) {
        createMetricLog({
          id: generateUuid(),
          start_time: startTime.toISOString(),
          end_time: endTime.toISOString(),
          obj_id: document.id,
          user_id: user?.id || null,
        } as MetricLog) // We don't pass duration because it's calculated on the backend
      }
    },
    [createMetricLog, document?.id, user?.id],
  )

  useEffect(() => {
    setSelectedField(null)
    setRotationDegree(0)
    // document.id isn't used in this useEffect, but we want it to fire whenever document.id changes
  }, [document?.id, setSelectedField])

  useEffect(() => {
    fieldsContainerEl?.scrollIntoView(true)
  }, [fieldsContainerEl])

  useHotkeys(
    'ctrl+meta+tab',
    () => {
      fieldsContainerEl?.children?.[0].querySelector('input')?.focus()
    },
    {
      preventDefault: true,
      enableOnFormTags: true,
    },
    [fieldsContainerEl],
  )

  useHotkeys(
    'esc',
    (e) => {
      ;(e.target as HTMLDivElement)?.blur()
    },
    {
      preventDefault: true,
      enableOnFormTags: true,
    },
    [],
  )

  useTrackActiveTime({ onReport })

  useTrackUserActiveTime({
    documentId: document?.id || '',
    workflowStateId: workflowState?.id || '',
  })

  const isVertical = validationLayout === 'top' || validationLayout === 'bottom'

  if (!document) return null

  return (
    <ValidationOptionsProvider document={documentQuery.document}>
      <DocumentImagePageProvider document={documentQuery.document}>
        <DocumentRowProvider documentRow={mainDocumentRow}>
          <ImageZoomPanCenterProvider>
            {/* overflowX hidden is needed because of the portal */}
            <Stack direction="row" sx={{ flexGrow: 1, overflowX: 'hidden' }}>
              <Stack
                key={validationLayout}
                alignItems="center"
                justifyContent="center"
                sx={{
                  position: 'relative',
                  minHeight: 0,
                  minWidth: 0,
                  flexGrow: 1,
                }}
              >
                {!workflowState ||
                workflowState?.code === 'processing' ? null : (
                  <>
                    <SizeProvider>
                      <ImageZoomPan
                        key={document.id} // Force remount when document changes so the image will properly update and state will reset
                        drawBoxes={drawBoxes}
                        rotationDegree={rotationDegree}
                        showConfidence={showConfidence}
                        transformerRef={transformerRef}
                        layout={validationLayout}
                        fieldsContainerEl={fieldsContainerEl}
                      />
                    </SizeProvider>

                    <NavigationPanel
                      controls={[
                        <ImageZoomPanControls
                          key="zoom-pan-controls"
                          hidePaginationControls={
                            !document?.document_pages.length ||
                            document?.document_pages.length <= 1
                          }
                          layout={validationLayout}
                          transformerRef={transformerRef}
                          rotateImage={rotateImage}
                          setDrawBoxes={setDrawBoxes}
                          setShowConfidence={setShowConfidence}
                          setValidationLayout={setValidationLayout}
                        />,
                      ]}
                      layout={validationLayout}
                    />

                    <ValidationFieldsPanel
                      layout={validationLayout}
                      setFieldsContainerEl={setFieldsContainerEl}
                    >
                      {groupedFields.map((group) => {
                        if (group.length === 0) return null
                        const isTableField = group[0].fields
                        return (
                          <Card
                            key={group[0].id}
                            elevation={isVertical ? 0 : 4}
                            sx={{
                              p: isTableField ? 0 : 1,
                              pointerEvents: 'all',
                            }}
                          >
                            <FieldGrid document={document} fields={group} />
                          </Card>
                        )
                      })}
                    </ValidationFieldsPanel>
                  </>
                )}
              </Stack>
              <DrawerPortal anchor="right" additionalId="validation" />
            </Stack>
          </ImageZoomPanCenterProvider>
        </DocumentRowProvider>
      </DocumentImagePageProvider>
    </ValidationOptionsProvider>
  )
}
