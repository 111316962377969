import { WorkflowStateEvent } from '@/types/workflow-state-events'
import {
  Stack,
  Paper,
  Typography,
  IconButton,
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
  Tooltip,
  Button,
  useTheme,
} from '@mui/material'
import { Draggable } from 'react-smooth-dnd'
import ActionCard from './ActionCard'
import {
  WorkflowStateAction,
  WorkflowStateActionType,
} from '@/types/workflow-state-actions'
import { useState, useRef } from 'react'
import {
  Add,
  Check,
  ContentCopy,
  Delete,
  DragIndicator,
  Edit,
  MoreVert,
  PanTool,
  Pause,
  PlayDisabled,
  TrendingFlat,
  VisibilityOff,
} from '@mui/icons-material'
import { useFeatureFlagContext } from '@/feature_flags/FeatureFlagProvider'
import useOverlay from '@/hooks/useOverlay'
import useIsSuperUser from '@/services/hooks/useIsSuperUser'
import { showErrorSnackbar, showInfoSnackbar } from '@/utils/snackbars'
import { SUPER_USER_ONLY_COLOR } from '@/theme/usePixydocsTheme'
import AddEditWorkflowStateEventDialog from '@/components/workflow-state-page/AddEditWorkflowStateEventDialog'
import { useWorkflowStatePageContext } from '@/components/workflow-state-page/WorkflowStatePage'
import { QueryKey } from '@tanstack/react-query'
import {
  useDeleteWorkflowStateEvent,
  useUpdateWorkflowStateEvent,
} from '@/service-library/hooks/workflow-state-events'
import { indigo } from '@mui/material/colors'
import { useDemoModeContext } from '@/components/demo-mode-provider/DemoModeProvider'
import { DraggableList } from '@/components/lists/DraggableList'
import { useGetWorkflowStateActionTypes } from '@/service-library/hooks/workflow-state-action-types'
import generateUuid from '@/utils/generate-uuid'
import copyToClipboard from '@/components/document-actions-menu/copy-to-clipboard'

const whenDocHasErrorsMap = {
  trigger: {
    message: 'Fire Without Warning',
    icon: Check,
  },
  trigger_with_warning: {
    message: 'Warn Before Firing',
    icon: Pause,
  },
  do_not_trigger: {
    message: 'Requires Clean Document',
    icon: PlayDisabled,
  },
}

type EventLaneProps = {
  workflowStateEvent: WorkflowStateEvent
  listQueryKey: QueryKey
  isDraggable?: boolean
  isPrimaryEvent?: boolean
}

export default function EventLane({
  workflowStateEvent,
  listQueryKey,
  isDraggable = false,
  isPrimaryEvent = false,
}: EventLaneProps) {
  const editOverlay = useOverlay()
  const laneMenuOverlay = useOverlay()
  const newActionOverlay = useOverlay()
  const menuButtonRef = useRef<HTMLButtonElement>(null)
  const newActionMenuAnchorRef = useRef<HTMLButtonElement>(null)
  const workflowState = useWorkflowStatePageContext()
  const featureFlags = useFeatureFlagContext()
  const isSuperUser = useIsSuperUser()
  const [demoMode] = useDemoModeContext()

  const isSuperUserVisible = isSuperUser && !demoMode

  const [actionsBeingEdited, setActionsBeingEdited] = useState<
    Record<string, boolean>
  >({})

  const { workflowStateActionTypes } = useGetWorkflowStateActionTypes({
    filters: { limit: '1000' },
  })

  const { updateWorkflowStateEvent } = useUpdateWorkflowStateEvent({
    listQueryKey,
    onError: () => {
      showErrorSnackbar('Failed to update event. Please try again later.')
    },
  })

  const { deleteWorkflowStateEvent } = useDeleteWorkflowStateEvent({
    listQueryKey,
    onError: () => {
      showErrorSnackbar('Failed to delete event. Please try again later.')
    },
  })

  // We use state here to prevent flickering when drag-and-dropping to change sort order
  const [actions, setActions] = useState<WorkflowStateAction[]>(
    workflowStateEvent.actions || [],
  )

  function onReorder(updatedWorkflowStateActions: WorkflowStateAction[]) {
    const updatedOrder = updatedWorkflowStateActions.map((state, index) => ({
      ...state,
      sort_order: index,
    }))
    setActions(updatedOrder)
    updateWorkflowStateEvent({
      ...workflowStateEvent,
      actions: updatedOrder,
    })
  }

  function onDeleteAction(action: WorkflowStateAction) {
    const updatedActions = actions.filter((a) => a.id !== action.id)
    setActions(updatedActions)
    updateWorkflowStateEvent({
      ...workflowStateEvent,
      actions: updatedActions,
    })
  }

  function onSaveAction(action: WorkflowStateAction) {
    const updatedActions = actions.map((a) => (a.id === action.id ? action : a))
    setActions(updatedActions)
    updateWorkflowStateEvent({
      ...workflowStateEvent,
      actions: updatedActions,
    })
  }

  function onAddAction(actionType: WorkflowStateActionType) {
    const actionId = generateUuid()

    setActions((prev = []) => [
      ...prev,
      {
        id: actionId,
        name: actionType.name,
        sort_order: prev.length + 1000000,
        event_id: workflowStateEvent?.id || '',
        action_type_id: actionType.id,
        params: {},
        action_type: actionType,
        is_enabled: true,
      },
    ])
    newActionOverlay.close()

    // Set isEditing to true so that the action card is in edit mode when it shows up
    setActionsBeingEdited((prev) => ({
      ...prev,
      [actionId]: true,
    }))
  }

  // If this is a new, unsaved action, remove it from the list of actions
  function onCancelAction(action: WorkflowStateAction) {
    if (!workflowStateEvent.actions?.find((a) => a.id === action.id)) {
      setActions((prev) => prev.filter((a) => a.id !== action.id))
    }
  }

  const theme = useTheme()

  const Icon =
    whenDocHasErrorsMap[workflowStateEvent.when_document_has_errors].icon

  return (
    <Stack direction="row" component={Paper} square>
      <Paper
        sx={{
          width: 300,
          maxWidth: 300,
          minWidth: 300,
          p: 2,
          position: 'sticky',
          left: 0,
          zIndex: 2,
          borderRight:
            theme.palette.mode === 'light'
              ? `1px solid ${theme.palette.divider}`
              : 'none',
        }}
        elevation={0}
        square
      >
        <Stack direction="row" spacing={1} alignItems="center">
          {isDraggable && (
            <DragIndicator
              className="event-drag-handle"
              sx={{
                cursor: 'grab',
                ':active': { cursor: 'grabbing' },
              }}
            />
          )}
          <Typography
            variant="body1"
            sx={{ flexGrow: 1, pl: isDraggable ? 0 : '6px' }}
          >
            {workflowStateEvent.name}
          </Typography>

          <IconButton
            size="small"
            ref={menuButtonRef}
            onClick={laneMenuOverlay.open}
          >
            <MoreVert />
          </IconButton>

          {/* MARK: Lane Menu */}
          <Menu
            open={laneMenuOverlay.isOpen}
            anchorEl={menuButtonRef.current}
            onClose={laneMenuOverlay.close}
          >
            <MenuItem
              onClick={() => {
                laneMenuOverlay.close()
                editOverlay.open()
              }}
            >
              <ListItemIcon>
                <Edit fontSize="small" />
              </ListItemIcon>
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                copyToClipboard(workflowStateEvent.id)
                showInfoSnackbar('Copied to Clipboard')
                laneMenuOverlay.close()
              }}
            >
              <ListItemIcon>
                <ContentCopy fontSize="small" />
              </ListItemIcon>
              Copy ID
            </MenuItem>
            <MenuItem
              onClick={() => {
                deleteWorkflowStateEvent(workflowStateEvent.id)
                laneMenuOverlay.close()
              }}
            >
              <ListItemIcon>
                <Delete fontSize="small" />
              </ListItemIcon>
              Delete
            </MenuItem>
          </Menu>

          {/* MARK: Edit Dialog */}
          {workflowState && (
            <AddEditWorkflowStateEventDialog
              overlay={editOverlay}
              workflowState={workflowState}
              workflowStateEvent={workflowStateEvent}
              listQueryKey={listQueryKey}
            />
          )}
        </Stack>

        {workflowStateEvent.code !== 'on_enter' &&
          workflowStateEvent.code !== 'on_exit' && (
            <Box sx={{ ml: '6px', mt: 1 }}>
              <Stack direction="row" alignItems="center" spacing={1}>
                {workflowStateEvent.move_to_next_doc_in_validation ? (
                  <TrendingFlat
                    sx={{ fontSize: 14, color: 'text.secondary' }}
                  />
                ) : (
                  <PanTool sx={{ fontSize: 14, color: 'text.secondary' }} />
                )}

                <Typography
                  variant="caption"
                  sx={{
                    display: 'block',
                    color: 'text.secondary',
                    transform: 'translateY(2px)',
                  }}
                >
                  {workflowStateEvent.move_to_next_doc_in_validation
                    ? 'Move to Next'
                    : 'Stay on Document'}
                </Typography>
              </Stack>

              <Stack direction="row" alignItems="center" spacing={1}>
                <Icon sx={{ fontSize: 14, color: 'text.secondary' }} />

                <Typography
                  variant="caption"
                  sx={{
                    display: 'block',
                    color: 'text.secondary',
                    transform: 'translateY(2px)',
                  }}
                >
                  {
                    whenDocHasErrorsMap[
                      workflowStateEvent.when_document_has_errors
                    ].message
                  }
                </Typography>
              </Stack>

              {isPrimaryEvent && (
                <Tooltip title="This event will be shown as the primary option wherever these events can be triggered.">
                  <Typography
                    variant="caption"
                    sx={{
                      ml: '2px',
                      display: 'block',
                      transform: 'translateY(2px)',
                      color: indigo['A200'],
                    }}
                  >
                    Primary Event
                  </Typography>
                </Tooltip>
              )}

              {!workflowStateEvent.allow_manual_trigger && (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <VisibilityOff
                    sx={{ fontSize: 14, color: 'text.secondary' }}
                  />

                  <Typography
                    variant="caption"
                    sx={{
                      display: 'block',
                      color: 'text.secondary',
                      transform: 'translateY(2px)',
                    }}
                  >
                    Cannot Trigger Manually
                  </Typography>
                </Stack>
              )}
            </Box>
          )}
      </Paper>

      {/* MARK: Action Cards */}
      <Stack
        direction="row"
        spacing={1}
        sx={{ py: 2, px: 1, flexGrow: 1, overflow: 'auto' }}
      >
        {actions.length > 0 && (
          <DraggableList
            orientation="horizontal"
            lockAxis="x"
            onChange={onReorder}
            data={actions}
          >
            {actions?.map((action) => {
              if (
                !featureFlags.show_action_python_script &&
                action.action_type?.code === 'custom_python_script'
              )
                return null
              return (
                // @ts-expect-error -- Draggable can have children
                <Draggable key={action.id}>
                  <ActionCard
                    action={action}
                    isEditing={actionsBeingEdited[action.id]}
                    setIsEditing={(isEditing) => {
                      setActionsBeingEdited((prev) => ({
                        ...prev,
                        [action.id]: isEditing,
                      }))
                    }}
                    onSave={onSaveAction}
                    onDelete={onDeleteAction}
                    onCancel={onCancelAction}
                  />
                </Draggable>
              )
            })}
          </DraggableList>
        )}

        <Box>
          <Button
            variant="text"
            startIcon={<Add />}
            sx={{ mt: '1px', flexShrink: 0, textWrap: 'nowrap' }}
            onClick={newActionOverlay.open}
            ref={newActionMenuAnchorRef}
          >
            Add Action
          </Button>

          <Menu
            anchorEl={newActionMenuAnchorRef.current}
            open={newActionOverlay.isOpen}
            onClose={newActionOverlay.close}
          >
            {workflowStateActionTypes.map((actionType, index) => {
              if (
                actionType.code === 'export_to_csv' ||
                actionType.code === 'export_to_json' ||
                (!featureFlags.show_action_python_script &&
                  actionType.code === 'custom_python_script') ||
                (!featureFlags.use_new_run_prediction_model &&
                  actionType.code === 'run_prediction_linked_model') ||
                (featureFlags.use_new_run_prediction_model &&
                  actionType.code === 'run_prediction_model') ||
                (actionType.is_superuser && !isSuperUserVisible)
              )
                return null
              return (
                <MenuItem
                  key={index}
                  onClick={() => {
                    onAddAction(actionType)
                  }}
                  sx={{
                    color: actionType.is_superuser
                      ? SUPER_USER_ONLY_COLOR
                      : undefined,
                  }}
                >
                  {actionType.name}
                </MenuItem>
              )
            })}
          </Menu>

          <Box sx={{ width: 300 }} />
        </Box>
      </Stack>
    </Stack>
  )
}
