/* eslint-disable @typescript-eslint/no-explicit-any */
import { Filters } from './request-types'

type ListParams = { filters?: Filters }

function buildQueryKeys(label: string) {
  const keys = {
    all: [label],
    details: () => [...keys.all, 'detail'],
    lists: () => [...keys.all, 'list'],
    detail: (
      { id, filters }: { id: string; filters?: Filters },
      modifiers: any[] = [],
    ) => [...keys.details(), id, filters, modifiers],
    list: ({ filters }: ListParams = {}, modifiers: any[] = []) => [
      ...keys.lists(),
      filters,
      modifiers,
    ],
  }
  return keys
}

export default {
  billingEvents: buildQueryKeys('billingEvents'),
  customers: buildQueryKeys('customers'),
  customVariableCategories: buildQueryKeys('customVariableCategories'),
  customVariables: buildQueryKeys('customVariables'),
  customVariableValues: buildQueryKeys('customVariableValues'),
  dataLists: buildQueryKeys('dataLists'),
  dataListEntries: buildQueryKeys('dataListEntries'),
  dataListEntryCells: buildQueryKeys('dataListEntryCells'),
  dataListEntryCellValues: buildQueryKeys('dataListEntryCellValues'),
  dataListColumns: buildQueryKeys('dataListColumns'),
  dataListColumnTypes: buildQueryKeys('dataListColumnTypes'),
  dataListTypes: buildQueryKeys('dataListTypes'),
  docAuditEntries: buildQueryKeys('docAuditEntries'),
  docProjectTags: buildQueryKeys('docProjectTags'),
  documents: buildQueryKeys('documents'),
  documentChips: buildQueryKeys('documentChips'),
  documentFlags: buildQueryKeys('documentFlags'),
  documentRows: buildQueryKeys('documentRows'),
  documentRowValues: buildQueryKeys('documentRowValues'),
  documentWorkflowStates: buildQueryKeys('documentWorkflowStates'),
  events: buildQueryKeys('events'),
  exportJobs: buildQueryKeys('exportJobs'),
  fieldRules: buildQueryKeys('fieldRules'),
  fieldTypeRules: buildQueryKeys('fieldTypeRules'),
  flagTypes: buildQueryKeys('flagTypes'),
  groups: buildQueryKeys('groups'),
  importerEmails: buildQueryKeys('importerEmails'),
  integrations: buildQueryKeys('integrations'),
  integrationTypes: buildQueryKeys('integrationTypes'),
  languages: buildQueryKeys('languages'),
  logEntries: buildQueryKeys('logEntries'),
  metricLogs: buildQueryKeys('metricLogs'),
  organizations: buildQueryKeys('organizations'),
  orgCredentials: buildQueryKeys('orgCredentials'),
  orgCredentialTypes: buildQueryKeys('orgCredentialTypes'),
  orgTypes: buildQueryKeys('orgTypes'),
  projectCategories: buildQueryKeys('projectCategories'),
  projectDocumentRules: buildQueryKeys('projectDocumentRules'),
  projectDocumentRuleTypes: buildQueryKeys('projectDocumentRuleTypes'),
  projectDynamoSettings: buildQueryKeys('projectDynamoSettings'),
  projectGrids: buildQueryKeys('projectGrids'),
  projectGridFields: buildQueryKeys('projectGridFields'),
  projectGridFieldRules: buildQueryKeys('projectGridFieldRules'),
  projectGridFieldTypes: buildQueryKeys('projectGridFieldTypes'),
  projectLinkedModels: buildQueryKeys('projectLinkedModels'),
  projectLinkedModelInfoTypes: buildQueryKeys('projectLinkedModelInfoTypes'),
  projectMappings: buildQueryKeys('projectMappings'),
  projectMappingFields: buildQueryKeys('projectMappingFields'),
  projectMappingTypes: buildQueryKeys('projectMappingTypes'),
  projectModels: buildQueryKeys('projectModels'),
  projectModelTypes: buildQueryKeys('projectModelTypes'),
  projectModelTypeFieldTypeRestrictions: buildQueryKeys(
    'projectModelTypeFieldTypeRestrictions',
  ),
  projectModelTrainingFields: buildQueryKeys('projectModelTrainingFields'),
  projectModelVersions: buildQueryKeys('projectModelVersions'),
  projectModelVersionCategories: buildQueryKeys(
    'projectModelVersionCategories',
  ),
  projectModelVersionFields: buildQueryKeys('projectModelVersionFields'),
  projectTags: buildQueryKeys('projectTags'),
  projects: buildQueryKeys('projects'),
  rowValueFlags: buildQueryKeys('rowValueFlags'),
  rules: buildQueryKeys('rules'),
  savedViews: buildQueryKeys('savedViews'),
  teams: buildQueryKeys('teams'),
  teamUsers: buildQueryKeys('teamUsers'),
  teamVisibleOrgs: buildQueryKeys('teamVisibleOrgs'),
  users: buildQueryKeys('users'),
  workflows: buildQueryKeys('workflows'),
  workflowStates: buildQueryKeys('workflowStates'),
  workflowStateActions: buildQueryKeys('workflowStateActions'),
  workflowStateActionTypes: buildQueryKeys('workflowStateActionTypes'),
  workflowStateEvents: buildQueryKeys('workflowStateEvents'),
}
