import { Box, Button, Card, Stack, Typography } from '@mui/material'
import { Document } from '@/types/documents'
import SplitDocumentImages from './SplitDocumentPages'
import SplitDocumentOptions from './SplitDocumentOptions'
import useSplitDocuments from './useSplitDocuments'

type SplitDocumentViewProps = {
  document: Document
  onConfirm: () => void
  onDelete: () => void
}

export default function SplitDocumentView({
  document,
  onConfirm,
  onDelete,
}: SplitDocumentViewProps) {
  const data = useSplitDocuments({
    document,
    onDelete,
  })

  const { splitPoints, createDocuments, resetStates } = data

  return document.page_count > 1 ? (
    <Stack sx={{ p: 2, height: '100%' }}>
      <Card
        component={Stack}
        spacing={2}
        sx={{ p: 2, width: 'fit-content', alignSelf: 'flex-end' }}
      >
        <Typography>
          Click between the page images to choose where to split the document.
          Each section will become a new document.
        </Typography>
        <SplitDocumentOptions
          pages={document.document_pages}
          splitDocumentsData={data}
        />
        <Box sx={{ alignSelf: 'flex-end' }}>
          <Button
            disabled={!splitPoints.length}
            variant="contained"
            onClick={() => {
              createDocuments()
              onConfirm()
              resetStates()
            }}
          >
            Confirm
          </Button>
        </Box>
      </Card>
      <Stack justifyContent="center" flexGrow={1}>
        <SplitDocumentImages
          document={document}
          splitDocumentsData={data}
          useFullImage
        />
      </Stack>
    </Stack>
  ) : (
    <Stack alignItems="center" spacing={3} sx={{ p: 2, margin: 'auto 0' }}>
      <Typography>This document only has one page.</Typography>
      <Box
        sx={{
          height: '70vh',
          textAlign: 'center',
        }}
      >
        <img
          loading="lazy"
          src={document.document_pages[0].image_urls.full}
          style={{ objectFit: 'contain', height: '100%', width: '100%' }}
        />
      </Box>
    </Stack>
  )
}
