import {
  createContext,
  ReactNode,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react'
import { fetchAndActivate, getAll } from '@firebase/remote-config'
import { remoteConfig } from './firebase_config'
import useLocalStorage from '@/hooks/useLocalStorage'

// Only for development
if (
  import.meta.env.VITE_ENVIRONMENT !== 'stage' &&
  import.meta.env.VITE_ENVIRONMENT !== 'prod'
) {
  remoteConfig.settings.minimumFetchIntervalMillis = 0
} else {
  remoteConfig.settings.minimumFetchIntervalMillis = 1000 * 60 * 10 // ten minutes
}

type Flags = Record<string, boolean>
type FeatureFlagContextValue = {
  featureFlags: Flags
  remoteFlags: Flags
  storedFlags: Flags
  updateFlag: (key: string, value: boolean) => void
  clearStoredFlags: () => void
}

const defaults: FeatureFlagContextValue = {
  updateFlag: () => {},
  clearStoredFlags: () => {},
  remoteFlags: {},
  storedFlags: {},
  featureFlags: {
    teams: false,
    is_internal_user: false,
    salesrabbit_demo: false,
    customer_page_visible: false,
    bar_chart_field_type: false,
    org_lists: false,
    org_custom_variables: false,
    custom_python_rules: false,
    address_field_type: false,
    quality_control_ui: false,
    rules_in_workflow_states: false,
    show_action_python_script: false,
    model_library: false,
    comparison_view: false,
    split_document_view: false,
    show_in_and_out_training_field_in_model_config: true,
    use_new_run_prediction_model: false,
  },
}

export const FeatureFlagContext =
  createContext<FeatureFlagContextValue>(defaults)

export const useFeatureFlagContext = () => {
  const { featureFlags } = useContext(FeatureFlagContext)
  return featureFlags
}

type FeatureFlagProviderProps = {
  children: ReactNode
}

export default function FeatureFlagProvider({
  children,
}: FeatureFlagProviderProps) {
  const [storedFlags, setStoredFlags] = useLocalStorage<Flags>(
    'feature-flags',
    {},
  )
  const [remoteFlags, setRemoteFlags] = useState<Flags>(defaults.featureFlags)

  useEffect(() => {
    remoteConfig.defaultConfig = defaults.featureFlags

    fetchAndActivate(remoteConfig)
      .then((activated) => {
        // eslint-disable-next-line no-console
        if (!activated) console.warn('Remote Feature flags not activated!!')
        return getAll(remoteConfig)
      })
      .then((remoteFlags) => {
        const newFlags: Flags = {}
        Object.entries(remoteFlags).forEach(([key, value]) => {
          newFlags[key] = value.asBoolean()
        })
        setRemoteFlags(newFlags)
      })
  }, [])

  function updateFlag(key: string, value: boolean) {
    setStoredFlags({
      ...storedFlags,
      [key]: value,
    })
  }

  function clearStoredFlags() {
    setStoredFlags({})
  }

  const flags = useMemo(
    () => ({
      ...defaults.featureFlags,
      ...remoteFlags,
      ...storedFlags,
    }),
    [remoteFlags, storedFlags],
  )

  return (
    <FeatureFlagContext.Provider
      value={{
        featureFlags: flags,
        remoteFlags,
        storedFlags,
        updateFlag,
        clearStoredFlags,
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  )
}
