import { useMemo } from 'react'
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Button,
} from '@mui/material'
import { useGetLogEntries } from '@/service-library/hooks/log-entries'
import { useGetUsers } from '@/service-library/hooks/users'
import { prettifyDate } from '@/utils/getFormattedDateTimeString'
import SectionAccordion from './SectionAccordion'
import EntryLongDescription from './EntryLongDescription'

type DocumentLogEntriesProps = {
  documentId: string
  enabled: boolean
}

export default function DocumentLogEntries({
  documentId,
  enabled,
}: DocumentLogEntriesProps) {
  const {
    logEntries,
    isLoading: entriesIsLoading,
    fetchNextPage,
    hasNextPage, // It might be true even when no more entries are available since dynamo does pagination different, but once we get an empty page, it will be false
    isFetchingNextPage,
  } = useGetLogEntries({
    filters: {
      endpoint: '/v2/pd/documents',
      obj_id: documentId,
      limit: '30',
      reverse: 'true',
    },
    enabled,
  })

  const userIds = useMemo(
    () =>
      logEntries.reduce<string[]>((acc, entry) => {
        if (entry.user_id && !acc.includes(entry.user_id)) {
          acc.push(entry.user_id)
        }
        return acc
      }, []),
    [logEntries],
  )

  const { users, isFetching: usersIsFetching } = useGetUsers({
    filters: {
      id__in: userIds.join(','),
      fields__only: 'id,name',
    },
    enabled: enabled && !entriesIsLoading && !!userIds.length,
    keepPreviousData: true,
  })

  const usersMap = users.reduce<Record<string, string>>((acc, user) => {
    acc[user.id] = user.name
    return acc
  }, {})

  return (
    <SectionAccordion title="Activity Log Entries">
      <List disablePadding>
        {entriesIsLoading && (
          <>
            <ListItem disablePadding>
              <ListItemText
                primary={<Skeleton animation="wave" width="85%" />}
                secondary={<Skeleton animation="wave" width="40%" />}
              />
            </ListItem>
            <ListItem disablePadding>
              <ListItemText
                primary={<Skeleton animation="wave" width="80%" />}
                secondary={<Skeleton animation="wave" width="40%" />}
              />
            </ListItem>
          </>
        )}
        {!entriesIsLoading &&
          (logEntries.length ? (
            logEntries.map((logEntry) => {
              const { id, created_at, user_id } = logEntry
              const userName = usersMap[user_id]
              return (
                <ListItem alignItems="flex-start" key={id} disablePadding>
                  <ListItemText
                    primary={
                      <>
                        {!userName && usersIsFetching ? (
                          <Skeleton
                            width={100}
                            sx={{ display: 'inline-block' }}
                          />
                        ) : (
                          <Typography
                            component="span"
                            variant="body2"
                            sx={{ color: 'text.primary', display: 'inline' }}
                          >
                            <b>{userName || 'System'}</b>
                          </Typography>
                        )}
                        <Typography variant="caption">
                          {' - '}
                          <Typography color="text.secondary" variant="caption">
                            {prettifyDate(created_at)}
                            {' - '}
                          </Typography>
                          <EntryLongDescription {...logEntry} />
                        </Typography>
                      </>
                    }
                    primaryTypographyProps={{
                      component: 'p',
                      sx: {
                        lineHeight: 1,
                      },
                    }}
                    sx={{ my: '2px' }}
                  />
                </ListItem>
              )
            })
          ) : (
            <Typography color="text.secondary" variant="body2">
              None
            </Typography>
          ))}
      </List>

      {!entriesIsLoading && hasNextPage && (
        <Button
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
          sx={{ mt: 1, ml: -0.5 }}
          variant="text"
        >
          {isFetchingNextPage ? 'Loading...' : 'Load More'}
        </Button>
      )}
    </SectionAccordion>
  )
}
