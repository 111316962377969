import { useParams } from 'react-router-dom'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import { OverlayState } from '@/hooks/useOverlay'
import { Typography, Button, Stack, Skeleton } from '@mui/material'
import { User } from '@/types/users'
import { LoadingButton } from '@mui/lab'
import { WorkflowState } from '@/types/workflows'
import ZerapixTable from '../zerapix-table/ZerapixTable'
import { MRT_ColumnDef } from 'material-react-table'
import { useProjectContext } from './ProjectProvider'
import useZerapixTable from '../zerapix-table/useZerapixTable'
import {
  useGetDocumentWorkflowStates,
  useSetDocumentsWorkflowState,
} from '@/service-library/hooks/document-workflow-states'

type FailedExportsDialogProps = {
  workflowState: WorkflowState
  overlay: OverlayState
}

type MinimalDocument = {
  id: string
  name: string
  uploaded_at: string
  created_by_user: User | null
}

const columns: MRT_ColumnDef<MinimalDocument>[] = [
  {
    header: 'Name',
    accessorKey: 'name',
  },
  {
    header: 'Upload Date',
    accessorKey: 'uploaded_at',
    Cell: ({ row }) => {
      return new Date(row.original.uploaded_at).toLocaleDateString()
    },
  },
  {
    id: 'submitter',
    header: 'Submitter',
    accessorFn: (originalRow) => originalRow.created_by_user?.name || '',
  },
]

export default function FailedExportsDialog({
  workflowState,
  overlay,
}: FailedExportsDialogProps) {
  const { project } = useProjectContext()
  const { view } = useParams()

  const { documentWorkflowStates = [], isLoading } =
    useGetDocumentWorkflowStates({
      filters: {
        limit: '1000',
        workflow_state_id__in: workflowState.id,
        status__in: 'error',
        fields__include: 'document',
        document__fields__only: 'uploaded_at,id,name,created_by_user',
      },
      enabled: overlay.isOpen, // Only fetch the documents once the overlay is opened
    })

  const documents = documentWorkflowStates.map(({ document_id, document }) => ({
    ...document,
    id: document_id,
  })) as MinimalDocument[]
  const { setWorkflowState, isLoading: isClearing } =
    useSetDocumentsWorkflowState({
      onSuccess: () => {
        overlay.close()
      },
    })

  function handleClearErrorStatuses() {
    setWorkflowState({
      patch: {
        workflow_state__code: workflowState.code,
        workflow_state__id: workflowState.id,
      },
      filter_criteria: {
        workflow_state__workflow_id: workflowState.workflow_id,
        document_id__in: documents.map(({ id }) => id),
      },
      current_workflow_state_id: workflowState.id,
    })
  }

  const table = useZerapixTable<MinimalDocument>({
    columns,
    data: documents,
    enableTopToolbar: false,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        window.open(
          `/projects/${project.id}/${view || 'documents'}?workflow=${
            workflowState.workflow_id
          }&workflow_state=${workflowState.id}&document_id=${row.original.id}`,
          '_blank',
        )
      },
      sx: {
        cursor: 'pointer',
      },
    }),
  })

  return (
    <Dialog title="Failed Exports" {...overlay} maxWidth="sm">
      <DialogContent>
        <Typography sx={{ pb: 2 }}>
          These documents failed during export. Please try again later. If these
          documents continue to fail, please contact support.
        </Typography>

        {isLoading && (
          <Stack spacing={1}>
            <Skeleton variant="text" width={400} />
            <Skeleton variant="text" width={250} />
            <Skeleton variant="text" width={350} />
          </Stack>
        )}

        {/* Table that shows each documents: Name, Upload Date, Submitter Name */}
        <ZerapixTable<MinimalDocument> table={table} />
      </DialogContent>

      <DialogFooter>
        <Button
          variant="outlined"
          onClick={() => {
            overlay.close()
          }}
        >
          Close
        </Button>
        <LoadingButton
          variant="outlined"
          loading={isLoading || isClearing}
          onClick={() => {
            handleClearErrorStatuses()
          }}
        >
          Clear Errors
        </LoadingButton>
      </DialogFooter>
    </Dialog>
  )
}
