import {
  CustomVariable,
  CustomVariableCategory,
  CustomVariableValue as TSCustomVariableValue,
} from '@/types/custom-variables'
import { Box, Stack, Typography } from '@mui/material'
import { QueryKey } from '@tanstack/react-query'
import CustomVariableValueRow from './CustomVariableValueRow'
import { Organization } from '@/types/organizations'

type CustomVariableValuesTableProps = {
  category: CustomVariableCategory & { variables: CustomVariable[] }
  customVariableValuesQueryKey: QueryKey
  resolvedCustomVariableValuesQueryKey: QueryKey
  resolvedCustomVariableValues: Record<string, string | null>
  customVariableValues: TSCustomVariableValue[]
  organization: Organization
}

export default function CustomVariableValuesTable({
  category,
  customVariableValuesQueryKey,
  resolvedCustomVariableValuesQueryKey,
  resolvedCustomVariableValues,
  customVariableValues,
  organization,
}: CustomVariableValuesTableProps) {
  return (
    <Stack spacing={2}>
      <Typography variant="h5">{category.name}</Typography>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '25% 25% 2fr',
          background: (theme) => theme.palette.background.paper,
          width: '100%',
          '--Grid-borderWidth': '1px',
          borderTop: 'var(--Grid-borderWidth) solid',
          borderLeft: 'var(--Grid-borderWidth) solid',
          borderColor: 'divider',
          '& > div': {
            borderRight: 'var(--Grid-borderWidth) solid',
            borderBottom: 'var(--Grid-borderWidth) solid',
            borderColor: 'divider',
            p: 1,
          },
        }}
      >
        <Box sx={{ gridColumn: 'span 1', borderRight: 'none !important' }}>
          <Typography variant="body1">Variable</Typography>
        </Box>
        <Box sx={{ borderRight: 'none !important' }}>
          <Typography variant="body1">Value</Typography>
        </Box>
        <Box>
          <Typography variant="body1">Description</Typography>
        </Box>

        {category.variables.map((variable) => {
          const variableValue = customVariableValues.find(
            ({ custom_variable_id }) => custom_variable_id === variable.id,
          )

          return (
            <CustomVariableValueRow
              key={variable.id}
              variable={variable}
              value={variableValue}
              customVariableValuesQueryKey={customVariableValuesQueryKey}
              resolvedCustomVariableValuesQueryKey={
                resolvedCustomVariableValuesQueryKey
              }
              resolvedValue={resolvedCustomVariableValues[variable.name]}
              organization={organization}
            />
          )
        })}

        {category.variables.length === 0 && (
          <Box sx={{ gridColumn: 'span 3' }}>
            <Typography variant="body2" color="text.secondary">
              <i>None</i>
            </Typography>
          </Box>
        )}
      </Box>
    </Stack>
  )
}
