import { useEffect, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { Document } from '@/types/documents'
import { DocumentFlag, RowValueFlag } from '@/types/flags'
import { WorkflowStateEvent } from '@/types/workflow-state-events'
import useOverlay from '@/hooks/useOverlay'
import {
  useTriggerWorkflowStateEvent,
  useWorkflowStateEventStatuses,
} from '@/service-library/hooks/events'
import { useGetWorkflowStateEvents } from '@/service-library/hooks/workflow-state-events'
import { getFlagsByLevel, getFlagsCountFromLevel } from '@/utils/flags-utils'
import useFlagPriorityColor from '@/components/flags/useFlagPriorityColor'
import { useNotifications } from '@/components/notifications/NotificationProvider'
import { useDocumentRowValuesContext } from './providers/DocumentRowValuesProvider'
import DocumentsWithErrorsDialog from './DocumentsWithErrorsDialog'
import EventButton from './EventButton'

type ValidationEventButtonProps = {
  document: Document
  goToNextAvailableDocumentOrClose: () => void
  workflowStateId: string
}

export default function ValidationEventButton({
  document,
  goToNextAvailableDocumentOrClose,
  workflowStateId,
}: ValidationEventButtonProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [triggeredEventDetails, setTriggeredEventDetails] = useState<{
    event: WorkflowStateEvent
    stayOnCurrenDoc: boolean
  }>()

  const hasErrorsOverlay = useOverlay()

  const { rowValueFlags } = useDocumentRowValuesContext()
  const { triggerWorkflowStateEvent } = useTriggerWorkflowStateEvent()

  const { workflowStateEvents = [] } = useGetWorkflowStateEvents({
    filters: {
      workflow_state_id: workflowStateId,
      limit: '100',
    },
  })
  const visibleWorkflowStateEvents = workflowStateEvents.filter(
    ({ code, allow_manual_trigger }) =>
      code !== 'on_enter' && code !== 'on_exit' && allow_manual_trigger,
  )

  const [shouldPoll, setShouldPoll] = useState(false)
  const { eventStatuses = [], refetch: refetchEventStatuses } =
    useWorkflowStateEventStatuses({
      filters: {
        document_id: document.id,
      },
      enabled: !!visibleWorkflowStateEvents.length,
      refetchInterval: shouldPoll ? 2000 : false,
    })

  const statusesWeCareAbout = eventStatuses.filter(
    ({ status }) => status === 'waiting' || status === 'running',
  )

  useEffect(() => {
    setShouldPoll(statusesWeCareAbout.length > 0)
  }, [refetchEventStatuses, statusesWeCareAbout.length])

  const { document_flags = [] } = document

  const documentFlagsByLevel = getFlagsByLevel(document_flags)
  const rowFlagsByLevel = getFlagsByLevel(rowValueFlags)

  const { priorityLevel } = useFlagPriorityColor({
    documentFlags: getFlagsCountFromLevel(documentFlagsByLevel),
    rowsFlags: getFlagsCountFromLevel(rowFlagsByLevel),
  })

  const documentErrorFlags = documentFlagsByLevel.error as DocumentFlag[]
  const rowValueErrorFlags = rowFlagsByLevel.error as RowValueFlag[]
  const documentWarningFlags = documentFlagsByLevel.warning as DocumentFlag[]
  const rowValueWarningFlags = rowFlagsByLevel.warning as RowValueFlag[]
  const hasProblemFlags =
    priorityLevel === 'error' || priorityLevel === 'warning'

  function triggerEvent(event: WorkflowStateEvent, stayOnDoc: boolean) {
    setIsLoading(true)
    triggerWorkflowStateEvent([
      {
        workflow_state_event_id: event.id,
        document_id: document.id,
      },
    ])

    if (stayOnDoc) return

    if (
      event.move_to_next_doc_in_validation ||
      event.move_to_next_doc_in_validation === undefined
    ) {
      goToNextAvailableDocumentOrClose()
    }
  }

  function handleTriggerEvent(
    event: WorkflowStateEvent,
    keys: { shiftKey: boolean }, // Using this structure since the manual trigger will pass a mouse event
  ) {
    if (
      hasProblemFlags &&
      event.when_document_has_errors === 'trigger_with_warning'
    ) {
      hasErrorsOverlay.open()
      setTriggeredEventDetails({
        event,
        stayOnCurrenDoc: !!keys?.shiftKey,
      })
    } else {
      triggerEvent(event, !!keys?.shiftKey)
    }
  }

  // Reset loading whenever we switch documents
  useEffect(() => {
    setIsLoading(false)
  }, [document.id])

  useNotifications({
    keys: ['pdWorkflow/events.event_complete'],
    callback: ({ updated_entity_ids }) => {
      if (updated_entity_ids.includes(document.id)) {
        refetchEventStatuses()
        setIsLoading(false)
      }
    },
  })

  useHotkeys(
    ['alt+d', 'shift+alt+d'],
    (_e, handler) => {
      !isLoading &&
        !statusesWeCareAbout.length &&
        visibleWorkflowStateEvents[0] &&
        // hotkey triggers primary event
        handleTriggerEvent(visibleWorkflowStateEvents[0], {
          shiftKey: !!handler.shift,
        })
    },
    {
      preventDefault: true,
      enableOnFormTags: true,
    },
    [
      statusesWeCareAbout.length,
      hasProblemFlags,
      isLoading,
      goToNextAvailableDocumentOrClose,
      visibleWorkflowStateEvents[0],
    ],
  )

  return visibleWorkflowStateEvents.length > 0 ? (
    <>
      <EventButton
        documentsHaveErrors={hasProblemFlags}
        documentsIds={[document.id]}
        workflowStateEvents={visibleWorkflowStateEvents}
        isLoading={isLoading || statusesWeCareAbout.length > 0}
        onTriggerEvent={handleTriggerEvent}
      />
      <DocumentsWithErrorsDialog
        overlay={hasErrorsOverlay}
        documentFlags={[...documentErrorFlags, ...documentWarningFlags]}
        rowValueFlags={[...rowValueErrorFlags, ...rowValueWarningFlags]}
        onContinue={() => {
          triggeredEventDetails &&
            triggerEvent(
              triggeredEventDetails.event,
              triggeredEventDetails.stayOnCurrenDoc,
            )
          setTriggeredEventDetails(undefined)
          hasErrorsOverlay.close()
        }}
      />
    </>
  ) : null
}
