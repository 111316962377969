import { Add } from '@mui/icons-material'
import { Box, Button, Stack, Typography } from '@mui/material'
import useOverlay from '@/hooks/useOverlay'
import { useGetCustomVariableCategories } from '@/service-library/hooks/custom-variable-categories'
import { useGetCustomVariables } from '@/service-library/hooks/custom-variables'
import { useRootOrganization } from '@/components/organizations/RootOrganizationProvider'
import AddCustomVariableCategoryDialog from './AddCustomVariableCategoryDialog'
import CustomVariableManagementTable from './CustomVariableManagementTable'

export default function CustomVariables() {
  const { rootOrganization } = useRootOrganization()
  const addCustomVariableCategoryOverlay = useOverlay()

  const { customVariableCategories, queryKey: categoriesQueryKey } =
    useGetCustomVariableCategories({
      filters: {
        limit: '1000',
        org_id: rootOrganization.id,
      },
    })

  const { customVariables, queryKey: variablesQueryKey } =
    useGetCustomVariables({
      filters: {
        limit: '1000',
        org_id: rootOrganization.id,
      },
    })

  const categoriesWithVariables = customVariableCategories.map((category) => {
    const variables = customVariables
      .filter((variable) => variable.category_id === category.id)
      .sort((a, b) => a.name.localeCompare(b.name))
    return {
      ...category,
      variables,
    }
  })

  return (
    <Box
      p={2}
      pb={3}
      sx={{
        overflowY: 'auto',
        height: 'calc(100vh - 126px)', // Subtracting 126 to account for the app top bar and the tabs
      }}
    >
      <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
        Manage what variables are available for your organizations.
      </Typography>
      <Stack spacing={5}>
        {categoriesWithVariables.map((category) => {
          return (
            <CustomVariableManagementTable
              key={category.id}
              category={category}
              categories={customVariableCategories}
              categoriesQueryKey={categoriesQueryKey}
              variablesQueryKey={variablesQueryKey}
            />
          )
        })}
      </Stack>
      <Box sx={{ mt: 6 }}>
        <Button
          variant="text"
          onClick={addCustomVariableCategoryOverlay.open}
          startIcon={<Add />}
        >
          New Category
        </Button>
      </Box>
      <AddCustomVariableCategoryDialog
        overlay={addCustomVariableCategoryOverlay}
        listQueryKey={categoriesQueryKey}
        orgId={rootOrganization.id}
      />
    </Box>
  )
}
