import { DocProjectTag } from '@/types/project-tags'
import { Stack, Typography } from '@mui/material'
import ProjectTagChip from './ProjectTagChip'
import { useEffect, useRef, useState } from 'react'

type DocProjectTagsListProps = {
  docProjectTags: DocProjectTag[]
}

export default function DocProjectTagsList({
  docProjectTags,
}: DocProjectTagsListProps) {
  const tagListRef = useRef<HTMLDivElement>(null)
  const collapsedCountRef = useRef<HTMLDivElement>(null)
  const [visibleItems, setVisibleItems] = useState<DocProjectTag[]>([])
  const [countPosition, setCountPosition] = useState(0)

  const collapsedCount = docProjectTags.length - visibleItems.length

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (!tagListRef.current) return
      const listChildren = tagListRef.current?.children
      const listWidth = tagListRef.current?.clientWidth

      let currentWidth = 0
      let visibleCount = 0
      for (const item of listChildren) {
        const itemWidth = item.clientWidth
        const style = window.getComputedStyle(item)
        const marginLeft = style.getPropertyValue('margin-left')
        const marginLeftValue = parseFloat(marginLeft)

        if (currentWidth + itemWidth + marginLeftValue <= listWidth) {
          currentWidth += itemWidth + marginLeftValue
          visibleCount++
        } else {
          setCountPosition(currentWidth)
          break
        }
      }

      setVisibleItems(docProjectTags.slice(0, visibleCount))
    })
    if (tagListRef.current) observer.observe(tagListRef.current)
    return () => {
      observer.disconnect()
    }
  }, [docProjectTags])

  return (
    <Stack
      direction="row-reverse"
      alignItems="center"
      spacing={0.5}
      ref={tagListRef}
      sx={{ width: '100%', position: 'relative' }}
    >
      {docProjectTags.map((tag, index) => (
        <ProjectTagChip
          key={tag.id + index}
          projectTag={tag.project_tag}
          invisible={!visibleItems.find((i) => i.id === tag.id)}
        />
      ))}

      <Typography
        component="p"
        variant="caption"
        color="text.secondary"
        ref={collapsedCountRef}
        sx={{
          visibility: collapsedCount > 0 ? 'visible' : 'hidden',
          position: 'absolute',
          right: countPosition,
          mt: '3px',
        }}
      >
        +{collapsedCount}
      </Typography>
    </Stack>
  )
}
