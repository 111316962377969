import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import buildService from '../create-service'
import { CustomVariable } from '@/types/custom-variables'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'

const service = buildService<CustomVariable>({
  pathCategory: 'custom_variables',
})

export function useGetCustomVariable({
  id,
  ...options
}: UseGetDetailOptions<CustomVariable>) {
  const query = useGetDetail<CustomVariable>({
    id,
    queryKey: queryKeys.customVariables.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    customVariable: query.data,
    ...query,
  }
}

export function useCreateCustomVariable(
  options: UseCreateDetailOptions<CustomVariable> = {},
) {
  const mutation = useCreateDetail<CustomVariable>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createCustomVariable: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateCustomVariable(
  options: UseUpdateDetailOptions<CustomVariable> = {},
) {
  const mutation = useUpdateDetail<CustomVariable>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateCustomVariable: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteCustomVariable(options: UseDeleteDetailOptions = {}) {
  const mutation = useDeleteDetail<CustomVariable>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteCustomVariable: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetCustomVariables({
  filters,
  ...options
}: UseGetListOptions<CustomVariable> = {}) {
  const query = useGetList<CustomVariable>({
    filters,
    queryKey: queryKeys.customVariables.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    customVariables: query.allData,
    ...query,
  }
}

export function useUpdateCustomVariables(
  options: UseUpdateListOptions<CustomVariable> = {},
) {
  const mutation = useUpdateList<CustomVariable>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateCustomVariables: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteCustomVariables({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<CustomVariable>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteCustomVariables: mutation.mutateAsync,
    ...mutation,
  }
}
