import { useRef } from 'react'
import { ViewSidebar } from '@mui/icons-material'
import { Button, Menu, MenuItem } from '@mui/material'
import useOverlay from '@/hooks/useOverlay'
import { useDocumentCurrentWorkflowViewContext } from '@/components/image-zoom-pan/providers/DocumentCurrentWorkflowViewProvider'
import { documentViews } from '@/components/workflow-state-page/WorkflowStateDocumentViews'

export default function DocumentViewControl() {
  const overlay = useOverlay()
  const anchorRef = useRef<HTMLButtonElement>(null)

  const {
    allowedDocumentCurrentWorkflowViews,
    documentCurrentWorkflowView,
    setDocumentCurrentWorkflowView,
  } = useDocumentCurrentWorkflowViewContext()

  if (
    allowedDocumentCurrentWorkflowViews.length === 1 ||
    !documentCurrentWorkflowView
  )
    return null

  return (
    <>
      <Button
        startIcon={<ViewSidebar />}
        onClick={overlay.toggle}
        ref={anchorRef}
        variant="text"
      >
        {documentViews[documentCurrentWorkflowView]}
      </Button>
      <Menu
        open={overlay.isOpen}
        onClose={overlay.close}
        anchorEl={anchorRef.current}
        elevation={16}
      >
        {allowedDocumentCurrentWorkflowViews.map((view) => (
          <MenuItem
            key={view}
            value={view}
            selected={view === documentCurrentWorkflowView}
            onClick={() => {
              setDocumentCurrentWorkflowView(view)
              overlay.close()
            }}
          >
            {documentViews[view]}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
