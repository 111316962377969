import { FlagCount, FlagLevel } from '@/types/flags'
import useFlagColorMap from './useFlagColorMap'

export type FlagsCount = {
  documentFlags?: FlagCount
  rowsFlags: FlagCount
}

export function getPriorityLevel(flagsCount: FlagsCount) {
  const { documentFlags, rowsFlags } = flagsCount

  if (documentFlags?.failure || rowsFlags.failure) {
    return 'failure'
  }
  if (documentFlags?.error || rowsFlags.error) {
    return 'error'
  }
  if (documentFlags?.warning || rowsFlags.warning) {
    return 'warning'
  }
  if (documentFlags?.info || rowsFlags.info) {
    return 'info'
  }
}

export default function useFlagPriorityColor(flagsCount: FlagsCount) {
  const colorMap = useFlagColorMap()
  const priorityLevel: FlagLevel | undefined = getPriorityLevel(flagsCount)

  return {
    priorityLevel,
    color: priorityLevel ? colorMap[priorityLevel] : undefined,
  }
}
