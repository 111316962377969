import { ReactNode } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Stack, Typography, useTheme, Box } from '@mui/material'
import UploadIcon from '@mui/icons-material/Upload'
import DropZone from './DropZone'
import RecentOrgDropZone from './RecentOrgDropZone'
import { Organization } from '@/types/organizations'
import Grid2 from '@mui/material/Unstable_Grid2'

type DocumentDropZoneProps = {
  children: ReactNode
  isFullscreen: boolean
  onDrop: (files: FileList) => void
  onOrgDrop: (files: FileList, targetOrg: Organization) => void
  recentOrgs?: Organization[]
}

export default function DocumentDropZone({
  children,
  isFullscreen,
  onDrop,
  onOrgDrop,
  recentOrgs = [],
}: DocumentDropZoneProps) {
  const theme = useTheme()

  const [searchParams] = useSearchParams()
  const documentId = searchParams.get('document_id') || ''

  return (
    <DropZone
      disabled={!!documentId}
      isFullscreen={isFullscreen}
      onDrop={onDrop}
      overlayChildren={
        <Box
          sx={{
            p: 2,
            height: '100%',
            width: '100%',
            flexGrow: 1,
          }}
        >
          <Stack
            spacing={2}
            sx={{
              height: '100%',
              border: `solid 2px ${theme.palette.secondary.main}`,
              borderRadius: 4,
              p: 2,
              alignItems: 'center',
              justifyContent: 'center',
              background: '#0008',
            }}
          >
            <Stack
              spacing={3}
              alignItems="center"
              sx={{
                flexGrow: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <UploadIcon
                sx={{
                  fontSize: 92,
                  border: `dotted 4px ${theme.palette.secondary.main}`,
                  borderRadius: '50%',
                  p: 1.5,
                  boxSizing: 'content-box',
                }}
              />
              <div>
                <Typography variant="h4" textAlign="center">
                  Upload Documents
                </Typography>
                <Typography variant="body2" textAlign="center">
                  Drop documents here to begin processing them.
                </Typography>
              </div>
            </Stack>

            <Grid2
              container
              spacing={2}
              sx={{ flexShrink: 0, width: '100%', alignItems: 'stretch' }}
            >
              {recentOrgs.map((organization) => (
                <Grid2 md={12 / 5} sm={12 / 3} xs={12} key={organization.id}>
                  <RecentOrgDropZone
                    onDrop={(files) => onOrgDrop(files, organization)}
                    organization={organization}
                  />
                </Grid2>
              ))}
            </Grid2>
          </Stack>
        </Box>
      }
    >
      {children}
    </DropZone>
  )
}
