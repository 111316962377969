import { ProjectLinkedModel } from '@/types/project-linked-models'
import useGetDetail, { UseGetDetailOptions } from '../core-hooks/useGetDetail'
import queryKeys from '../query-keys'
import useCreateDetail, {
  UseCreateDetailOptions,
} from '../core-hooks/useCreateDetail'
import useDeleteDetail, {
  UseDeleteDetailOptions,
} from '../core-hooks/useDeleteDetail'
import useUpdateDetail, {
  UseUpdateDetailOptions,
} from '../core-hooks/useUpdateDetail'
import useGetList, { UseGetListOptions } from '../core-hooks/useGetList'
import useUpdateList, {
  UseUpdateListOptions,
} from '../core-hooks/useUpdateList'
import useDeleteList, {
  UseDeleteListOptions,
} from '../core-hooks/useDeleteList'
import buildService from '../create-service'

const service = buildService<ProjectLinkedModel>({
  pathCategory: 'project_linked_models',
})

export function useGetProjectLinkedModel({
  id,
  ...options
}: UseGetDetailOptions<ProjectLinkedModel>) {
  const query = useGetDetail<ProjectLinkedModel>({
    id,
    queryKey: queryKeys.projectLinkedModels.detail({
      id,
      filters: options.filters,
    }),
    serviceFn: service.getDetail,
    ...options,
  })

  return {
    projectLinkedModel: query.data,
    ...query,
  }
}

export function useCreateProjectLinkedModel(
  options: UseCreateDetailOptions<ProjectLinkedModel> = {},
) {
  const mutation = useCreateDetail<ProjectLinkedModel>({
    serviceFn: service.createDetail,
    ...options,
  })

  return {
    createProjectLinkedModel: mutation.mutateAsync,
    ...mutation,
  }
}

export function useUpdateProjectLinkedModel(
  options: UseUpdateDetailOptions<ProjectLinkedModel> = {},
) {
  const mutation = useUpdateDetail<ProjectLinkedModel>({
    serviceFn: service.updateDetail,
    ...options,
  })

  return {
    updateProjectLinkedModel: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteProjectLinkedModel(
  options: UseDeleteDetailOptions = {},
) {
  const mutation = useDeleteDetail<ProjectLinkedModel>({
    serviceFn: service.deleteDetail,
    ...options,
  })

  return {
    deleteProjectLinkedModel: mutation.mutateAsync,
    ...mutation,
  }
}

export function useGetProjectLinkedModels({
  filters,
  ...options
}: UseGetListOptions<ProjectLinkedModel> = {}) {
  const query = useGetList<ProjectLinkedModel>({
    filters,
    queryKey: queryKeys.projectLinkedModels.list({ filters }),
    serviceFn: service.getList,
    ...options,
  })

  return {
    projectLinkedModels: query.allData,
    ...query,
  }
}

export function useUpdateProjectLinkedModels(
  options: UseUpdateListOptions<ProjectLinkedModel> = {},
) {
  const mutation = useUpdateList<ProjectLinkedModel>({
    serviceFn: service.updateList,
    ...options,
  })

  return {
    updateProjectLinkedModels: mutation.mutateAsync,
    ...mutation,
  }
}

export function useDeleteProjectLinkedModels({
  sideEffectQueryKeys,
  listQueryKey,
  ...options
}: UseDeleteListOptions = {}) {
  const mutation = useDeleteList<ProjectLinkedModel>({
    serviceFn: service.deleteList,
    sideEffectQueryKeys,
    listQueryKey,
    ...options,
  })

  return {
    deleteProjectLinkedModels: mutation.mutateAsync,
    ...mutation,
  }
}
