import { Card, Typography } from '@mui/material'
import { Handle, Position } from '@xyflow/react'
import { memo } from 'react'

function PredictionNode({ data }: { data: { label: string } }) {
  return (
    <Card sx={{ p: 4, borderRadius: 2 }}>
      <Typography variant="body2" textAlign="left">
        {data.label}
      </Typography>
      <Handle type="target" position={Position.Left} />
      <Handle type="source" position={Position.Right} />
    </Card>
  )
}

export default memo(PredictionNode)
