import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '@mui/material'
import { QueryKey } from '@tanstack/react-query'
import { OverlayState } from '@/hooks/useOverlay'
import { useCreateCustomVariableCategory } from '@/service-library/hooks/custom-variable-categories'
import queryKeys from '@/service-library/query-keys'
import generateUuid from '@/utils/generate-uuid'
import { showErrorSnackbar } from '@/utils/snackbars'
import { Dialog, DialogContent, DialogFooter } from '@/components/dialog'
import { FormTextField, PixyDocsForm } from '@/components/forms'

type AddCustomVariableCategoryDialogProps = {
  overlay: OverlayState
  listQueryKey: QueryKey
  orgId: string
}

type FormValues = {
  name: string
}

export default function AddCustomVariableCategoryDialog({
  overlay,
  listQueryKey,
  orgId,
}: AddCustomVariableCategoryDialogProps) {
  const { createCustomVariableCategory } = useCreateCustomVariableCategory({
    listQueryKey,
    // needed since we use the root org to get the custom variable categories for the other tab
    sideEffectQueryKeys: [queryKeys.organizations.details()],
    onError: () => {
      showErrorSnackbar('Unable to create category. Please try again later.')
    },
  })

  const methods = useForm<FormValues>({
    defaultValues: { name: '' },
  })

  const {
    formState: { isDirty, isValid },
    register,
    reset,
  } = methods

  function handleCreateCustomVariableCategory(values: FormValues) {
    values.name = values.name.trim()
    createCustomVariableCategory({
      ...values,
      id: generateUuid(),
      org_id: orgId,
    })
    overlay.close()
  }

  useEffect(() => {
    if (!overlay.isOpen) {
      reset()
    }
  }, [overlay.isOpen, reset])

  return (
    <Dialog title="New Category" {...overlay} maxWidth="sm">
      <PixyDocsForm
        methods={methods}
        onSubmit={handleCreateCustomVariableCategory}
      >
        <DialogContent>
          <FormTextField
            autoFocus
            fullWidth
            label="Name"
            required
            {...register('name', {
              validate: (value) => value.trim().length > 0,
            })}
          />
        </DialogContent>
        <DialogFooter>
          <Button variant="text" onClick={overlay.close}>
            Cancel
          </Button>
          <Button type="submit" disabled={!isDirty || !isValid}>
            Save
          </Button>
        </DialogFooter>
      </PixyDocsForm>
    </Dialog>
  )
}
