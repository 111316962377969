import { Document } from '@/types/documents'
import { prettifyDate } from '@/utils/getFormattedDateTimeString'
import { Typography, Stack } from '@mui/material'

type StaticDocumentDetailsProps = {
  document: Document
}

function Detail({ label, value }: { label: string; value: string }) {
  return (
    <Stack direction="row" spacing={1}>
      <Typography sx={{ flexGrow: 1 }} color="text.secondary">
        {label}
      </Typography>
      <Typography sx={{ flexShrink: 0 }}>{value}</Typography>
    </Stack>
  )
}

export default function StaticDocumentDetails({
  document,
}: StaticDocumentDetailsProps) {
  const { created_by_user, uploaded_at } = document
  const prettyDate = prettifyDate(uploaded_at)

  return (
    <>
      <Detail label="Submitter:" value={created_by_user?.name || ''} />
      <Detail label="Upload Date:" value={prettyDate} />
    </>
  )
}
