import { ProjectModelVersion } from '@/types/project-models'

const trainingStatusColorMap: Record<
  ProjectModelVersion['training_status'],
  string
> = {
  training: 'blue.main',
  canceled: 'text.secondary',
  canceling: 'text.secondary',
  error: 'red.main',
  trained: 'green.main',
  starting: 'green.main',
  starting_gpu: 'green.main',
  evaluating: 'green.main',
  terminating_gpu: 'green.main',
  completed: 'green.main',
}

export default trainingStatusColorMap
