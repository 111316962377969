import { Link } from 'react-router-dom'
import { Add } from '@mui/icons-material'
import { Stack, Typography, IconButton, Tooltip } from '@mui/material'
import { ProjectCategory as ProjectCategoryT } from '@/types/projects'
import ProjectCard from '@/components/project-card/ProjectCard'
import ProjectCategoryNameWithActions from './ProjectCategoryNameWithActions'

export type ProjectCategoryProps = {
  canAddEdit: boolean
  category: ProjectCategoryT
}

export default function ProjectCategory({
  canAddEdit,
  category,
}: ProjectCategoryProps) {
  const { projects = [] } = category

  return (
    <Stack spacing={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <ProjectCategoryNameWithActions
          category={category}
          canEdit={canAddEdit}
        />
        {canAddEdit && (
          <Link to="/project-setup/details" state={{ category }}>
            <Tooltip title="New Project">
              <IconButton size="small">
                <Add fontSize="large" color="primary" />
              </IconButton>
            </Tooltip>
          </Link>
        )}
      </Stack>

      {/* Negative margin offsets Grid's surrounding margin */}
      {projects.map((project) => {
        if (!canAddEdit && project.setup_state !== 'complete') return
        return (
          <div key={project.id}>
            <ProjectCard project={project} />
          </div>
        )
      })}

      {projects.length === 0 && (
        <Typography variant="body1" color="text.secondary">
          {canAddEdit ? (
            <i>Create a project to begin processing documents.</i>
          ) : (
            <i>No projects.</i>
          )}
        </Typography>
      )}
    </Stack>
  )
}
