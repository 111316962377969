import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'
import useLocalStorage from '@/hooks/useLocalStorage'

const tabs = {
  dashboard: 'Dashboard',
  documents: 'Documents',
  data: 'Data',
  settings: 'Settings',
}

const sortColumns = {
  enteredAtColId: 'State Duration',
  uploadColId: 'Upload Date',
}

const sortOrder = {
  desc: 'Newest at Top',
  asc: 'Oldest at Top',
}

type UserPreferenceProps = {
  label: string
  description: string
  optionsMap: Record<string, string>
  value: string
  setValue: (value: string) => void
}

function UserPreference({
  label,
  description,
  value,
  optionsMap,
  setValue,
}: UserPreferenceProps) {
  return (
    <Stack spacing={2}>
      <div>
        <Typography variant="body1">{label}</Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </div>
      <RadioGroup
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
        }}
        sx={{ ml: '5px !important' }}
      >
        {Object.entries(optionsMap).map(([key, value]) => (
          <FormControlLabel
            key={key}
            value={key}
            control={
              <Radio color="neutral" sx={{ padding: 0.5 }} size="small" />
            }
            label={value}
            sx={{
              columnGap: 0.5,
              width: 'fit-content',
            }}
          />
        ))}
      </RadioGroup>
    </Stack>
  )
}

export default function UserPreferences() {
  const [projectPreferredTab, setProjectPreferredTab] = useLocalStorage(
    'project-preferred-tab',
    'documents',
  )

  const [projDefaultSortColumn, setProjDefaultSortColumn] = useLocalStorage(
    'proj-default-sort-columns',
    'enteredAtColId',
  )

  const [projDefaultSortOrder, setProjDefaultSortOrder] = useLocalStorage(
    'proj-default-sort-order',
    'desc',
  )

  return (
    <Stack spacing={4} sx={{ height: '100%' }}>
      <Typography variant="h4">My Preferences</Typography>
      <UserPreference
        label="Default Tab"
        description="Which tab is selected by default when entering a project."
        value={projectPreferredTab}
        optionsMap={tabs}
        setValue={setProjectPreferredTab}
      />
      <UserPreference
        label="Default Sort Column"
        description="On the Documents and Data tabs in a project, rows will be sorted by this column when the page first loads."
        value={projDefaultSortColumn}
        optionsMap={sortColumns}
        setValue={setProjDefaultSortColumn}
      />
      <UserPreference
        label="Default Sort Order"
        description="On the Documents and Data tabs in a project, rows will be sorted in this order when the page first loads."
        value={projDefaultSortOrder}
        optionsMap={sortOrder}
        setValue={setProjDefaultSortOrder}
      />
    </Stack>
  )
}
