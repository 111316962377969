import { Box, Stack, Typography } from '@mui/material'
import { useGetProjectTag } from '@/service-library/hooks/project-tags'
import { WorkflowStateAction } from '@/types/workflow-state-actions'
import ProjectTagChip from '@/components/project-tags/ProjectTagChip'

type AddProjectTagDisplayProps = {
  action: WorkflowStateAction
}

export default function AddProjectTagDisplay({
  action,
}: AddProjectTagDisplayProps) {
  const { projectTag } = useGetProjectTag({
    id: action.params.project_tag_id,
  })

  return (
    <Stack>
      <Typography variant="body2" color="text.secondary">
        Add this tag to the document:
      </Typography>
      <Box sx={{ display: 'flex' }}>
        <ProjectTagChip projectTag={projectTag} />
      </Box>
    </Stack>
  )
}
