import { useState } from 'react'
import { Document } from '@/types/documents'
import { useBulkSplitCreateDocuments } from '@/service-library/hooks/break-up-multiple-page-document'
import generateUuid from '@/utils/generate-uuid'
import { showErrorSnackbar, showInfoSnackbar } from '@/utils/snackbars'
import { useAuthentication } from '@/components/auth/AuthProvider'

type SplitDocumentViewProps = {
  document: Document
  onDelete: () => void
}

function getPagesFromSplitPoints(
  splitPoints: number[],
  totalPoints: number,
  deletedPages: number[],
) {
  const newDocumentsPages = []
  let startPage = 1
  splitPoints.forEach((point) => {
    const pages = Array.from(
      { length: point - startPage + 1 },
      (_, index) => startPage + index,
    )

    const filteredPages = pages.filter((page) => !deletedPages.includes(page))
    if (filteredPages.length) newDocumentsPages.push(filteredPages)
    startPage = point + 1
  })

  if (startPage <= totalPoints) {
    const lastDocumentPages = Array.from(
      { length: totalPoints - startPage + 1 },
      (_, index) => startPage + index,
    )

    const filteredPages = lastDocumentPages.filter(
      (page) => !deletedPages.includes(page),
    )
    if (filteredPages.length) newDocumentsPages.push(filteredPages)
  }

  return newDocumentsPages
}

export default function useSplitDocuments({
  document,
  onDelete,
}: SplitDocumentViewProps) {
  const { user } = useAuthentication()

  const [splitPoints, setSplitPoints] = useState<number[]>([])
  const [pagesPerSplit, setPagesPerSplit] = useState<number>(1)
  const [pagesToDelete, setPagesToDelete] = useState<number[]>([])
  const [deleteOriginal, setDeleteOriginal] = useState(true)

  const pages = document.document_pages

  const resetStates = () => {
    setSplitPoints([])
    setDeleteOriginal(true)
    setPagesToDelete([])
    setPagesPerSplit(1)
  }

  const { bulkSplitCreateDocuments, isLoading } = useBulkSplitCreateDocuments({
    id: document.id,
    filters: {
      keep_original_doc: deleteOriginal ? 'false' : 'true',
    },
    onError: () => {
      showErrorSnackbar(
        'Some documents may have failed to create. Please try again later.',
        { autoHideDuration: 3000 },
      )
    },
    onSuccess: () => {
      if (deleteOriginal) {
        onDelete()
      }
    },
    onIdle: () => {
      resetStates()
    },
  })

  const createDocuments = () => {
    showInfoSnackbar('Splitting Document')

    const documentNameWithoutExtension =
      document.name.slice(-4).toLowerCase() === '.pdf'
        ? document.name.slice(0, -4)
        : document.name
    const newDocumentsPages = getPagesFromSplitPoints(
      splitPoints,
      pages.length,
      pagesToDelete,
    )

    const newDocuments = newDocumentsPages.map((pages, index) => ({
      id: generateUuid(),
      name: `${documentNameWithoutExtension} (split-${index + 1}).pdf`,
      owner_org_id: document.owner_org_id,
      project_id: document.project_id,
      submitter: user?.name,
      submitter_email: user?.email,
      pages,
    }))

    bulkSplitCreateDocuments(newDocuments)
  }

  return {
    deleteOriginal,
    isLoading,
    pagesPerSplit,
    pagesToDelete,
    splitPoints,
    createDocuments,
    resetStates,
    setDeleteOriginal,
    setPagesPerSplit,
    setPagesToDelete,
    setSplitPoints,
  }
}
