import { useEffect, useRef } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Add, ArrowBack } from '@mui/icons-material'
import { Box, Button, Stack } from '@mui/material'
import useGetThemeColor from '@/hooks/useGetThemeColor'
import useLocationHelpers from '@/hooks/useLocationHelpers'
import useOverlay from '@/hooks/useOverlay'
import { useGetWorkflowState } from '@/service-library/hooks/workflow-states'
import { useGetWorkflowStateEvents } from '@/service-library/hooks/workflow-state-events'
import LargeHeading from '@/components/large-heading/LargeHeading'
import ProjectBreadcrumb from '@/components/project-dashboard/ProjectBreadcrumb'
import AddEditWorkflowStateEventDialog from '@/components/workflow-state-page/AddEditWorkflowStateEventDialog'
import { WorkflowStateContext } from '@/components/workflow-state-page/WorkflowStatePage'
import { useSelectedWorkflowContext } from '@/components/workflows/SelectedWorkflowProvider'
import EventLanes from './EventLanes'
import { getWorkflowStateEventGroups } from './helpers'

export default function WorkflowStateEventsPage() {
  const getThemeColor = useGetThemeColor()
  const navigate = useNavigate()
  const { appendCurrentParamsToUrl } = useLocationHelpers()
  const { id } = useParams<{ id: string }>()
  const { workflowState } = useGetWorkflowState({ id: id as string })
  const { selectedWorkflow } = useSelectedWorkflowContext()

  const containerRef = useRef<HTMLDivElement>(null)

  const addEditOverlay = useOverlay()

  const {
    workflowStateEvents = [],
    isLoading,
    queryKey,
  } = useGetWorkflowStateEvents({
    filters: {
      limit: '1000',
      workflow_state_id: id,
      fields__include: 'actions',
      actions__fields__include: 'action_type',
    },
  })

  const { systemEvents, customEvents } =
    getWorkflowStateEventGroups(workflowStateEvents)

  useEffect(() => {
    if (workflowState && selectedWorkflow?.id !== workflowState.workflow_id) {
      navigate(appendCurrentParamsToUrl('../workflow-states'))
    }
  })

  if (isLoading) return null

  return (
    <WorkflowStateContext.Provider value={workflowState}>
      <Stack spacing={2} sx={{ px: 1 }}>
        <ProjectBreadcrumb label="Workflow States" url="../workflow-states" />
        {workflowState && (
          <ProjectBreadcrumb
            label={workflowState?.name || ''}
            url={`../workflow-states/${workflowState?.id}`}
          />
        )}
        <ProjectBreadcrumb label="Events" />

        <Box>
          <Button
            startIcon={<ArrowBack />}
            component={Link}
            to={appendCurrentParamsToUrl('./..')}
            variant="text"
            sx={{ ml: '-5px' }}
          >
            Back to Workflow State Settings
          </Button>
        </Box>

        <LargeHeading
          heading={
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              flexShrink={0}
            >
              <Box
                sx={{
                  background: getThemeColor(workflowState?.color),
                  width: 4,
                  height: 24,
                  borderRadius: 4,
                }}
              />
              <Box>{workflowState?.name}</Box>
            </Stack>
          }
          subHeading="Events"
        />

        <Box ref={containerRef} sx={{ pb: 2 }}>
          <Stack
            direction="column"
            spacing={4}
            sx={{
              minWidth: '100%',
            }}
          >
            <EventLanes
              title="System Events"
              workflowStateEvents={systemEvents}
              listQueryKey={queryKey}
            />
            <EventLanes
              isReorderable
              showPrimaryEvent
              title={
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Box>Custom Events</Box>
                  <Button
                    variant="text"
                    startIcon={<Add />}
                    onClick={addEditOverlay.open}
                  >
                    Add Event
                  </Button>
                </Stack>
              }
              workflowStateEvents={customEvents}
              listQueryKey={queryKey}
            />
          </Stack>
        </Box>
      </Stack>

      {workflowState && (
        <AddEditWorkflowStateEventDialog
          overlay={addEditOverlay}
          workflowState={workflowState}
          listQueryKey={queryKey}
          onSave={() => {
            setTimeout(() => {
              const scrollContainer = containerRef.current?.closest(
                '[data-scroll-container]',
              )
              scrollContainer?.scrollTo({
                top: scrollContainer.scrollHeight,
                behavior: 'smooth',
              })
            }, 100)
          }}
        />
      )}
    </WorkflowStateContext.Provider>
  )
}
