import { ProjectModelVersion } from '@/types/project-models'
import { Select, MenuItem, Stack, Box, Typography } from '@mui/material'
import { validTrainingStatuses } from '../model-page/helpers'
import trainingStatusColorMap from '../model-page/training-status-colors'

type ModelVersionSelectProps = {
  selectedVersionId: string
  setSelectedVersionId: (id: string) => void
  modelVersions: ProjectModelVersion[]
}

export default function ModelVersionSelect({
  selectedVersionId,
  setSelectedVersionId,
  modelVersions,
}: ModelVersionSelectProps) {
  const sortedVersions = modelVersions.sort((a, b) => {
    return a.version - b.version
  })

  return (
    <Select
      value={
        selectedVersionId ||
        (modelVersions.length > 0 ? 'select-a-version' : 'no-versions')
      }
      size="small"
      sx={{ px: 1 }}
      onChange={(event) => {
        setSelectedVersionId(event.target.value)
      }}
      disabled={modelVersions.length === 0}
    >
      {sortedVersions.map((version) => (
        <MenuItem key={version.id} value={version.id}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Box>v{version.version}.0</Box>
            <Typography
              variant="caption"
              sx={{
                color: trainingStatusColorMap[version.training_status],
              }}
            >
              {version.is_current && '(active)'}
              {validTrainingStatuses.includes(version.training_status) &&
                '(training)'}
              {version.training_status === 'error' && '(failed)'}
              {version.training_status === 'canceling' && '(canceling)'}
              {version.training_status === 'canceled' && '(canceled)'}
            </Typography>
          </Stack>
        </MenuItem>
      ))}

      {modelVersions.length === 0 && (
        <MenuItem disabled value="no-versions">
          <Typography variant="caption">No versions available</Typography>
        </MenuItem>
      )}

      {!selectedVersionId && modelVersions.length > 0 && (
        <MenuItem disabled value="select-a-version">
          <Typography variant="caption" color="text.secondary">
            Select a version
          </Typography>
        </MenuItem>
      )}
    </Select>
  )
}
