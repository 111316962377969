import { Box, Typography } from '@mui/material'
import {
  CustomVariable,
  CustomVariableValue as TSCustomVariableValue,
} from '@/types/custom-variables'
import { QueryKey } from '@tanstack/react-query'
import {
  useCreateCustomVariableValue,
  useDeleteCustomVariableValue,
  useUpdateCustomVariableValue,
} from '@/service-library/hooks/custom-variable-values'
import { showErrorSnackbar } from '@/utils/snackbars'
import generateUuid from '@/utils/generate-uuid'
import { Organization } from '@/types/organizations'
import CustomVariableValue from './CustomVariableValue'
import usePermission from '@/hooks/usePermission'

type CustomVariableValueRowProps = {
  variable: CustomVariable
  customVariableValuesQueryKey: QueryKey
  resolvedCustomVariableValuesQueryKey: QueryKey
  value?: TSCustomVariableValue
  resolvedValue?: string | null
  organization: Organization
}

export default function CustomVariableValueRow({
  variable,
  customVariableValuesQueryKey,
  resolvedCustomVariableValuesQueryKey,
  value,
  resolvedValue,
  organization,
}: CustomVariableValueRowProps) {
  const { hasEditingPermission } = usePermission()

  const { createCustomVariableValue } = useCreateCustomVariableValue({
    sideEffectQueryKeys: [resolvedCustomVariableValuesQueryKey],
    listQueryKey: customVariableValuesQueryKey,
    onError: () => {
      showErrorSnackbar('Failed to create value. Please try again later.')
    },
  })
  const { deleteCustomVariableValue } = useDeleteCustomVariableValue({
    sideEffectQueryKeys: [resolvedCustomVariableValuesQueryKey],
    listQueryKey: customVariableValuesQueryKey,
    onError: () => {
      showErrorSnackbar('Failed to delete value. Please try again later.')
    },
  })
  const { updateCustomVariableValue } = useUpdateCustomVariableValue({
    sideEffectQueryKeys: [resolvedCustomVariableValuesQueryKey],
    listQueryKey: customVariableValuesQueryKey,
    onError: () => {
      showErrorSnackbar('Failed to update value. Please try again later.')
    },
  })

  function handleCreateUpdateValue({
    value,
    customVariableId,
    customVariableValue,
  }: {
    value: string
    customVariableId: string
    customVariableValue?: TSCustomVariableValue
  }) {
    if (!organization) return
    if (!customVariableValue) {
      createCustomVariableValue({
        id: generateUuid(),
        value,
        custom_variable_id: customVariableId,
        owner_org_id: organization.id,
      })
    } else {
      updateCustomVariableValue({ ...customVariableValue, value })
    }
  }

  return (
    <>
      <Box>
        <Typography
          sx={{ overflowWrap: 'break-word', py: '2px' }}
          variant="body2"
        >
          {variable.name}
        </Typography>
      </Box>

      <CustomVariableValue
        canEdit={hasEditingPermission('edit_orgs', organization.id)}
        isRootOrg={!organization.parent_org_id}
        variableValue={value}
        resolvedValue={resolvedValue || null}
        handleOnBlur={(newValue) => {
          handleCreateUpdateValue({
            value: newValue,
            customVariableId: variable.id,
            customVariableValue: value,
          })
        }}
        onDelete={deleteCustomVariableValue}
        organization={organization}
      />

      <Box>
        <Typography variant="body2" color="text.secondary" sx={{ py: '2px' }}>
          {variable.description}
        </Typography>
      </Box>
    </>
  )
}
