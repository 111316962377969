import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { MRT_SortingState } from 'material-react-table'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DataList, DataListColumn } from '@/types/data-lists'
import useLocalStorage from '@/hooks/useLocalStorage'
import useOverlay from '@/hooks/useOverlay'
import ZerapixTable from '@/components/zerapix-table/ZerapixTable'
import { RowData } from './constructTableRows'
import AddEditDataListColumnDialog from './AddEditDataListColumnDialog'
import useDataListTable from './useDataListTable'
import ValueMappingsDialog from './ValueMappingsDialog'
import { useGetDataListColumns } from '@/service-library/hooks/data-list-columns'
import { useGetDataListEntries } from '@/service-library/hooks/data-list-entries'

type DataListDataProps = {
  parentDataList: DataList
  dataList: DataList
  showOrgTree: boolean
  setShowOrgTree: Dispatch<SetStateAction<boolean>>
}

export default function DataListData({
  parentDataList,
  dataList,
  showOrgTree,
  setShowOrgTree,
}: DataListDataProps) {
  const valueMappingsOverlay = useOverlay()
  const addEditColumnOverlay = useOverlay()

  const [selectedColumnId, setSelectedColumnId] = useState<string>()
  const [mappingColumnsData, setMappingColumnsData] = useState<{
    mainColumn: DataListColumn
    mappingColumns: DataListColumn[]
  }>({
    mainColumn: {} as DataListColumn,
    mappingColumns: [],
  })

  const [sorting, setSorting] = useLocalStorage<MRT_SortingState>(
    `data-list-sorting-${parentDataList.id}`,
    [],
  )
  const ordering = useMemo(() => {
    if (sorting.length === 0) return 'sort_order'

    return sorting
      .map(({ id, desc }) => `${desc ? '-' : ''}column_id-${id}`)
      .join(',')
  }, [sorting])

  const dataListColumnsQuery = useGetDataListColumns({
    filters: {
      limit: '1000',
      data_list_id: parentDataList.id,
      fields__include: 'mapping_columns_ids,has_values,data_list_column_type',
    },
    refetchOnWindowFocus: false,
  })

  const { dataListColumns = [], queryKey } = dataListColumnsQuery

  const dataListEntriesQuery = useGetDataListEntries({
    filters: {
      data_list_id: dataList.id,
      fields__include: 'data_list_entry_cells',
      data_list_entry_cells__fields__include: 'data_list_entry_cell_values',
      limit: '200',
      ordering,
    },
    refetchOnWindowFocus: false,
  })

  const { dataListEntries = [], queryKey: entriesQueryKey } =
    dataListEntriesQuery

  const table = useDataListTable({
    dataList,
    parentDataList,
    dataListColumnsQuery,
    dataListEntriesQuery,
    addEditColumnOverlay,
    valueMappingsOverlay,
    setMappingColumnsData,
    setSelectedColumnId,
    showOrgTree,
    sorting,
    setShowOrgTree,
    setSorting,
  })

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ZerapixTable<RowData> table={table} />

      {mappingColumnsData.mainColumn.id && (
        <ValueMappingsDialog
          overlay={valueMappingsOverlay}
          mappingColumnsData={mappingColumnsData}
          dataListColumns={dataListColumns}
          dataListEntries={dataListEntries}
          entriesQueryKey={entriesQueryKey}
        />
      )}

      <AddEditDataListColumnDialog
        listQueryKey={queryKey}
        dataList={dataList}
        dataListColumn={
          selectedColumnId
            ? dataListColumns.find(({ id }) => selectedColumnId === id)
            : undefined
        }
        overlay={addEditColumnOverlay}
        onClose={() => {
          setSelectedColumnId(undefined)
        }}
      />
    </LocalizationProvider>
  )
}
