import { useState, useRef, useEffect } from 'react'
import { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch'
import { OpenInNew, SwapHoriz } from '@mui/icons-material'
import { IconButton, Link, Popover, Stack, Typography } from '@mui/material'
import { Document, DocumentRow } from '@/types/documents'
import { ProjectGridField as TSField } from '@/types/fields'
import useOverlay from '@/hooks/useOverlay'
import { useGetDocument } from '@/service-library/hooks/documents'
import DocumentImagePageProvider from '@/components/image-zoom-pan/providers/DocumentImagePageProvider'
import ImageZoomPan from '@/components/image-zoom-pan/ImageZoomPan'
import ImageZoomPanControls from '@/components/image-zoom-pan/ImageZoomPanControls'
import ImageZoomPanCenterProvider from '@/components/image-zoom-pan/providers/ImageZoomPanCenterProvider'
import NavigationPanel from '@/components/image-zoom-pan/NavigationPanel'
import ValidationOptionsProvider from '@/components/image-zoom-pan/providers/ValidationOptionsProvider'
import SizeProvider from '@/components/size-provider/SizeProvider'
import { useSelectedWorkflowContext } from '@/components/workflows/SelectedWorkflowProvider'
import { useWorkflowsContext } from '@/components/workflows-provider/WorkflowsProvider'
import DocumentRowProvider from './providers/DocumentRowProvider'
import FieldsComparison from './FieldsComparison'
import DocumentComparisonSelection from './DocumentComparisonSelection'
import useGetThemeColor from '@/hooks/useGetThemeColor'
import useLocationHelpers from '@/hooks/useLocationHelpers'

type ComparisonViewDocumentProps = {
  documentQuery: ReturnType<typeof useGetDocument>
  flatFields?: TSField[]
  groupedFields?: TSField[][]
  side: 'left' | 'right'
  mainProjectGridId?: string
  mode?: 'image' | 'all_fields' | 'relevant_fields'
  mainDocument: Document
}

export default function ComparisonViewDocument({
  documentQuery,
  groupedFields = [],
  side,
  mainProjectGridId,
  mode = 'image',
  mainDocument,
}: ComparisonViewDocumentProps) {
  const { document } = documentQuery
  const { selectedWorkflow } = useSelectedWorkflowContext()
  const { getAllWorkflowStates } = useWorkflowsContext()
  const swapDocumentOverlay = useOverlay()

  const transformerRef = useRef<ReactZoomPanPinchRef>(null)

  const [drawBoxes, setDrawBoxes] = useState(true)

  const [rotationDegree, setRotationDegree] = useState(0)

  const workflowStates = selectedWorkflow?.workflow_states || []

  const stateInCurrentWorkflow = workflowStates.find((state) =>
    document?.workflow_states_ids?.some((id) => id === state.id),
  )

  // For the secondary document, we first check for the document in the current workflow, then in all workflows
  const workflowState =
    side === 'left'
      ? stateInCurrentWorkflow
      : stateInCurrentWorkflow ||
        getAllWorkflowStates().find((state) =>
          document?.workflow_states_ids?.some((id) => id === state.id),
        )

  const mainDocumentRow = document?.document_rows?.find(
    ({ project_grid_id }) => project_grid_id === mainProjectGridId,
  ) as DocumentRow

  const rotateImage = () => {
    setRotationDegree((prev) => (prev + 90 === 360 ? 0 : prev + 90))
  }

  useEffect(() => {
    setRotationDegree(0)
    // document.id isn't used in this useEffect, but we want it to fire whenever document.id changes
  }, [document?.id])

  const getThemeColor = useGetThemeColor()

  const { getCurrentPathWithParams } = useLocationHelpers()

  const pathWithoutDocument = getCurrentPathWithParams().includes('document_id')
    ? getCurrentPathWithParams().split('&document_id')[0]
    : getCurrentPathWithParams()

  if (!document) return null

  return (
    <ValidationOptionsProvider
      document={documentQuery.document}
      localStorageKeyPrefix={
        side === 'left' ? 'comparison-left' : 'comparison-right'
      }
    >
      <DocumentImagePageProvider document={documentQuery.document}>
        <DocumentRowProvider documentRow={mainDocumentRow}>
          <ImageZoomPanCenterProvider>
            <Stack sx={{ flexGrow: 1, minWidth: 0 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  flexShrink: 0,
                  minHeight: 0,
                  height: '68px',
                  p: 1,
                  borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                  position: 'sticky',
                  top: 0,
                  backgroundColor: (theme) => theme.palette.background.paper,
                  zIndex: 10000,
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  sx={{
                    minWidth: 0,
                    width: '100%',
                  }}
                >
                  {side === 'left' && (
                    <Stack direction="row" spacing={1} alignItems="center">
                      <IconButton
                        component={Link}
                        target="_blank"
                        href={`${pathWithoutDocument}&document_id=${document.id}`}
                      >
                        <OpenInNew fontSize="inherit" />
                      </IconButton>

                      <IconButton onClick={swapDocumentOverlay.open}>
                        <SwapHoriz fontSize="inherit" />
                      </IconButton>

                      <Popover
                        anchorEl={swapDocumentOverlay.anchorEl as Element}
                        open={swapDocumentOverlay.isOpen}
                        onClose={swapDocumentOverlay.close}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        slotProps={{
                          paper: {
                            sx: {
                              width: '350px',
                              p: 2,
                            },
                          },
                        }}
                      >
                        <DocumentComparisonSelection
                          onSuccess={swapDocumentOverlay.close}
                          document={mainDocument}
                          comparisonDocument={document}
                        />
                      </Popover>
                    </Stack>
                  )}

                  <Stack
                    direction="column"
                    alignItems={side === 'left' ? 'end' : undefined}
                    sx={{
                      width: '100%',
                    }}
                  >
                    {side === 'right' ? (
                      <Typography color="secondary">
                        <b>Possible Duplicate</b>
                      </Typography>
                    ) : (
                      <Typography
                        noWrap
                        sx={{ color: (theme) => theme.palette.green.main }}
                      >
                        <b>Original Document</b>
                      </Typography>
                    )}
                    <Typography variant="caption" color="text.secondary" noWrap>
                      {document.name}{' '}
                      {side === 'right' && <i>(Current Document)</i>}
                    </Typography>
                    <Typography
                      variant="caption"
                      color={getThemeColor(stateInCurrentWorkflow?.color)}
                      noWrap
                    >
                      {stateInCurrentWorkflow?.name ||
                        '(Not in current workflow)'}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>

              <Stack
                sx={{
                  position: 'relative',
                  minHeight: 0,
                  minWidth: 0,
                  flexGrow: 1,
                }}
              >
                {!workflowState ||
                workflowState?.code === 'processing' ? null : mode ===
                  'image' ? (
                  <>
                    <SizeProvider>
                      <ImageZoomPan
                        key={document.id} // Force remount when document changes so the image will properly update and state will reset
                        drawBoxes={drawBoxes}
                        rotationDegree={rotationDegree}
                        transformerRef={transformerRef}
                        layout="top"
                      />
                    </SizeProvider>
                    <NavigationPanel
                      controls={[
                        <ImageZoomPanControls
                          documentView="comparison"
                          key="zoom-pan-controls"
                          hidePaginationControls={
                            !document?.document_pages.length ||
                            document?.document_pages.length <= 1
                          }
                          layout="top"
                          transformerRef={transformerRef}
                          rotateImage={rotateImage}
                          setDrawBoxes={setDrawBoxes}
                        />,
                      ]}
                      layout="top"
                    />
                  </>
                ) : (
                  <FieldsComparison groupedFields={groupedFields} side={side} />
                )}
              </Stack>
            </Stack>
          </ImageZoomPanCenterProvider>
        </DocumentRowProvider>
      </DocumentImagePageProvider>
    </ValidationOptionsProvider>
  )
}
