import { useGetDocument } from '@/service-library/hooks/documents'
import { ListItemButton, Skeleton, Stack, Typography } from '@mui/material'
import { useSelectedWorkflowContext } from '../workflows/SelectedWorkflowProvider'
import useGetThemeColor from '@/hooks/useGetThemeColor'

type ComparisonDocSuggestionProps = {
  documentId: string
  onClick: () => void
  subText?: string
  isSelected?: boolean
}

export default function ComparisonDocSuggestion({
  documentId,
  subText,
  onClick,
  isSelected,
}: ComparisonDocSuggestionProps) {
  const { document, isLoading, isError } = useGetDocument({
    id: documentId,
    filters: {
      fields__only: 'id,name,workflow_states_ids,document_pages',
      document_pages__fields__only: 'image_urls,page_number',
    },
    retry: () => false,
  })

  const { selectedWorkflow } = useSelectedWorkflowContext()
  const workflowStates = selectedWorkflow?.workflow_states || []

  const stateInCurrentWorkflow = workflowStates.find((state) =>
    document?.workflow_states_ids?.some((id) => id === state.id),
  )

  const getThemeColor = useGetThemeColor()

  if (isError) return null

  const pageOne = document?.document_pages?.find(
    (page) => page.page_number === 1,
  )

  return (
    <ListItemButton
      onClick={onClick}
      selected={isSelected}
      dense
      sx={{ borderRadius: 2 }}
    >
      {document && (
        <img
          src={pageOne?.image_urls.thumbnail}
          style={{
            height: 120,
            width: 100,
            marginRight: 20,
            marginLeft: -20,
            borderRadius: 8,
          }}
        />
      )}

      {!isLoading ? (
        <Stack sx={{ minWidth: 0 }}>
          <Typography>{subText}</Typography>
          <Typography
            noWrap
            variant="caption"
            color="text.secondary"
            sx={{ width: '100%' }}
          >
            {document?.name}
          </Typography>
          <Typography
            variant="caption"
            color={getThemeColor(stateInCurrentWorkflow?.color)}
          >
            {stateInCurrentWorkflow?.name || '(Not in current workflow)'}
          </Typography>
        </Stack>
      ) : (
        <Stack>
          <Skeleton width={150} />
          <Skeleton width={100} />
          <Skeleton width={75} />
        </Stack>
      )}
    </ListItemButton>
  )
}
