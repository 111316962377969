import {
  CustomVariable,
  CustomVariableCategory,
} from '@/types/custom-variables'
import { Box, Button, Stack, Typography } from '@mui/material'
import CategoryNameWithActions from './CategoryNameWithActions'
import { QueryKey } from '@tanstack/react-query'
import { Add } from '@mui/icons-material'
import AddEditCustomVariableDialog from './AddEditCustomVariableDialog'
import useOverlay from '@/hooks/useOverlay'
import { useRootOrganization } from '../organizations/RootOrganizationProvider'
import CustomVariableManagementRow from './CustomVariableManagementRow'

type CustomVariableManagementTableProps = {
  category: CustomVariableCategory & { variables: CustomVariable[] }
  categories: CustomVariableCategory[]
  categoriesQueryKey: QueryKey
  variablesQueryKey: QueryKey
}

export default function CustomVariableManagementTable({
  category,
  categories,
  categoriesQueryKey,
  variablesQueryKey,
}: CustomVariableManagementTableProps) {
  const { rootOrganization } = useRootOrganization()
  const addCustomVariableOverlay = useOverlay()

  const canBeDeleted = categories.length > 1 && category.variables.length === 0
  let deleteMessage =
    categories.length > 1 ? '' : 'There must be at least one category.'
  if (category.variables.length > 0) {
    deleteMessage =
      'You must remove all variables in this category before it can be deleted.'
  }

  return (
    <Stack spacing={1}>
      <CategoryNameWithActions
        category={category}
        listQueryKey={categoriesQueryKey}
        deleteDisabledMessage={!canBeDeleted ? deleteMessage : undefined}
      />

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'max-content 25% 2fr',
          width: '100%',
          '--Grid-borderWidth': '1px',
          borderTop: 'var(--Grid-borderWidth) solid',
          borderLeft: 'var(--Grid-borderWidth) solid',
          borderColor: 'divider',
          '& > div': {
            borderRight: 'var(--Grid-borderWidth) solid',
            borderBottom: 'var(--Grid-borderWidth) solid',
            borderColor: 'divider',
            p: 1,
          },
        }}
      >
        <Box sx={{ gridColumn: 'span 2', borderRight: 'none !important' }}>
          <Typography variant="body1">Variable</Typography>
        </Box>
        <Box>
          <Typography variant="body1">Description</Typography>
        </Box>

        {category.variables.map((variable) => {
          return (
            <CustomVariableManagementRow
              key={variable.id}
              variable={variable}
              variablesQueryKey={variablesQueryKey}
              category={category}
              categories={categories}
            />
          )
        })}

        {category.variables.length === 0 && (
          <Box sx={{ gridColumn: 'span 3' }}>
            <Typography variant="body2" color="text.secondary">
              <i>None</i>
            </Typography>
          </Box>
        )}
      </Box>

      <Box>
        <Button
          startIcon={<Add />}
          variant="text"
          onClick={addCustomVariableOverlay.open}
        >
          New Variable
        </Button>
      </Box>

      <AddEditCustomVariableDialog
        overlay={addCustomVariableOverlay}
        categories={categories}
        listQueryKey={variablesQueryKey}
        selectedCategory={category}
        orgId={rootOrganization.id}
      />
    </Stack>
  )
}
