import { Dispatch, SetStateAction, useState } from 'react'
import {
  CheckCircleOutline,
  DeleteOutline,
  OpenInFull,
} from '@mui/icons-material'
import { Box, Button, Card, IconButton, Stack } from '@mui/material'
import { DocumentPage } from '@/types/documents'
import useOverlay from '@/hooks/useOverlay'
import { Dialog, DialogFooter } from '@/components/dialog'
import { FULL_TILE_WIDTH, THUMBNAIL_TILE_WIDTH } from './SplitDocumentPages'

export default function ImageCard({
  page,
  pagesToDelete,
  setPagesToDelete,
  useFullImage,
}: {
  page: DocumentPage
  pagesToDelete: number[]
  setPagesToDelete: Dispatch<SetStateAction<number[]>>
  useFullImage?: boolean
}) {
  const fullImageOverlay = useOverlay()
  const [showIcons, setShowIcons] = useState(false)
  const pageIsToBeDeleted = pagesToDelete.includes(page.page_number)
  const tileWidth = useFullImage ? FULL_TILE_WIDTH : THUMBNAIL_TILE_WIDTH
  return (
    /* Display flex so it will get rid of default spacing pixels underneath the image */
    <Card
      sx={{ display: 'flex', position: 'relative' }}
      onMouseEnter={() => {
        !showIcons && setShowIcons(true)
      }}
      onMouseLeave={() => {
        !pageIsToBeDeleted && setShowIcons(false)
      }}
    >
      <Box
        sx={{
          background: 'black',
          width: tileWidth,
          height: useFullImage ? '50vh' : undefined,
          textAlign: 'center',
        }}
      >
        <img
          loading="lazy"
          style={{
            objectFit: 'contain',
            height: '100%',
            width: '100%',
            filter: pageIsToBeDeleted ? 'brightness(60%)' : undefined,
          }}
          src={useFullImage ? page.image_urls.full : page.image_urls.thumbnail}
          onError={(event) => {
            if (useFullImage) return
            const target = event.target as HTMLImageElement
            target.src = page.image_urls.full
            target.onerror = null
          }}
        />
      </Box>
      {showIcons && (
        <Stack
          direction="row"
          sx={{
            position: 'absolute',
            top: 4,
            right: 4,
          }}
        >
          <IconButton color="primary" onClick={fullImageOverlay.open}>
            <OpenInFull />
          </IconButton>
          <IconButton
            color="error"
            onClick={() => {
              setPagesToDelete((prev) => {
                return pageIsToBeDeleted
                  ? prev.filter((p) => p !== page.page_number)
                  : [...prev, page.page_number]
              })
            }}
          >
            {pageIsToBeDeleted ? <CheckCircleOutline /> : <DeleteOutline />}
          </IconButton>
        </Stack>
      )}
      <Dialog
        title={`Page ${page.page_number}`}
        {...fullImageOverlay}
        onClose={() => {
          fullImageOverlay.close()
          setShowIcons(false)
        }}
        fullScreen
      >
        <Box
          sx={{
            background: 'black',
            height: 'calc(100vh - 128px)', // 128px is the height of the dialog header and footer
            textAlign: 'center',
          }}
        >
          <img
            src={page.image_urls.full}
            style={{ objectFit: 'contain', height: '100%', width: '100%' }}
          />
        </Box>

        <DialogFooter>
          <Button
            onClick={() => {
              fullImageOverlay.close()
              setShowIcons(false)
            }}
          >
            Close
          </Button>
        </DialogFooter>
      </Dialog>
    </Card>
  )
}
