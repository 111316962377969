import { Stack, Typography } from '@mui/material'
import { FormCheckbox } from '@/components/forms'
import { PropertyFieldProps } from './PropertyField'

type PropertyCheckboxProps = PropertyFieldProps

export default function PropertyCheckbox({
  propertyId,
  property,
  required,
}: PropertyCheckboxProps) {
  const { display } = property

  return (
    <FormCheckbox
      name={propertyId}
      label={
        <Stack>
          <Typography>{display.label}</Typography>
          {display.description && (
            <Typography color="textSecondary" variant="caption">
              {display.description}
            </Typography>
          )}
        </Stack>
      }
      required={required}
      sx={{ mt: -1, ml: -1 }}
      formLabelSx={{
        alignItems: 'flex-start',
        width: 'fit-content',
      }}
    />
  )
}
