import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Document } from '@/types/documents'
import { WorkflowState } from '@/types/workflows'
import { useFeatureFlagContext } from '@/feature_flags/FeatureFlagProvider'
import { useSelectedWorkflowContext } from '@/components/workflows/SelectedWorkflowProvider'

type DocumentCurrentWorkflowViewProviderValue = {
  allowedDocumentCurrentWorkflowViews: WorkflowState['allowed_document_views']
  defaultDocumentCurrentWorkflowView?: WorkflowState['default_document_view']
  documentCurrentWorkflowView?: WorkflowState['default_document_view']
  setDocumentCurrentWorkflowView: (
    view: WorkflowState['default_document_view'],
  ) => void
  currentState?: WorkflowState
}

const DocumentCurrentWorkflowViewContext =
  createContext<DocumentCurrentWorkflowViewProviderValue>(
    {} as DocumentCurrentWorkflowViewProviderValue,
  )

export const useDocumentCurrentWorkflowViewContext = () =>
  useContext(DocumentCurrentWorkflowViewContext)

type DocumentCurrentWorkflowViewProviderProps = {
  children: ReactNode
  document?: Document
}

export default function DocumentCurrentWorkflowViewProvider({
  children,
  document,
}: DocumentCurrentWorkflowViewProviderProps) {
  const featureFlags = useFeatureFlagContext()

  const { selectedWorkflow } = useSelectedWorkflowContext()

  const documentState = useMemo(
    () =>
      selectedWorkflow.workflow_states?.find((state) =>
        document?.workflow_states_ids?.includes(state.id),
      ),
    [document, selectedWorkflow.workflow_states],
  )

  const allowedDocumentCurrentWorkflowViews = useMemo(() => {
    if (!documentState?.allowed_document_views) return []
    const includeComparisonView = featureFlags.comparison_view
    const includeSplitDocumentView = featureFlags.split_document_view

    return includeComparisonView && includeSplitDocumentView
      ? documentState.allowed_document_views
      : documentState.allowed_document_views.filter(
          (view) =>
            view === 'validation' ||
            (includeComparisonView && view === 'comparison') ||
            (includeSplitDocumentView && view === 'split-document'),
        )
  }, [
    documentState?.allowed_document_views,
    featureFlags.comparison_view,
    featureFlags.split_document_view,
  ])

  const defaultDocumentCurrentWorkflowView = useMemo(() => {
    if (documentState?.default_document_view) {
      return allowedDocumentCurrentWorkflowViews.includes(
        documentState.default_document_view,
      )
        ? documentState.default_document_view
        : allowedDocumentCurrentWorkflowViews[0]
    }
  }, [
    allowedDocumentCurrentWorkflowViews,
    documentState?.default_document_view,
  ])

  const [documentCurrentWorkflowView, setDocumentCurrentWorkflowView] =
    useState(defaultDocumentCurrentWorkflowView)

  useEffect(() => {
    if (!documentCurrentWorkflowView && defaultDocumentCurrentWorkflowView) {
      setDocumentCurrentWorkflowView(defaultDocumentCurrentWorkflowView)
    }

    if (
      documentCurrentWorkflowView &&
      !allowedDocumentCurrentWorkflowViews.includes(documentCurrentWorkflowView)
    ) {
      setDocumentCurrentWorkflowView(defaultDocumentCurrentWorkflowView)
    }
  }, [
    allowedDocumentCurrentWorkflowViews,
    defaultDocumentCurrentWorkflowView,
    documentCurrentWorkflowView,
  ])

  return (
    <DocumentCurrentWorkflowViewContext.Provider
      value={{
        allowedDocumentCurrentWorkflowViews,
        defaultDocumentCurrentWorkflowView,
        documentCurrentWorkflowView,
        setDocumentCurrentWorkflowView,
        currentState: documentState,
      }}
    >
      {children}
    </DocumentCurrentWorkflowViewContext.Provider>
  )
}
