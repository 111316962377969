import ModelCard from './ModelCard'
import { Container, Stack, Tooltip, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import { HelpOutlineOutlined } from '@mui/icons-material'
import ProjectBreadcrumb from '../project-dashboard/ProjectBreadcrumb'
import { useGetProjectLinkedModels } from '@/service-library/hooks/project-linked-models'
import { useProjectContext } from '../project-dashboard/ProjectProvider'
import NewModelCard from './NewModelCard'

export default function ModelLibrary() {
  const { project } = useProjectContext()
  const { projectLinkedModels, isLoading } = useGetProjectLinkedModels({
    filters: {
      project_id: project.id,
      fields__include: 'project_model,current_model_version,created_at',
      project_model__fields__include: 'project_model_type',
    },
  })

  const displayedProjectLinkedModels = projectLinkedModels
    .filter(
      (projectLinkedModel) =>
        projectLinkedModel.project_model?.project_model_type?.code !== 'OCR',
    )
    // Sort them by first added to last added
    .toSorted((a, b) =>
      new Date(a.created_at) > new Date(b.created_at) ? -1 : 0,
    )

  return (
    <Container maxWidth="xl">
      <ProjectBreadcrumb label="Model Library" url="../model-library" />

      <Stack direction="column" spacing={2}>
        <Stack direction="row" alignItems="center" sx={{ pl: 1 }} spacing={1}>
          <Typography variant="h4">Model Library</Typography>
          <Tooltip title="The Model Library is where you manage what models are available to this project. Models are only available for use in your project if they are added here.">
            <HelpOutlineOutlined sx={{ color: 'primary.main' }} />
          </Tooltip>
        </Stack>

        <Grid2 container spacing={2}>
          {displayedProjectLinkedModels.map((projectLinkedModel) => (
            <Grid2 key={projectLinkedModel.id} xs={12} md={6} lg={4}>
              <ModelCard
                key={projectLinkedModel.id}
                projectLinkedModel={projectLinkedModel}
              />
            </Grid2>
          ))}
          {!isLoading && (
            <Grid2 xs={12} md={6} lg={4}>
              <NewModelCard />
            </Grid2>
          )}
        </Grid2>
      </Stack>
    </Container>
  )
}
