import { useRef, useState, useEffect, useCallback, useMemo } from 'react'
import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
} from 'react-zoom-pan-pinch'
import Color from 'color'
import { CircularProgress, Stack, Alert, useTheme, Card } from '@mui/material'
import {
  blue,
  blueGrey,
  green,
  grey,
  indigo,
  orange,
  pink,
  red,
} from '@mui/material/colors'
import { DocumentChip } from '@/types/documents'
import { ProjectGridField } from '@/types/fields'
import generatedTrainingDocumentImage from '@/assets/generated_training_document.jpg'
import useLocalStorage from '@/hooks/useLocalStorage'
import useOverlay from '@/hooks/useOverlay'
import {
  FlagsCount,
  getPriorityLevel,
} from '@/components/flags/useFlagPriorityColor'
import { useImageZoomPanCenterContext } from '@/components/image-zoom-pan/providers/ImageZoomPanCenterProvider'
import { useProjectContext } from '@/components/project-dashboard/ProjectProvider'
import { useContainerSize } from '@/components/size-provider/SizeProvider'
import ImageZoomPanPopover from '@/components/validation/ImageZoomPanPopover'
import useDocumentChipActions from '@/components/validation/useDocumentChipActions'
import { useDocumentChipsContext } from '@/components/validation/providers/DocumentChipsProvider'
import { useDocumentRowValuesContext } from '@/components/validation/providers/DocumentRowValuesProvider'
import { useDocumentRowContext } from '@/components/validation/providers/DocumentRowProvider'
import { useSelectedFieldContext } from '@/components/validation/providers/SelectedFieldProvider'
import { useDocumentComparisonDataContext } from './providers/DocumentComparisonDataProvider'
import { useDocumentContext } from './providers/DocumentProvider'
import { useDocumentCurrentWorkflowViewContext } from './providers/DocumentCurrentWorkflowViewProvider'
import { useDocumentImagePageContext } from './providers/DocumentImagePageProvider'
import { useValidationOptionsContext } from './providers/ValidationOptionsProvider'
import {
  rotatePoint,
  scalePosition,
  Position,
  convertMouseEventPoint,
  drawChip,
  getChipsInRangeSelection,
  getSelectionMode,
  getChipsOnRows,
  orderPoints,
  getCornerCoordinates,
} from './image-zoom-pan-helpers'

// Magic number multipliers for drawing frames
const FONT_SIZE_MULTIPLIER = 0.012
const FONT_SIZE_MULTIPLIER_SMALL = 0.009

const CHIP_FRAME_PADDING_MULTIPLIER = 0.006
const CHIP_FRAME_LINE_WIDTH_MULTIPLIER = 0.002
const CHIP_FRAME_LINE_WIDTH_MULTIPLIER_SMALL = 0.001

const CHIP_FRAME_OFFSET_MULTIPLIER = 0.0028
const CHIP_FRAME_OFFSET_MULTIPLIER_SMALL = 0.0016

const CHIP_FRAME_AXIS_PADDING = 0

const ROW_COLUMN_LINE_WIDTH_MULTIPLIER = 0.0009

const selectionBoxColorMap = {
  selecting: blue['A400'],
  deselecting: red['A400'],
  'multi-selecting': green['A400'],
}

function getFieldFrameColor(
  fieldKey: string,
  flagsCount: FlagsCount,
  differentFieldKeysComparingChips?: string[],
  colorIndex: keyof typeof indigo = 800,
  isForBackground = false,
) {
  if (!differentFieldKeysComparingChips) {
    if (isForBackground) return 'transparent'
    if (!flagsCount) return indigo[colorIndex]
    const priorityLevel = getPriorityLevel(flagsCount)
    switch (priorityLevel) {
      case 'failure':
        return pink[colorIndex]
      case 'error':
        return red[colorIndex]
      case 'warning':
        return orange[
          typeof colorIndex == 'number' && colorIndex >= 400
            ? ((colorIndex - 300) as keyof typeof indigo)
            : 'A400'
        ] // adjusting the orange color index to match better with the warning color
      default:
        return indigo[colorIndex]
    }
  }

  const isDifferentField =
    differentFieldKeysComparingChips.includes('all') ||
    differentFieldKeysComparingChips.includes(fieldKey)

  const color = isDifferentField ? red[colorIndex] : green[colorIndex]
  return isForBackground ? `${color}50` : color
}

type ImageZoomPanProps = {
  rotationDegree: number
  transformerRef: React.RefObject<ReactZoomPanPinchRef>
  drawBoxes?: boolean
  showConfidence?: boolean
  fieldsContainerEl?: HTMLDivElement | null
  layout?: 'right' | 'left' | 'top' | 'bottom'
}

type ImageZoomPanDisplayProps = {
  image: HTMLImageElement
} & ImageZoomPanProps

function ImageZoomPanDisplay({
  image,
  rotationDegree,
  transformerRef,
  drawBoxes = true,
  showConfidence = false,
  fieldsContainerEl,
  layout = 'top',
}: ImageZoomPanDisplayProps) {
  const theme = useTheme()
  const chipsOverlay = useOverlay()

  const { project, fieldsMap } = useProjectContext()

  const [anchorPosition, setAnchorPosition] = useState({ left: 0, top: 0 })

  const [disablePanning, setDisablePanning] = useState(false)

  const [fieldsContainerWidth, setFieldsContainerWidth] = useState(
    fieldsContainerEl?.clientWidth || 0,
  )
  const containerSize = useContainerSize()

  const { document: documentData, isLoading } = useDocumentContext()
  const { currentState, documentCurrentWorkflowView } =
    useDocumentCurrentWorkflowViewContext()
  const { fieldsWithFlags, fieldKeysWithFlagsCount } =
    useDocumentRowValuesContext()
  const [selectedOptionKey] = useLocalStorage(
    'validation-visible-fields-option',
    'relevant',
  )

  const { imagePage, pageCount, setImagePage, thumbnailsOverlay } =
    useDocumentImagePageContext()

  const { differentFieldKeysComparingChips } =
    useDocumentComparisonDataContext()

  const {
    showOCR,
    languageCode,
    showChipFrame,
    showAllChipFrames,
    showRowColumnChipFrames,
  } = useValidationOptionsContext()

  const { centeredScaleState, centeredPositionXState } =
    useImageZoomPanCenterContext()

  const [, setCenteredScale] = centeredScaleState || []
  const [, setCenteredPositionX] = centeredPositionXState || []

  const imageViewRef = useRef<HTMLDivElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const chipCanvasRef = useRef<HTMLCanvasElement>(
    document.createElement('canvas'),
  )
  const spotlightCanvasRef = useRef<HTMLCanvasElement>(
    document.createElement('canvas'),
  )
  const selectionBoxCanvasRef = useRef<HTMLCanvasElement>(
    document.createElement('canvas'),
  )
  const selectedChipsFrameCanvasRef = useRef<HTMLCanvasElement>(
    document.createElement('canvas'),
  )

  const { canvasWidth, canvasHeight } = useMemo(() => {
    return {
      canvasWidth:
        rotationDegree === 0 || rotationDegree === 180
          ? image.width
          : image.height,
      canvasHeight:
        rotationDegree === 90 || rotationDegree === 270
          ? image.width
          : image.height,
    }
  }, [image.height, image.width, rotationDegree])

  const [selectionOrigin, setSelectionOrigin] = useState<{
    original: Position
    rotated: Position
    selectionMode: 'selecting' | 'deselecting' | 'multi-selecting'
  } | null>(null)
  const [selectionEnd, setSelectionEnd] = useState<{
    original: Position
    rotated: Position
  } | null>(null)

  const { documentChips = [], hoveredChipId } = useDocumentChipsContext()

  const currentPageChips = useMemo(
    () =>
      documentChips.filter(
        ({ document_page_number }) => document_page_number === imagePage,
      ),
    [documentChips, imagePage],
  )

  const {
    popoverChips,
    shouldNotAllowRangeSelection,
    handleClickedPolygon,
    onCanvasClick,
    onRangeSelection,
  } = useDocumentChipActions({
    canvasRef,
    chipsOverlay,
    currentPageChips,
    drawBoxes,
    image,
    canvasSize: {
      width: canvasWidth,
      height: canvasHeight,
    },
    rotationDegree,
    setAnchorPosition,
  })

  const { selectedField, isSelectedField } = useSelectedFieldContext()

  const mainDocumentRow = useDocumentRowContext()

  const allowRangeSelection = useMemo(
    () => !shouldNotAllowRangeSelection,
    [shouldNotAllowRangeSelection],
  )

  // This ref is used to track the distance a user drags each time they pan the document.
  // This can be used to roughly determine if the user is dragging or clicking.
  // If they _barely_ drag, we still want to count it as a click, since it likely wasn't intentionally dragged.
  const dragDistanceRef = useRef({
    x: 0,
    y: 0,
  })

  const handleClick = (
    event: React.MouseEvent<HTMLCanvasElement, MouseEvent>,
  ) => {
    if (
      // If we're doing a multi-selection, we don't want to click
      selectionOrigin?.selectionMode === 'multi-selecting' ||
      selectionOrigin?.selectionMode === 'selecting'
    )
      return

    // If they only dragged a few pixels, it is likely meant to be a click.
    if (
      Math.abs(dragDistanceRef.current.x) > 4 ||
      Math.abs(dragDistanceRef.current.y) > 4
    ) {
      // We reset the dragDistanceRef here instead of in onPanningStop or onMouseUp since those fire before onClick.
      dragDistanceRef.current = {
        x: 0,
        y: 0,
      }
    } else {
      onCanvasClick(event)
    }
  }

  const initialScale = useMemo(() => {
    const tempInitialScale = containerSize.height / canvasHeight
    if (layout === 'top' || layout === 'bottom') return tempInitialScale
    const scaledCanvasWidth = canvasWidth * tempInitialScale
    const availableWidth = containerSize.width - fieldsContainerWidth
    if (scaledCanvasWidth > availableWidth) {
      return availableWidth / canvasWidth
    }
    return tempInitialScale
  }, [
    canvasHeight,
    canvasWidth,
    containerSize.height,
    containerSize.width,
    fieldsContainerWidth,
    layout,
  ])

  const initialPositionX = useMemo(() => {
    const scaledCanvasWidthToInitialScale = canvasWidth * initialScale
    const paneWidth = containerSize.width

    // Subtract the scaled canvas width from the zoom/pan pane width
    // and divide it by 2 to have it centered
    const centeredPosition = (paneWidth - scaledCanvasWidthToInitialScale) / 2

    if (layout === 'bottom' || layout === 'top') {
      return centeredPosition
    } else if (layout === 'right') {
      // When field panel is at the right, we moved the image half fieldsContainerWidth
      // from the centered position to the left. (16 accounts for margins)
      return centeredPosition - fieldsContainerWidth / 2 - 16
    }
    // When field panel is at the left, we moved the image half fieldsContainerWidth
    // from the centered position to the right. (8 accounts for margins)
    return centeredPosition + fieldsContainerWidth / 2 + 8
  }, [
    canvasWidth,
    containerSize.width,
    fieldsContainerWidth,
    initialScale,
    layout,
  ])

  useEffect(() => {
    if (!fieldsContainerEl) return
    const observer = new ResizeObserver(() => {
      setFieldsContainerWidth(fieldsContainerEl.clientWidth)
    })
    observer.observe(fieldsContainerEl)
    return () => {
      observer.disconnect()
    }
  }, [fieldsContainerEl])

  useEffect(() => {
    setCenteredScale(initialScale)
    setCenteredPositionX(initialPositionX)
  }, [initialPositionX, initialScale, setCenteredScale, setCenteredPositionX])

  // Reset zoom each time we switch pages or orientation
  useEffect(() => {
    transformerRef.current?.resetTransform()
  }, [imagePage, layout, transformerRef])

  // #region Set Canvas Sizes
  // (only fires when image or image size changes)
  useEffect(() => {
    const imageCanvas = canvasRef.current
    if (!imageCanvas) return
    imageCanvas.width = canvasWidth
    imageCanvas.height = canvasHeight
    const chipCanvas = chipCanvasRef.current
    chipCanvas.width = canvasWidth
    chipCanvas.height = canvasHeight
    const spotlightCanvas = spotlightCanvasRef.current
    spotlightCanvas.width = canvasWidth
    spotlightCanvas.height = canvasHeight
    const selectionBoxCanvas = selectionBoxCanvasRef.current
    selectionBoxCanvas.width = canvasWidth
    selectionBoxCanvas.height = canvasHeight
    const selectedChipsFrameCanvas = selectedChipsFrameCanvasRef.current
    selectedChipsFrameCanvas.width = canvasWidth
    selectedChipsFrameCanvas.height = canvasHeight
  }, [canvasWidth, canvasHeight])

  // Set up the Chips Canvas and draw chip rectangles
  useEffect(() => {
    const imageCanvas = canvasRef.current
    const imageContext = imageCanvas?.getContext('2d')
    const chipCanvas = chipCanvasRef.current
    const chipContext = chipCanvas.getContext('2d')
    const spotlightCanvas = spotlightCanvasRef.current
    const spotlightContext = spotlightCanvas.getContext('2d')
    const selectionBoxCanvas = selectionBoxCanvasRef.current
    const selectionBoxContext = selectionBoxCanvas.getContext('2d')
    const selectedChipsFrameCanvas = selectedChipsFrameCanvasRef.current
    const selectedChipsFrameContext = selectedChipsFrameCanvas.getContext('2d')
    if (
      !chipContext ||
      !imageContext ||
      !spotlightContext ||
      !selectionBoxContext ||
      !selectedChipsFrameContext
    )
      return

    // Always clear out existing canvases before redrawing
    imageContext.clearRect(0, 0, canvasWidth, canvasHeight)
    chipContext.clearRect(0, 0, canvasWidth, canvasHeight)
    spotlightContext.clearRect(0, 0, canvasWidth, canvasHeight)
    selectionBoxContext.clearRect(0, 0, canvasWidth, canvasHeight)
    selectedChipsFrameContext.clearRect(0, 0, canvasWidth, canvasHeight)

    const selectedFieldIsCheckbox =
      selectedField?.field.project_grid_field_type.code === 'checkbox'

    const fillChip = () => {
      chipContext.fill()
    }

    // #region Draw Chips
    if (drawBoxes && selectedField?.field.input_behavior !== 'manual_only') {
      chipContext.lineWidth = 2
      currentPageChips.forEach((chip) => {
        const isCheckboxChip =
          chip.text === 'FalseBox' || chip.text === 'TrueBox'

        const isAttachedToTableField =
          chip.document_row_id !== mainDocumentRow?.id

        const isSameRowAsSelectedField =
          selectedField?.document_row_id === chip.document_row_id

        const isSameColumnAsSelectedField =
          selectedField?.field?.id === chip.project_grid_field_id

        chipContext.strokeStyle = grey[800]
        chipContext.setLineDash([5, 2])

        if (
          // Draw chip if no field is selected
          !selectedField ||
          // Draw the chip box if the selected field is a checkbox and the chip is a checkbox
          (selectedFieldIsCheckbox && isCheckboxChip) ||
          // Draw the chip box if the selected field is not a checkbox and the chip is not a checkbox
          (!selectedFieldIsCheckbox && !isCheckboxChip)
        ) {
          drawChip({
            canvasSize: { width: canvasWidth, height: canvasHeight },
            context: chipContext,
            polygon: chip.pbox,
          })
        }
        if (showConfidence) {
          const confidenceColor =
            chip.confidence >= 90
              ? 'green'
              : chip.confidence >= 50
              ? 'yellow'
              : 'red'
          chipContext.fillStyle = Color(theme.palette[confidenceColor].main)
            .alpha(showOCR ? 1 : 0.5)
            .string()
          fillChip()
        } else {
          chipContext.fillStyle = Color(blue[300])
            .alpha(showOCR ? 1 : 0.5)
            .string()

          const chipIsAttachedToSelectedField = isSelectedField?.(
            chip.project_grid_field_id,
            chip.document_row_id,
          )
          if (selectedField) {
            if (chipIsAttachedToSelectedField) {
              fillChip()
            } else if (
              chip.project_grid_field_id &&
              isAttachedToTableField &&
              (isSameRowAsSelectedField || isSameColumnAsSelectedField)
            ) {
              chipContext.fillStyle = Color(theme.palette.secondary.main)
                .alpha(showOCR ? 1 : 0.15)
                .string()
              fillChip()
            } else if (
              chip.project_grid_field_id &&
              !chipIsAttachedToSelectedField
            ) {
              chipContext.fillStyle = showOCR
                ? grey[600]
                : Color('#000000').alpha(0.25).string()
              fillChip()
            } else if (showOCR) {
              chipContext.fillStyle = '#ffffff'
              fillChip()
            }
          } else if (chip.project_grid_field_id !== null) {
            fillChip()
          } else if (showOCR) {
            chipContext.fillStyle = '#ffffff'
            fillChip()
          }
        }

        // #region (if showOCR) Draw OCR Text
        if (showOCR) {
          const orderedPoints = orderPoints(chip.pbox)
          const topLeft = scalePosition({
            position: orderedPoints[0],
            width: canvasWidth,
            height: canvasHeight,
          })
          const topRight = scalePosition({
            position: orderedPoints[1],
            width: canvasWidth,
            height: canvasHeight,
          })
          const bottomLeft = scalePosition({
            position: orderedPoints[3],
            width: canvasWidth,
            height: canvasHeight,
          })
          const chipWidth = topRight.x - topLeft.x
          const chipHeight = topLeft.y - bottomLeft.y

          chipContext.fillStyle = '#000000'

          const textToDisplay =
            languageCode && languageCode !== documentData?.primary_language_id
              ? chip.translations.find(
                  ({ language_id }) => language_id === languageCode,
                )?.text || chip.text
              : chip.text

          // If it seems like the chip is positioned sideways, we will rotate the context
          // to draw the text in a sideways orientation as well. (leaving one-character chips
          // out because most of the time, those chips won't be positioned sideways.)
          if (chipWidth < chipHeight && textToDisplay.length > 1) {
            const fontSizeIsTooSmall = chipWidth - 2 < 10

            chipContext.save()
            chipContext.font = `${
              fontSizeIsTooSmall ? 10 : chipWidth - 2
            }px sans-serif`
            chipContext.translate(bottomLeft.x, bottomLeft.y)
            chipContext.rotate(Math.PI / 2)
            chipContext.fillText(
              textToDisplay,
              fontSizeIsTooSmall ? 1 : 2,
              fontSizeIsTooSmall ? 0 : -5,
              chipHeight - 2,
            )
            chipContext.restore()
          } else {
            const fontSizeIsTooSmall = chipHeight - 2 < 10
            chipContext.font = `${
              fontSizeIsTooSmall ? 10 : chipHeight - 2
            }px sans-serif`

            chipContext.fillText(
              textToDisplay,
              bottomLeft.x + (fontSizeIsTooSmall ? 1 : 2),
              fontSizeIsTooSmall ? topLeft.y : topLeft.y - 5,
              chipWidth - 2,
            )
          }
          // resetting fill color so that other drawings use the right color
          chipContext.fillStyle = Color(theme.palette.primary.main)
            .alpha(0.5)
            .string()
        }
      })
    }

    // #region Draw Spotlight for hovered chip
    if (hoveredChipId || selectedFieldIsCheckbox) {
      const spotlight = (x: number, y: number, r: number) => {
        spotlightContext.globalCompositeOperation = 'destination-out' // Cuts out the spotlight circle from layer below instead of drawing over it
        spotlightContext.beginPath()
        spotlightContext.arc(x, y, r, 0, Math.PI * 2)
        spotlightContext.closePath()
        spotlightContext.fillStyle = 'white' // Color doesn't matter, but must be a solid color
        spotlightContext.fill()
      }
      spotlightContext.globalCompositeOperation = 'source-over'
      spotlightContext.fillStyle = '#00000031'
      spotlightContext.fillRect(0, 0, canvasWidth, canvasHeight)

      currentPageChips.forEach((chip) => {
        const isCheckboxChip =
          chip.text === 'FalseBox' || chip.text === 'TrueBox'
        if (!isCheckboxChip && hoveredChipId !== chip.id) return

        const orderedPoints = orderPoints(chip.pbox)
        const topLeft = scalePosition({
          position: orderedPoints[0],
          width: canvasWidth,
          height: canvasHeight,
        })
        const topRight = scalePosition({
          position: orderedPoints[1],
          width: canvasWidth,
          height: canvasHeight,
        })
        const bottomLeft = scalePosition({
          position: orderedPoints[3],
          width: canvasWidth,
          height: canvasHeight,
        })

        const checkboxWidth = topRight.x - topLeft.x
        const checkboxHeight = topLeft.y - bottomLeft.y

        const radius =
          (checkboxWidth > checkboxHeight ? checkboxWidth : checkboxHeight) + 7
        // Get mid-point between left and right side of checkbox
        const midX = topRight.x - checkboxWidth / 2
        // Get mid-point between top and bottom of checkbox
        const midY = topLeft.y - checkboxHeight / 2
        spotlight(midX, midY, radius)
      })
    }

    // #region Calculate translation based on rotation
    switch (rotationDegree) {
      case 90:
        imageContext.translate(image.height, 0)
        break
      case 180:
        imageContext.translate(image.width, image.height)
        break
      case 270:
        imageContext.translate(0, image.width)
        break
    }

    // MARK: Draw Selection Box
    if (selectionOrigin && selectionEnd) {
      selectionBoxContext.beginPath()
      selectionBoxContext.roundRect(
        selectionOrigin.original.x,
        selectionOrigin.original.y,
        selectionEnd.original.x - selectionOrigin.original.x,
        selectionEnd.original.y - selectionOrigin.original.y,
        8,
      )
      const isSelecting =
        selectionOrigin.selectionMode === 'selecting' ||
        selectionOrigin.selectionMode === 'multi-selecting'
      const selectionBoxColor =
        selectionBoxColorMap[selectionOrigin.selectionMode]
      selectionBoxContext.strokeStyle = selectionBoxColor
      selectionBoxContext.lineWidth = 5
      selectionBoxContext.stroke()
      selectionBoxContext.closePath()

      const chipsInSelection = getChipsInRangeSelection({
        image,
        selectionMode: selectionOrigin.selectionMode,
        chips: currentPageChips,
        polygon: [
          { x: selectionOrigin.rotated.x, y: selectionOrigin.rotated.y },
          { x: selectionEnd.rotated.x, y: selectionOrigin.rotated.y },
          { x: selectionEnd.rotated.x, y: selectionEnd.rotated.y },
          { x: selectionOrigin.rotated.x, y: selectionEnd.rotated.y },
        ],
        selectedField,
      })

      let chipsInRows: DocumentChip[][] = []
      if (
        selectionOrigin.selectionMode === 'multi-selecting' &&
        canvasRef.current
      ) {
        chipsInRows = getChipsOnRows(chipsInSelection, canvasRef.current)
      }

      chipsInSelection.forEach((chip) => {
        let rowIndex = 0
        if (selectionOrigin.selectionMode === 'multi-selecting') {
          rowIndex = chipsInRows.findIndex((row) =>
            row.find(({ id }) => id === chip.id),
          )
        }
        drawChip({
          canvasSize: { width: canvasWidth, height: canvasHeight },
          context: chipContext,
          polygon: chip.pbox,
        })

        if (!isSelecting)
          chipContext.fillStyle = Color(red[700]).alpha(0.45).string()
        else if (rowIndex % 2 === 1) {
          chipContext.fillStyle = Color(green['A400']).alpha(0.45).string()
        } else {
          chipContext.fillStyle = Color(blue['A400']).alpha(0.5).string()
        }
        fillChip()
      })
    }

    // #region Draw Rounded Rect
    function roundedRect(
      x: number,
      y: number,
      width: number,
      height: number,
      radius: number,
      fillRect = true,
    ) {
      if (!selectedChipsFrameContext) return
      selectedChipsFrameContext.beginPath()
      selectedChipsFrameContext.moveTo(x + radius, y)
      selectedChipsFrameContext.arcTo(
        x + width,
        y,
        x + width,
        y + height,
        radius,
      )
      selectedChipsFrameContext.arcTo(
        x + width,
        y + height,
        x,
        y + height,
        radius,
      )
      selectedChipsFrameContext.arcTo(x, y + height, x, y, radius)
      selectedChipsFrameContext.arcTo(x, y, x + width, y, radius)
      selectedChipsFrameContext.closePath()
      selectedChipsFrameContext.stroke() // Draw the border
      fillRect && selectedChipsFrameContext.fill() // Fill the rectangle
    }

    function drawDashedChipFrame(chips: DocumentChip[]) {
      if (chips.length > 1 && selectedChipsFrameContext) {
        selectedChipsFrameContext.closePath()
        const { topLeftX, topLeftY, bottomRightX, bottomRightY } =
          getCornerCoordinates(chips)

        const rowTopLeftCoordinate = scalePosition({
          position: {
            x: topLeftX,
            y: topLeftY,
          },
          width: canvasWidth,
          height: canvasHeight,
        })
        const rowBottomRightCoordinate = scalePosition({
          position: {
            x: bottomRightX,
            y: bottomRightY,
          },
          width: canvasWidth,
          height: canvasHeight,
        })

        selectedChipsFrameContext.lineWidth =
          image.height * ROW_COLUMN_LINE_WIDTH_MULTIPLIER
        selectedChipsFrameContext.strokeStyle = blueGrey['A700']
        selectedChipsFrameContext.setLineDash([5, 5])
        roundedRect(
          rowTopLeftCoordinate.x - CHIP_FRAME_AXIS_PADDING,
          rowTopLeftCoordinate.y - CHIP_FRAME_AXIS_PADDING,
          rowBottomRightCoordinate.x -
            rowTopLeftCoordinate.x +
            CHIP_FRAME_AXIS_PADDING * 2,
          rowBottomRightCoordinate.y -
            rowTopLeftCoordinate.y +
            CHIP_FRAME_AXIS_PADDING * 2,
          8,
          false,
        )
        selectedChipsFrameContext.stroke()
        // Reset dash so it doesn't apply to everything else
        selectedChipsFrameContext.setLineDash([0, 0])
      }
    }

    // #region Draw Chip Frame
    function drawChipFrame(
      chips: DocumentChip[],
      field: ProjectGridField,
      showingAll = false,
    ) {
      if (!selectedChipsFrameContext) return
      let fontSize = Math.round(
        image.height *
          (showingAll ? FONT_SIZE_MULTIPLIER_SMALL : FONT_SIZE_MULTIPLIER),
      )
      const padding = showingAll
        ? 0
        : image.height * CHIP_FRAME_PADDING_MULTIPLIER
      let textYOffset = Math.round(
        image.height *
          (showingAll
            ? CHIP_FRAME_OFFSET_MULTIPLIER_SMALL
            : CHIP_FRAME_OFFSET_MULTIPLIER),
      )

      const isTooSmall = fontSize < 8
      if (isTooSmall) {
        fontSize = 8
        textYOffset += 2
      }
      // Do nothing if we don't have chips on the current page
      const { topLeftX, topLeftY, bottomRightX, bottomRightY } =
        getCornerCoordinates(chips)

      const topLeftCoordinate = scalePosition({
        position: {
          x: topLeftX,
          y: topLeftY,
        },
        width: canvasWidth,
        height: canvasHeight,
      })
      const bottomRightCoordinate = scalePosition({
        position: {
          x: bottomRightX,
          y: bottomRightY,
        },
        width: canvasWidth,
        height: canvasHeight,
      })

      const fieldKey = `${chips[0].project_grid_field_id}_${chips[0].document_row_id}`
      selectedChipsFrameContext.strokeStyle = getFieldFrameColor(
        fieldKey,
        fieldKeysWithFlagsCount[fieldKey],
        differentFieldKeysComparingChips,
        'A700',
      )
      selectedChipsFrameContext.lineWidth = Math.round(
        image.height *
          (showingAll
            ? CHIP_FRAME_LINE_WIDTH_MULTIPLIER_SMALL
            : CHIP_FRAME_LINE_WIDTH_MULTIPLIER),
      )
      selectedChipsFrameContext.fillStyle = getFieldFrameColor(
        fieldKey,
        fieldKeysWithFlagsCount[fieldKey],
        differentFieldKeysComparingChips,
        200,
        true,
      )
      // Draw the frame around the chips
      roundedRect(
        topLeftCoordinate.x - padding,
        topLeftCoordinate.y - padding,
        bottomRightCoordinate.x - topLeftCoordinate.x + padding * 2,
        bottomRightCoordinate.y - topLeftCoordinate.y + padding * 2,
        selectedField ? 8 : 2,
      )

      selectedChipsFrameContext.font = `${fontSize}px "Helvetica"`
      selectedChipsFrameContext.strokeStyle = getFieldFrameColor(
        fieldKey,
        fieldKeysWithFlagsCount[fieldKey],
        differentFieldKeysComparingChips,
      )
      selectedChipsFrameContext.fillStyle = getFieldFrameColor(
        fieldKey,
        fieldKeysWithFlagsCount[fieldKey],
        differentFieldKeysComparingChips,
      )
      const textWidth = selectedChipsFrameContext.measureText(field.name)

      // Draw field name box
      roundedRect(
        topLeftCoordinate.x - padding + 12,
        topLeftCoordinate.y - padding - fontSize + textYOffset,
        textWidth.width + 2,
        fontSize + 2,
        2,
      )
      selectedChipsFrameContext.fill()
      selectedChipsFrameContext.stroke()

      selectedChipsFrameContext.fillStyle = 'white'
      selectedChipsFrameContext.fillText(
        field.name,
        topLeftCoordinate.x - padding + 13,
        topLeftCoordinate.y - padding + 2,
      )
    }

    // #region Draw Chip Frame Trigger
    const selectedFieldChips = currentPageChips.filter((chip) =>
      isSelectedField?.(chip.project_grid_field_id, chip.document_row_id),
    )
    if (selectedField && selectedFieldChips.length > 0 && drawBoxes) {
      const sameRowChips = currentPageChips.filter((chip) => {
        return (
          selectedField?.document_row_id === chip.document_row_id &&
          chip.document_row_id !== mainDocumentRow?.id
        )
      })
      const sameColumnChips = currentPageChips.filter((chip) => {
        return (
          selectedField?.field?.id === chip.project_grid_field_id &&
          chip.document_row_id !== mainDocumentRow?.id
        )
      })

      // Draw box around all chips in the same row
      if (showRowColumnChipFrames) {
        drawDashedChipFrame(sameRowChips)
        drawDashedChipFrame(sameColumnChips)
      }

      if (showChipFrame) drawChipFrame(selectedFieldChips, selectedField.field)
    }

    // #region Draw all chip frames
    if (!selectedField && showAllChipFrames && drawBoxes) {
      const chipGroups = currentPageChips.reduce<
        Record<string, DocumentChip[]>
      >((acc, chip) => {
        const key = `${chip.project_grid_field_id}_${chip.document_row_id}`
        if (!acc[key]) acc[key] = []
        acc[key].push(chip)
        return acc
      }, {})

      const onlyRelevantFields =
        documentCurrentWorkflowView === 'validation' &&
        selectedOptionKey === 'relevant'

      const onlyFlaggedFields =
        documentCurrentWorkflowView === 'validation' &&
        selectedOptionKey === 'flagged'

      Object.values(chipGroups).forEach((chipGroup) => {
        const fieldId = chipGroup[0].project_grid_field_id
        if (
          !fieldId ||
          (onlyRelevantFields &&
            currentState?.excluded_project_grid_fields_ids?.includes(
              fieldId,
            )) ||
          (onlyFlaggedFields && !fieldsWithFlags.includes(fieldId))
        )
          return

        const field = fieldsMap[fieldId]
        if (!field) return
        drawChipFrame(chipGroup, field, true)
      })
    }

    // #region Rotate & Draw Canvases
    imageContext.rotate((rotationDegree * Math.PI) / 180)

    // Draw the document image
    imageContext.drawImage(image, 0, 0, image.width, image.height)

    // Draw the chip canvas as a layer of the image canvas
    imageContext.drawImage(chipCanvas, 0, 0, image.width, image.height)

    // Draw the spotlight canvas as a layer of the image canvas
    imageContext.drawImage(spotlightCanvas, 0, 0, image.width, image.height)

    // Draw the chip frame canvas as a layer of the image canvas
    imageContext.drawImage(
      selectedChipsFrameCanvas,
      0,
      0,
      image.width,
      image.height,
    )

    imageContext.resetTransform()
  }, [
    canvasHeight,
    canvasWidth,
    currentPageChips,
    currentState?.excluded_project_grid_fields_ids,
    differentFieldKeysComparingChips,
    documentChips,
    documentCurrentWorkflowView,
    documentData?.primary_language_id,
    drawBoxes,
    fieldKeysWithFlagsCount,
    fieldsMap,
    fieldsWithFlags,
    hoveredChipId,
    image,
    imagePage,
    isSelectedField,
    languageCode,
    mainDocumentRow?.id,
    rotationDegree,
    selectedField,
    selectedOptionKey,
    selectionEnd,
    selectionOrigin,
    showAllChipFrames,
    showChipFrame,
    showConfidence,
    showOCR,
    showRowColumnChipFrames,
    theme.palette,
  ])

  // #region On Mouse Down
  const onMouseDown: React.MouseEventHandler = useCallback(
    (event) => {
      const canvas = selectionBoxCanvasRef.current
      const selectionMode = getSelectionMode(
        event,
        project,
        selectedField?.field,
      )
      if (!selectionMode || !allowRangeSelection || !canvas) return

      if (
        !selectionMode ||
        // We don't allow selection mode in the validation screen when there is not a selected field
        ((selectionMode === 'selecting' ||
          selectionMode === 'multi-selecting') &&
          !selectedField)
      )
        return

      const clickedPoint = convertMouseEventPoint({
        event,
        canvas,
      })
      const origin = rotatePoint({
        point: clickedPoint,
        rotationDegree,
        image,
      })

      setDisablePanning(true)
      setSelectionOrigin({
        original: clickedPoint,
        rotated: origin,
        selectionMode,
      })
    },
    [allowRangeSelection, image, project, rotationDegree, selectedField],
  )

  // #region On Mouse Move
  const onMouseMove: React.MouseEventHandler = useCallback(
    (event) => {
      const canvas = selectionBoxCanvasRef.current
      const selectionMode = getSelectionMode(
        event,
        project,
        selectedField?.field,
      )

      if (
        !selectionMode ||
        !allowRangeSelection ||
        !canvas ||
        (selectionMode && selectionMode !== selectionOrigin?.selectionMode)
      )
        return

      const clickedPoint = convertMouseEventPoint({
        event,
        canvas,
      })
      const end = rotatePoint({
        point: clickedPoint,
        rotationDegree,
        image,
      })
      setSelectionEnd({
        original: clickedPoint,
        rotated: end,
      })
    },
    [
      allowRangeSelection,
      image,
      project,
      rotationDegree,
      selectedField?.field,
      selectionOrigin?.selectionMode,
    ],
  )

  // #region On Mouse Up
  const onMouseUp: React.MouseEventHandler = useCallback(() => {
    setDisablePanning(false)
    if (!allowRangeSelection) return

    if (selectionOrigin && selectionEnd) {
      const end = selectionEnd.rotated

      onRangeSelection(
        {
          origin: selectionOrigin.rotated,
          end,
        },
        selectionOrigin.selectionMode,
      )
    }

    // Timing out by an event cycle so we can check in additional events handlers for the selection mode
    setTimeout(() => {
      setSelectionOrigin(null)
      setSelectionEnd(null)
    }, 0)
  }, [allowRangeSelection, onRangeSelection, selectionEnd, selectionOrigin])

  const onWheel = (event: WheelEvent) => {
    if (event.metaKey) {
      thumbnailsOverlay.open()
      if (event.deltaY > 0 && imagePage < pageCount) {
        setImagePage((prev) => prev + 1)
      } else if (event.deltaY < 0 && imagePage > 1) {
        setImagePage((prev) => prev - 1)
      }
    }
  }

  return (
    <div
      ref={imageViewRef}
      style={{
        height: '100%',
        width: '100%',
        background: theme.palette.background.default,
      }}
      // Applying onmouseup to the pane instead of the canvas so we catch mouseup events that happen outside of the canvas
      onMouseUp={onMouseUp}
    >
      <ImageZoomPanPopover
        chips={popoverChips}
        overlay={chipsOverlay}
        anchorPosition={anchorPosition}
        onChipClick={handleClickedPolygon}
      />
      <TransformWrapper
        ref={transformerRef}
        initialScale={initialScale}
        initialPositionX={initialPositionX}
        minScale={0.66 * initialScale}
        panning={{
          disabled: disablePanning,
          velocityDisabled: true,
        }}
        limitToBounds={false}
        wheel={{
          step: 0.05,
        }}
        onPanning={(_ref, event) => {
          const { movementX, movementY } = event as MouseEvent
          dragDistanceRef.current = {
            x: dragDistanceRef.current.x + movementX,
            y: dragDistanceRef.current.y + movementY,
          }
        }}
        onWheel={(_ref, event) => {
          onWheel(event)
        }}
        // we need this because onWheel doesn't get called when the max level of zooming is reached,
        // and we can't only depend on this because it doesn't get called as often as onWheel.
        onZoomStart={(_ref, event) => {
          onWheel(event as WheelEvent)
        }}
      >
        <TransformComponent
          wrapperStyle={{
            zIndex: 4,
            backgroundSize: '40px 40px',
            backgroundImage: `radial-gradient(circle, ${theme.palette.divider} 1px, rgba(0, 0, 0, 0) 1px)`,
            height: '100%',
            width: '100%',
            maxHeight: '100%',
            maxWidth: '100%',
          }}
        >
          <Card elevation={1} sx={{ position: 'relative' }}>
            <canvas
              ref={canvasRef}
              style={{
                display: isLoading ? 'none' : 'initial',
              }}
              onClick={handleClick}
              onMouseDown={onMouseDown}
              onMouseMove={onMouseMove}
              onMouseLeave={() => {
                // This is to clean up in case they move their mouse out of the canvas while dragging
                dragDistanceRef.current = {
                  x: 0,
                  y: 0,
                }
              }}
            />
            <canvas
              ref={selectionBoxCanvasRef}
              style={{
                display: isLoading ? 'none' : 'initial',
                position: 'absolute',
                top: 0,
                left: 0,
                pointerEvents: 'none',
              }}
            />
          </Card>
        </TransformComponent>
      </TransformWrapper>
    </div>
  )
}

/** Responsible for fetching image data and handling loading/error states for image data. */
export default function ImageZoomPan(props: ImageZoomPanProps) {
  const { document } = useDocumentContext()
  const { imagePage } = useDocumentImagePageContext()
  const [image, setImage] = useState<HTMLImageElement | null>(null)

  // Prevents page from breaking when imagePage hasn't reset yet, and the imagePage is greater than the new doc page count
  const pageNumber = imagePage > (document?.page_count || 0) ? 1 : imagePage

  const [isFetchingImage, setIsFetchingImage] = useState(true)
  const [imageFailed, setImageFailed] = useState(false)

  useEffect(() => {
    setImage(null)
  }, [pageNumber])

  // This is dumb, but image has to be stateful. Otherwise it won't trigger re-renders on change.
  useEffect(() => {
    setIsFetchingImage(true)
    if (
      document?.document_pages[pageNumber - 1]?.image_urls.full ||
      (document && !document?.needs_file)
    ) {
      let imageEl: HTMLImageElement | null = null
      imageEl = new Image()
      imageEl.onload = () => {
        setIsFetchingImage(false)
        setImage(imageEl)
      }
      imageEl.onerror = () => {
        setIsFetchingImage(false)
        setImageFailed(true)
      }
      if (document.needs_file) {
        imageEl.src =
          document?.document_pages[pageNumber - 1]?.image_urls.full || ''
      } else {
        imageEl.src = generatedTrainingDocumentImage
      }
    } else {
      setImage(null)
    }
  }, [document, document?.document_pages, document?.needs_file, pageNumber])

  return image ? (
    <ImageZoomPanDisplay {...props} image={image} />
  ) : (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        height: '100%',
        // style properties use to "cover" chips in popper
        zIndex: 4,
        position: 'inherit',
      }}
    >
      {imageFailed && (
        <Alert severity="error">
          Unable to load document image. Please try again later.
        </Alert>
      )}
      {isFetchingImage && <CircularProgress />}
    </Stack>
  )
}
