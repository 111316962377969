import {
  Button,
  Checkbox,
  FormControlLabel,
  Slider,
  Stack,
} from '@mui/material'
import { Document } from '@/types/documents'
import useSplitDocuments from './useSplitDocuments'

type SplitDocumentOptionsProps = {
  pages: Document['document_pages']
  splitDocumentsData: ReturnType<typeof useSplitDocuments>
}

export default function SplitDocumentOptions({
  pages,
  splitDocumentsData,
}: SplitDocumentOptionsProps) {
  const {
    deleteOriginal,
    pagesPerSplit,
    splitPoints,
    setDeleteOriginal,
    setPagesPerSplit,
    setSplitPoints,
  } = splitDocumentsData

  return (
    <>
      <Stack direction="row" spacing={2} sx={{ py: 2 }}>
        <Button
          variant="text"
          onClick={() => {
            const points = []
            for (let i = pagesPerSplit; i < pages.length; i += pagesPerSplit) {
              points.push(i)
            }
            setSplitPoints(points)
          }}
          sx={{
            minWidth: '160px',
          }}
        >
          Split Every {pagesPerSplit} Page{pagesPerSplit > 1 ? 's' : ''}
        </Button>
        <Slider
          value={pagesPerSplit}
          min={1}
          max={9}
          valueLabelDisplay="auto"
          onChange={(_, value) => setPagesPerSplit(value as number)}
          sx={{ width: 200 }}
        />
        <Button
          disabled={!splitPoints.length}
          variant="text"
          onClick={() => {
            setSplitPoints([])
          }}
        >
          Clear All Split Points
        </Button>
      </Stack>
      <FormControlLabel
        label="Delete Original Document"
        control={
          <Checkbox
            color={deleteOriginal ? 'error' : 'neutral'}
            sx={{ padding: 0 }}
            checked={deleteOriginal}
            onChange={(event, checked) => setDeleteOriginal(checked)}
          />
        }
        componentsProps={{
          typography: { color: deleteOriginal ? 'error' : 'neutral' },
        }}
        sx={{ mx: 0, columnGap: 1, width: 'fit-content' }}
      />
    </>
  )
}
