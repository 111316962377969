import { Button } from '@mui/material'
import { Dialog, DialogContent, DialogFooter } from '../dialog'
import OrganizationTreeSearch from '../organizations-tree/OrganizationTreeSearch'
import OrganizationTreeSearchSkeleton from '../organizations-tree/OrganizationTreeSearchSkeleton'
import { OverlayState } from '@/hooks/useOverlay'
import { useRootOrganization } from '../organizations/RootOrganizationProvider'
import { Organization } from '@/types/organizations'
import { useNavigate } from 'react-router-dom'
import { Dispatch, SetStateAction } from 'react'

type SelectOrgDialogProps = {
  overlay: OverlayState
  selectedOrgId: string
  setSelectedOrgId: Dispatch<SetStateAction<string>>
  organizations: Organization[]
  selectedOrganization: Organization
  isFetchingAll: boolean
}

export default function SelectOrgDialog({
  overlay,
  selectedOrgId,
  setSelectedOrgId,
  organizations,
  selectedOrganization,
  isFetchingAll,
}: SelectOrgDialogProps) {
  const { rootOrganization } = useRootOrganization()
  const navigate = useNavigate()

  return (
    <Dialog title="Select Organization" {...overlay} maxWidth="md">
      <DialogContent>
        {isFetchingAll ? (
          <OrganizationTreeSearchSkeleton />
        ) : (
          <OrganizationTreeSearch
            organizations={organizations}
            rootTreeOrgId={rootOrganization.id}
            selectedOrgId={selectedOrgId}
            selectedOrganization={selectedOrganization}
            setSelectedOrgId={setSelectedOrgId}
          />
        )}
      </DialogContent>
      <DialogFooter>
        <Button variant="text" onClick={overlay.close}>
          Cancel
        </Button>
        <Button
          type="submit"
          onClick={() => {
            overlay.close()
            navigate(selectedOrgId)
          }}
        >
          Save
        </Button>
      </DialogFooter>
    </Dialog>
  )
}
