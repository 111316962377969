import { Edit, Delete } from '@mui/icons-material'
import { Box, Stack, IconButton, Typography } from '@mui/material'
import AddEditCustomVariableDialog from './AddEditCustomVariableDialog'
import useOverlay from '@/hooks/useOverlay'
import { QueryKey } from '@tanstack/react-query'
import queryKeys from '@/service-library/query-keys'
import { showErrorSnackbar } from '@/utils/snackbars'
import {
  CustomVariable,
  CustomVariableCategory,
} from '@/types/custom-variables'
import { useDeleteCustomVariable } from '@/service-library/hooks/custom-variables'
import { useRootOrganization } from '../organizations/RootOrganizationProvider'

type CustomVariableManagementRowProps = {
  variable: CustomVariable
  variablesQueryKey: QueryKey
  category: CustomVariableCategory & { variables: CustomVariable[] }
  categories: CustomVariableCategory[]
}

export default function CustomVariableManagementRow({
  variable,
  variablesQueryKey,
  category,
  categories,
}: CustomVariableManagementRowProps) {
  const editCustomVariableOverlay = useOverlay()
  const { rootOrganization } = useRootOrganization()

  const { deleteCustomVariable } = useDeleteCustomVariable({
    listQueryKey: variablesQueryKey,
    // needed since we use the root org to get the custom variables for the other tab
    sideEffectQueryKeys: [queryKeys.organizations.details()],
    onError: () => {
      showErrorSnackbar('Unable to delete variable. Please try again later.')
    },
  })

  return (
    <>
      <Box>
        <Stack spacing={1} direction="row">
          <IconButton onClick={editCustomVariableOverlay.open} size="small">
            <Edit color="primary" fontSize="inherit" />
          </IconButton>
          <IconButton
            onClick={() => {
              deleteCustomVariable(variable.id)
            }}
            size="small"
          >
            <Delete color="error" fontSize="inherit" />
          </IconButton>

          <AddEditCustomVariableDialog
            overlay={editCustomVariableOverlay}
            categories={categories}
            variable={variable}
            listQueryKey={variablesQueryKey}
            selectedCategory={category}
            orgId={rootOrganization.id}
          />
        </Stack>
      </Box>

      <Box>
        <Typography sx={{ overflowWrap: 'break-word' }} variant="body2">
          {variable.name}
        </Typography>
      </Box>

      <Box>
        <Typography variant="body2" color="text.secondary">
          {variable.description}
        </Typography>
      </Box>
    </>
  )
}
