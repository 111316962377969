import { ProjectLinkedModel } from '@/types/project-linked-models'
import { DeveloperBoard } from '@mui/icons-material'
import { Box, Card, CardActionArea, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

type ModelCardProps = {
  projectLinkedModel: ProjectLinkedModel
}

// How do I remove a model?
// Should I be able to remove a model from the Model Library?

export default function ModelCard({ projectLinkedModel }: ModelCardProps) {
  const currentVersion = projectLinkedModel.current_model_version
  return (
    <Card sx={{ height: '100%' }}>
      <CardActionArea
        component={Link}
        to={`./${projectLinkedModel.id}`}
        sx={{ height: '100%' }}
      >
        <Stack direction="row" spacing={1} sx={{ height: '100%' }}>
          <Box sx={{ p: 2, flexGrow: 1 }}>
            <Typography variant="overline" sx={{ lineHeight: 1 }}>
              {projectLinkedModel.project_model?.project_model_type?.name}
            </Typography>
            <Typography variant="h5">
              {projectLinkedModel.project_model?.name}
            </Typography>
            <Typography
              variant="caption"
              color={currentVersion ? 'primary.main' : 'text.secondary'}
            >
              {currentVersion
                ? `v${currentVersion?.version}.0`
                : 'No Version Set'}
            </Typography>
          </Box>
          <Stack
            sx={{
              width: 100,
              height: '100%',
              background: (theme) => theme.palette.grey[800],
              flexShrink: 0,
            }}
            alignItems="center"
            justifyContent="center"
          >
            <DeveloperBoard sx={{ fontSize: 48 }} />
          </Stack>
        </Stack>
      </CardActionArea>
    </Card>
  )
}
